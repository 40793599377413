import React, { useEffect } from 'react';
import { Text, ScaledScreenRatio } from 'components';
import classes from './PackagePopUp.module.scss';
import CloseIconSVG from './CloseIconSVG';
import SilverSVG from './SilverSVG';
import GoldSVG from './GoldSVG';
import PlatinumSVG from './PlatinumSVG';

interface IDialogProps {
  package: 'Silver' | 'Gold' | 'Platinum',
  open: boolean;
  onClose(): void;
}

const PackagePopUp = (props: IDialogProps) => {
  const isInIframe = window.location !== window.parent.location;

  useEffect(() => {
    if (!isInIframe) return;
    if (props.open) {
      window.parent.postMessage('iframeMessage=PACKAGE_POPUP__n__' + props.package, '*');
      setTimeout(() => {
        props.onClose();
      }, 500);
    }
  }, [props.open, props.package, isInIframe]);
  if (isInIframe) return null;
  if (!props.open) return null;

  const PackageSVG = props.package === 'Silver' ? SilverSVG
    : props.package === 'Gold' ? GoldSVG
      : PlatinumSVG;

  return (
    <div className={classes.container}>
      <ScaledScreenRatio width={1920} height={1080}>
        <div className={classes.content}>
          <PackageSVG />
          <CloseIconSVG
            style={{
              position: 'absolute',
              top: 50,
              right: 50,
              cursor: 'pointer'
            }}
            onClick={props.onClose}
          />
        </div>
      </ScaledScreenRatio>
    </div>
  );
};

export default PackagePopUp; 