import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={1652} height={906} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 813"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h67.482v44.016H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Rectangle 814"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h48.674v54.633H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Rectangle 815"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h65v42.397H0z"
        />
      </clipPath>
    </defs>
    <g transform="translate(-105 -87)">
      <rect
        data-name="Rectangle 805"
        width={1652}
        height={906}
        rx={28}
        transform="translate(105 87)"
        fill="#e4e4e4"
      />
      <text
        data-name="Silver"
        transform="translate(171 113.356)"
        fill="#2726dd"
        fontSize={40}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={49}>
          {"Silver"}
        </tspan>
      </text>
      <text
        data-name="Cost Reduction"
        transform="translate(1199 268)"
        fill="#2726dd"
        fontSize={24}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={29}>
          {"Cost Reduction"}
        </tspan>
      </text>
      <text
        data-name="\u2022 Integrated Fraud Protection - Platform Maintenance - Access to Fraud Transaction Interface - Access to Rule Manager - 5 Fraud Sub-clients with Dedicated Fraud Strategies - Single Call Flow (Pre or Post Auth) - Active &amp; Pa"
        transform="translate(171 346)"
        fill="#2726dd"
        fontSize={16}
        fontFamily="NeuePlakText-Regular, Neue Plak Text"
      >
        <tspan x={0} y={20} xmlSpace="preserve">
          {"\u2022   Integrated Fraud Protection "}
        </tspan>
        <tspan x={0} y={44} xmlSpace="preserve">
          {"     - Platform Maintenance"}
        </tspan>
        <tspan x={0} y={68} xmlSpace="preserve">
          {"     - Access to Fraud Transaction Interface"}
        </tspan>
        <tspan x={0} y={92} xmlSpace="preserve">
          {"     - Access to Rule Manager"}
        </tspan>
        <tspan x={0} y={116} xmlSpace="preserve">
          {"     - 5 Fraud Sub-clients with Dedicated Fraud Strategies"}
        </tspan>
        <tspan x={0} y={140} xmlSpace="preserve">
          {"     - Single Call Flow (Pre or Post Auth)"}
        </tspan>
        <tspan x={0} y={164} xmlSpace="preserve">
          {"     - Active & Passive Mode Options"}
        </tspan>
        <tspan x={0} y={188} xmlSpace="preserve">
          {"     - Address Verification & Card Verification Value Checks"}
        </tspan>
        <tspan x={0} y={212} xmlSpace="preserve">
          {"     - IP Geolocation Checks"}
        </tspan>
        <tspan x={0} y={236} xmlSpace="preserve">
          {"     - CPF Validation (Brazil only)"}
        </tspan>
      </text>
      <text
        data-name="Authentication / Fraud Management"
        transform="translate(171 312)"
        fill="#2726dd"
        fontSize={18}
        fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
        fontWeight={600}
      >
        <tspan x={0} y={22}>
          {"Authentication / Fraud Management"}
        </tspan>
      </text>
      <text
        data-name="\u2022 Platform Maintenance \u2022 Access to Fraud Transaction Interface \u2022 Access to Rule Manager \u2022 5 Fraud Sub-clients with Dedicated Fraud Strategies \u2022 Single Call Flow (Pre or Post Auth) \u2022 Active &amp; Passive Mode Options \u2022 Address Verification &amp; Card"
        transform="translate(1207 349)"
        fill="#2726dd"
        fontSize={16}
        fontFamily="NeuePlakText-Regular, Neue Plak Text"
      >
        <tspan x={0} y={20} xmlSpace="preserve">
          {"\u2022   Platform Maintenance"}
        </tspan>
        <tspan x={0} y={44} xmlSpace="preserve">
          {"\u2022   Access to Fraud Transaction Interface"}
        </tspan>
        <tspan x={0} y={68} xmlSpace="preserve">
          {"\u2022   Access to Rule Manager"}
        </tspan>
        <tspan x={0} y={92} xmlSpace="preserve">
          {"\u2022   5 Fraud Sub-clients with Dedicated Fraud Strategies"}
        </tspan>
        <tspan x={0} y={116} xmlSpace="preserve">
          {"\u2022   Single Call Flow (Pre or Post Auth)"}
        </tspan>
        <tspan x={0} y={140} xmlSpace="preserve">
          {"\u2022   Active & Passive Mode Options"}
        </tspan>
        <tspan x={0} y={164} xmlSpace="preserve">
          {"\u2022   Address Verification & Card Verification Value Checks"}
        </tspan>
        <tspan x={0} y={188} xmlSpace="preserve">
          {"\u2022   IP Geolocation Checks"}
        </tspan>
        <tspan x={0} y={212} xmlSpace="preserve">
          {"\u2022   CPF Validation (Brazil only)"}
        </tspan>
      </text>
      <text
        transform="translate(1207 312)"
        fill="#2726dd"
        fontSize={18}
        fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
        fontWeight={600}
      >
        <tspan x={0} y={22}>
          {"Chargebacks"}
        </tspan>
      </text>
      <text
        data-name="\u2022 Single API Driven (SAAS) Payment Orchestration Platform \u2022 Low Code Development &amp; Integration Portal \u2022 PCI-DSS Compliant &amp; Customizable Payment Widget Interfaces \u2022 Bulk Merchants Onboarding Automation \u2022 Unified Settlement &amp; Chargeback Reporting"
        transform="translate(1207 617)"
        fill="#2726dd"
        fontSize={16}
        fontFamily="NeuePlakText-Regular, Neue Plak Text"
      >
        <tspan x={0} y={20} xmlSpace="preserve">
          {"\u2022   Single API Driven (SAAS) Payment Orchestration Platform"}
        </tspan>
        <tspan x={0} y={44} xmlSpace="preserve">
          {"\u2022   Low Code Development & Integration Portal"}
        </tspan>
        <tspan x={0} y={68} xmlSpace="preserve">
          {
            "\u2022   PCI-DSS Compliant & Customizable Payment Widget Interfaces"
          }
        </tspan>
        <tspan x={0} y={92} xmlSpace="preserve">
          {"\u2022   Bulk Merchants Onboarding Automation"}
        </tspan>
        <tspan x={0} y={116} xmlSpace="preserve">
          {"\u2022   Unified Settlement & Chargeback Reporting"}
        </tspan>
        <tspan x={0} y={140} xmlSpace="preserve">
          {"     (Available for the Top 22 Acquirers)"}
        </tspan>
        <tspan x={0} y={164} xmlSpace="preserve">
          {"\u2022   Automated Webhook Reporting"}
        </tspan>
        <tspan x={0} y={188} xmlSpace="preserve">
          {"\u2022   Standard Reports & Email Notifications"}
        </tspan>
        <tspan x={0} y={212} xmlSpace="preserve">
          {"\u2022   Business Intelligence Platform & Monitoring"}
        </tspan>
        <tspan x={0} y={236} xmlSpace="preserve">
          {"\u2022   Project Based Development & Testing Environment"}
        </tspan>
        <tspan x={0} y={260} xmlSpace="preserve">
          {"\u2022   24x7 Platform Availability & Help Desk Support"}
        </tspan>
        <tspan x={0} y={284} xmlSpace="preserve">
          {"\u2022   Standard Access & Maintenance, Regular Security / API / "}
        </tspan>
        <tspan x={0} y={308} xmlSpace="preserve">
          {"     Compliance updates"}
        </tspan>
      </text>
      <text
        data-name="Cost of Doing Business"
        transform="translate(1207 584)"
        fill="#2726dd"
        fontSize={18}
        fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
        fontWeight={600}
      >
        <tspan x={0} y={22}>
          {"Cost of Doing Business"}
        </tspan>
      </text>
      <text
        transform="translate(678 268)"
        fill="#2726dd"
        fontSize={24}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={29}>
          {"Growth"}
        </tspan>
      </text>
      <text
        transform="translate(171 268)"
        fill="#2726dd"
        fontSize={24}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={29}>
          {"Conversion"}
        </tspan>
      </text>
      <text
        data-name="\u2022 ACI PayAfter \u2013 Access to 70+ Global BNPL Providers (Ex. Klarna One, Zip, Sezzle, etc.) \u2022 Crypto Payments with Zero Percent Transaction Processing Fee with Volatility Protection, and One-Click Payment"
        transform="translate(678 349)"
        fill="#2726dd"
        fontSize={16}
        fontFamily="NeuePlakText-Regular, Neue Plak Text"
      >
        <tspan x={0} y={20} xmlSpace="preserve">
          {"\u2022   ACI PayAfter \u2013 Access to 70+ Global BNPL Providers"}
        </tspan>
        <tspan x={0} y={44} xmlSpace="preserve">
          {"    (Ex. Klarna One, Zip, Sezzle, etc.)"}
        </tspan>
        <tspan x={0} y={68} xmlSpace="preserve">
          {"\u2022   Crypto Payments with Zero Percent Transaction "}
        </tspan>
        <tspan x={0} y={92} xmlSpace="preserve">
          {
            "      Processing Fee with Volatility Protection,  and One-Click Payment"
          }
        </tspan>
      </text>
      <text
        data-name="Accepting Alternative Payments"
        transform="translate(678 312)"
        fill="#2726dd"
        fontSize={18}
        fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
        fontWeight={600}
      >
        <tspan x={0} y={22}>
          {"Accepting Alternative Payments"}
        </tspan>
      </text>
      <text
        data-name="\u2022 ACI, Issuer and Acquirer Tokenization- Enabling One-Click &amp; Recurring Payments"
        transform="translate(678 506)"
        fill="#2726dd"
        fontSize={16}
        fontFamily="NeuePlakText-Regular, Neue Plak Text"
      >
        <tspan x={0} y={20} xmlSpace="preserve">
          {
            "\u2022   ACI, Issuer and Acquirer Tokenization- Enabling One-Click &"
          }
        </tspan>
        <tspan x={0} y={44} xmlSpace="preserve">
          {"      Recurring Payments"}
        </tspan>
      </text>
      <text
        data-name="Mobile / Tokenization / One click"
        transform="translate(678 470)"
        fill="#2726dd"
        fontSize={18}
        fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
        fontWeight={600}
      >
        <tspan x={0} y={22}>
          {"Mobile / Tokenization / One click"}
        </tspan>
      </text>
      <text
        data-name="\u2022 Multi-Language Multi-Currency for Global Expansion \u2022 No. of Acquirer / Processor Connections Included \u2013 3 \u2022 No. of Global Branded Wallets / Mobile Pay Connections Included \u2013 2 (Ex. ApplePay, GooglePay, PayPal etc.) \u2022 Integration with All Ma"
        transform="translate(171 656)"
        fill="#2726dd"
        fontSize={16}
        fontFamily="NeuePlakText-Regular, Neue Plak Text"
      >
        <tspan x={0} y={20} xmlSpace="preserve">
          {"\u2022   Multi-Language Multi-Currency for Global Expansion"}
        </tspan>
        <tspan x={0} y={44} xmlSpace="preserve">
          {"\u2022   No. of Acquirer / Processor Connections Included \u2013 3"}
        </tspan>
        <tspan x={0} y={68} xmlSpace="preserve">
          {"\u2022   No. of Global Branded Wallets / Mobile Pay Connections"}
        </tspan>
        <tspan x={0} y={92} xmlSpace="preserve">
          {"      Included \u2013 2 (Ex. ApplePay, GooglePay, PayPal etc.)"}
        </tspan>
        <tspan x={0} y={116} xmlSpace="preserve">
          {"\u2022   Integration with All Major eCommerce Platforms"}
        </tspan>
        <tspan x={0} y={140} xmlSpace="preserve">
          {"     (Shopify, Magento)"}
        </tspan>
        <tspan x={0} y={164} xmlSpace="preserve">
          {"\u2022   Integration with Major CRM Software "}
        </tspan>
        <tspan x={0} y={188} xmlSpace="preserve">
          {"      (Salesforce, CC, SAP Hybris)"}
        </tspan>
        <tspan x={0} y={212} xmlSpace="preserve">
          {"\u2022   Payment & Fraud Analytics"}
        </tspan>
      </text>
      <text
        data-name="Acceptance / Bank Approvals"
        transform="translate(171 617)"
        fill="#2726dd"
        fontSize={18}
        fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
        fontWeight={600}
      >
        <tspan x={0} y={22}>
          {"Acceptance / Bank Approvals"}
        </tspan>
      </text>
      <path
        data-name="Line 138"
        fill="none"
        stroke="#2726dd"
        d="M655.5 201.356V957"
      />
      <path
        data-name="Line 139"
        fill="none"
        stroke="#2726dd"
        d="M1176.5 201.356V957"
      />
      <g data-name="Group 3182">
        <path
          data-name="Path 42"
          d="M737.317 252.795V222.19h-6.653v30.605"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 43"
          d="M695.014 252.796v-7.984h-6.654v7.984"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 44"
          d="M709.115 252.796v-13.307h-6.654v13.307"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 45"
          d="M723.216 252.796v-21.291h-6.653v21.291"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 46"
          d="M695.865 222.523h6.77v6.772"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Line 143"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
          d="m688.36 236.783 14.26-14.26"
        />
        <g data-name="Group 3181">
          <g
            data-name="Group 3180"
            clipPath="url(#a)"
            transform="translate(678 215.984)"
          >
            <path
              data-name="Path 47"
              d="M67.482 43.018H.998V0"
              fill="none"
              stroke="#2726dc"
              strokeMiterlimit={10}
              strokeWidth={2}
            />
          </g>
        </g>
      </g>
      <g data-name="Group 3184">
        <g
          data-name="Group 3183"
          transform="translate(171 209)"
          clipPath="url(#b)"
          fill="none"
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            data-name="Path 48"
            d="M24.397 3.641A23.735 23.735 0 0 0 .662 27.376h6.3"
            stroke="#2726dd"
          />
          <path
            data-name="Path 49"
            d="M24.277 50.992a23.735 23.735 0 0 0 23.735-23.731h-6.175"
            stroke="#2726dd"
          />
          <path
            data-name="Path 50"
            d="m21.223.468 3.174 3.174-3.174 3.172"
            stroke="#2726dd"
          />
          <path
            data-name="Path 51"
            d="m27.45 54.165-3.174-3.174 3.174-3.173"
            stroke="#2726dd"
          />
          <ellipse
            data-name="Ellipse 8"
            cx={11.247}
            cy={4.174}
            rx={11.247}
            ry={4.174}
            transform="translate(13.09 20.108)"
            stroke="#2726dc"
          />
          <path
            data-name="Path 52"
            d="M35.584 29.548a2.1 2.1 0 0 1-.732 1.485c-1.613 1.572-5.712 2.689-10.515 2.689s-8.9-1.116-10.513-2.688a2.1 2.1 0 0 1-.734-1.486"
            stroke="#2726dc"
          />
        </g>
      </g>
      <g data-name="Group 3187">
        <path
          data-name="Path 53"
          d="M1208.979 253.061v-29.48h6.409v29.48"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 54"
          d="M1249.727 253.06v-7.69h6.409v7.69"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 55"
          d="M1236.145 253.06v-12.817h6.409v12.817"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 56"
          d="M1222.561 253.061v-20.508h6.409v20.508"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Path 57"
          d="M1249.615 237.637h6.521v-6.521"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <path
          data-name="Line 144"
          fill="none"
          stroke="#2726dd"
          strokeMiterlimit={10}
          strokeWidth={2}
          d="m1242.067 223.581 14.056 14.056"
        />
        <g data-name="Group 3186">
          <g
            data-name="Group 3185"
            clipPath="url(#c)"
            transform="translate(1199 217.603)"
          >
            <path
              data-name="Path 58"
              d="M65 41.436H.961V0"
              fill="none"
              stroke="#2726dc"
              strokeMiterlimit={10}
              strokeWidth={2}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
