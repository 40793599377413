import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={49} height={49} {...props}>
    <g data-name="Group 30" transform="translate(-1558.914 -196.284)">
      <circle
        data-name="Ellipse 7"
        cx={24.5}
        cy={24.5}
        r={24.5}
        transform="translate(1558.914 196.284)"
        fill="#ffcb00"
      />
      <g data-name="Group 1755" fill="none" stroke="#fff" strokeWidth={3}>
        <path data-name="Line 3" d="m1576 211.213 15.4 18.295" />
        <path data-name="Line 4" d="m1591.4 211.213-15.4 18.295" />
      </g>
    </g>
  </svg>
)

export default SvgComponent
