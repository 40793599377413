import './App.scss';

import {
  HomePage,
  ResponsiveIframe,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Login,
} from 'screens';
import { withStoreProvider } from 'store';
import { useEffect, useState } from 'react';

function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname: string, cvalue: any, exdays: any) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  // @ts-ignore
  var expires = "expires=" + d.toGMTString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function clearKey() {
  window.history.pushState({}, document.title, window.location.pathname);
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('?key=m7*f1lZ*B53J')) {
      clearKey();
      setIsLoggedIn(true);
      setIsReady(true);
      setCookie("usName", window.btoa("QUNJdXBsaWZ0MjAyMw=="), 30);
      return;
    }
    var user = getCookie("usName");
    if (user !== "") {
      setIsLoggedIn(true);
    }
    setIsReady(true);
  }, []);

  const onInputCode = () => {
    const code = window.prompt('Please enter the password');
    if (!code) return alert('Wrong password');
    if (window.btoa(code) == "QUNJdXBsaWZ0MjAyMw==") {
      setCookie("usName", window.btoa(code), 30);
      setIsLoggedIn(true);
    }
    else {
      alert("Wrong Password");
    }
  }

  if (!isReady) return null;

  if (!isLoggedIn) return (
    <Login
      onInputCode={onInputCode}
    />
  );

  return window.location.pathname.includes('/home') ? <HomePage />
    : window.location.pathname.includes('/step-1') ? <Step1 />
      : window.location.pathname.includes('/step-2') ? <Step2 />
        : window.location.pathname.includes('/step-3') ? <Step3 />
          : window.location.pathname.includes('/step-4') ? <Step4 />
            : window.location.pathname.includes('/step-5') ? <Step5 />
              : window.location.pathname.includes('/step-6') ? <Step6 />
                : window.location.pathname.includes('/step-7') ? <Step7 />
                  // : <ResponsiveIframe />
                  : <HomePage />
};

export default withStoreProvider(App);