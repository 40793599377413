import React from 'react';
import classes from './StatsDisplay.module.scss';
import { Text } from 'components';
import MoneyIcon from './MoneyIcon';

const StatsDisplay = ({ label, value } : any) => {
  return (
    <div className={classes.statsContainer}>
      <MoneyIcon />
      <Text blue style={{ height: 74, marginTop: 30, width: 220 }} center>{label}</Text>
      <Text h3 blue center money>{value}</Text>
    </div>
  );
};

export default StatsDisplay;