import { useRatioDimensions } from '../ratio-container/useRatioDimensions';
import { RatioContainer } from '../ratio-container/RatioContainer';
import * as SVGChildData from './svgText';
import classes from './SVGUI.module.scss';

interface ISVGUIProps {
  text: Function;
  ratio: number;
  state?: any;
  children?: any;
  wrapperSize?: {
    innerWidth: number,
    innerHeight: number,
  }
}

export const SVGUI = ({ text, ratio, state, children, wrapperSize = { innerWidth: window.innerWidth, innerHeight: window.innerHeight } }: ISVGUIProps) => {
  const width = useRatioDimensions(ratio, wrapperSize);
  return (
    <RatioContainer width={width} ratio={ratio}>
      <div className={classes.container}>
        <div className={classes.svgContentWrapper}>
          <div dangerouslySetInnerHTML={{ __html: text(state) }} className={classes.svgContent} />
          {children}
        </div>
      </div>
    </RatioContainer>
  );
};

SVGUI.texts = {
  step2: SVGChildData.svgStep2
};
SVGUI.Comps = {
  Step2: SVGChildData.svgStep2Children,
};
