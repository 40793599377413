import React from 'react';
import classes from './Text.module.scss';
import cls from 'classnames';

interface IProps {
  white?: boolean;
  blue?: boolean;
  bold?: boolean;
  semiBold?: boolean;
  style?: any;
  children?: any;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  p?: boolean; // paragraph
  center?: boolean;
  money?: boolean;
}

const Text = (props: IProps) => {
  const isHeading = props.h1 || props.h2 || props.h3 || props.h4 || props.h5 || props.h6;
  const textProps = {
    style: props.style,
    className: cls(
      props.white ? classes.white : classes.mainColor,
      classes.regular,
      { [classes.blue]: props.blue },
      { [classes.bold]: props.bold || isHeading },
      { [classes.semiBold]: props.semiBold },
      { [classes.h1]: props.h1 },
      { [classes.h2]: props.h2 },
      { [classes.h3]: props.h3 },
      { [classes.h4]: props.h4 },
      { [classes.h5]: props.h5 },
      { [classes.h6]: props.h6 },
      { [classes.center]: props.center },
      // @ts-ignore
      { [classes.scaleDownAbit]: props.money && !['USD', 'EUR', 'GBP', 'AUD'].includes(window.activeCurrency?.cc !== 'USD') },
    ),
    children: props.children,
  };
  return isHeading ? <h3 {...textProps} /> : props.p ? <p {...textProps} /> : <span {...textProps} />;
};

export default Text;