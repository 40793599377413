import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={1652} height={906} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 813"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h67.482v44.016H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Rectangle 814"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h48.674v54.633H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Rectangle 815"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h65v42.397H0z"
        />
      </clipPath>
    </defs>
    <path
      data-name="Path 59"
      d="M28 0h1596a28 28 0 0 1 28 28v850a28 28 0 0 1-28 28H28a28 28 0 0 1-28-28V28A28 28 0 0 1 28 0Z"
      fill="#FFCB00"
    />
    <text
      data-name="Gold"
      transform="translate(66 26.356)"
      fill="#2726dd"
      fontSize={40}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={49}>
        {"Gold"}
      </tspan>
    </text>
    <text
      data-name="Cost Reduction"
      transform="translate(1094 181)"
      fill="#2726dd"
      fontSize={24}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={29}>
        {"Cost Reduction"}
      </tspan>
    </text>
    <text
      data-name="\u2022 3D Secure 2.1 \u2022 Advanced Fraud Protection - Global Realtime Self Learning Model - Case Manager access for Manual Reviews &amp; Auto Analyst - Up to 20 Sub-clients (5 in Silver + Additional 15 in Gold) - Risk Analytics Support (6 revi"
      transform="translate(66 259)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   3D Secure 2.1"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"\u2022   Advanced Fraud Protection"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"     - Global Realtime Self Learning Model"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"     - Case Manager access for Manual Reviews & Auto Analyst "}
      </tspan>
      <tspan x={0} y={116} xmlSpace="preserve">
        {"     - Up to 20 Sub-clients (5 in Silver + Additional 15 in Gold)"}
      </tspan>
      <tspan x={0} y={140} xmlSpace="preserve">
        {"     - Risk Analytics Support (6 reviews per year)"}
      </tspan>
      <tspan x={0} y={164} xmlSpace="preserve">
        {"     - Fuzzy Matching"}
      </tspan>
      <tspan x={0} y={188} xmlSpace="preserve">
        {"     - Click & Block Positive/Negative Listing"}
      </tspan>
      <tspan x={0} y={212} xmlSpace="preserve">
        {"     - Autopilot Rules"}
      </tspan>
      <tspan x={0} y={236} xmlSpace="preserve">
        {"     - Rule Category Flags"}
      </tspan>
      <tspan x={0} y={260} xmlSpace="preserve">
        {"     - Business Intelligence Reports & Dashboards (ReDi)"}
      </tspan>
      <tspan x={0} y={284} xmlSpace="preserve">
        {"     - Dedicated Development & Testing Environment"}
      </tspan>
    </text>
    <text
      data-name="Authentication / Fraud Management"
      transform="translate(66 225)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Authentication / Fraud Management"}
      </tspan>
    </text>
    <text
      data-name="Everything in Silver, Plus:"
      transform="translate(66 81)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Everything in Silver, Plus:"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Global Realtime Self Learning Model \u2022 Case Manager Access for Manual Reviews \u2022 Up to 20 Sub-clients \u2022 Risk Analytics support (6 Reviews Per Year) \u2022 Fuzzy Matching \u2022 Click &amp; Block Positive / Negative Listing \u2022 Autopilot Rules \u2022 Rule Categ"
      transform="translate(1102 258)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   Global Realtime Self Learning Model"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"\u2022   Case Manager Access for Manual Reviews"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"\u2022   Up to 20 Sub-clients "}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Risk Analytics support (6 Reviews Per Year)"}
      </tspan>
      <tspan x={0} y={116} xmlSpace="preserve">
        {"\u2022   Fuzzy Matching"}
      </tspan>
      <tspan x={0} y={140} xmlSpace="preserve">
        {"\u2022   Click & Block Positive / Negative Listing"}
      </tspan>
      <tspan x={0} y={164} xmlSpace="preserve">
        {"\u2022   Autopilot Rules"}
      </tspan>
      <tspan x={0} y={188} xmlSpace="preserve">
        {"\u2022   Rule Category Flags"}
      </tspan>
      <tspan x={0} y={212} xmlSpace="preserve">
        {"\u2022   Business Intelligence Reports & Dashboards (ReDi)"}
      </tspan>
      <tspan x={0} y={236} xmlSpace="preserve">
        {"\u2022   Dedicated Development & Testing Environment"}
      </tspan>
    </text>
    <text
      transform="translate(1102 225)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Chargebacks"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Bespoke Customizations Included \u2013 90 hours/year (Ex. Functionality, Services, Transaction Flows) \u2022 Unified Settlement &amp; Chargeback Reporting (for Additional Acquirers) \u2022 Dedicated Development &amp; Testing Environment"
      transform="translate(1102 554)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   Bespoke Customizations Included \u2013 90 hours/year "}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"      (Ex. Functionality, Services, Transaction Flows)"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {
          "\u2022   Unified Settlement & Chargeback Reporting (for Additional Acquirers)"
        }
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Dedicated Development & Testing Environment"}
      </tspan>
    </text>
    <text
      data-name="Cost of Doing Business"
      transform="translate(1102 519)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Cost of Doing Business"}
      </tspan>
    </text>
    <text
      transform="translate(573 181)"
      fill="#2726dd"
      fontSize={24}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={29}>
        {"Growth"}
      </tspan>
    </text>
    <text
      transform="translate(66 181)"
      fill="#2726dd"
      fontSize={24}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={29}>
        {"Conversion"}
      </tspan>
    </text>
    <text
      data-name="\u2022 No. of Additional Global Branded Wallets / Mobile Pay Connections Included \u2013 1 (Total 3) \u2022 No. of Local Wallets Included \u2013 1 \u2022 Bank Payments 2 (ex. ACH for US2, SEPA/SEDA2 for EU, etc.)"
      transform="translate(573 259)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   No. of Additional Global Branded Wallets / Mobile Pay "}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"      Connections Included \u2013 1 (Total 3)"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"\u2022   No. of Local Wallets Included \u2013 1"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Bank Payments 2 (ex. ACH for US2, SEPA/SEDA2 for EU, etc.)"}
      </tspan>
    </text>
    <text
      data-name="Accepting Alternative Payments"
      transform="translate(573 225)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Accepting Alternative Payments"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Mobile Development Kit (MSDK)"
      transform="translate(573 406)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022  Mobile Development Kit (MSDK)"}
      </tspan>
    </text>
    <text
      data-name="Mobile / Tokenization / One click"
      transform="translate(573 374)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Mobile / Tokenization / One click"}
      </tspan>
    </text>
    <text
      data-name="\u2022 No. of Additional Acquirer / Processor Connections Included \u2013 5 (Total 8) \u2022 No. of Local Acquirer / Processor Connections - 1 \u2022 Intelligent Payment Routing (Ex. Least Cost Routing)"
      transform="translate(66 601)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   No. of Additional Acquirer / Processor Connections"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"      Included \u2013 5 (Total 8)"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"\u2022   No. of Local Acquirer / Processor Connections - 1"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Intelligent Payment Routing (Ex. Least Cost Routing)"}
      </tspan>
    </text>
    <text
      data-name="Acceptance / Bank Approvals"
      transform="translate(66 567)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Acceptance / Bank Approvals"}
      </tspan>
    </text>
    <path
      data-name="Line 138"
      fill="none"
      stroke="#2726dd"
      d="M550.5 114.356V870"
    />
    <path
      data-name="Line 139"
      fill="none"
      stroke="#2726dd"
      d="M1071.5 114.356V870"
    />
    <g data-name="Group 3182">
      <path
        data-name="Path 42"
        d="M632.317 165.795V135.19h-6.653v30.605"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 43"
        d="M590.014 165.796v-7.984h-6.654v7.984"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 44"
        d="M604.115 165.796v-13.307h-6.654v13.307"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 45"
        d="M618.216 165.796v-21.291h-6.653v21.291"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 46"
        d="M590.865 135.523h6.77v6.772"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Line 143"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="m583.36 149.783 14.26-14.26"
      />
      <g data-name="Group 3181">
        <g
          data-name="Group 3180"
          clipPath="url(#a)"
          transform="translate(573 128.984)"
        >
          <path
            data-name="Path 47"
            d="M67.482 43.018H.998V0"
            fill="none"
            stroke="#2726dc"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </g>
    <g data-name="Group 3184">
      <g
        data-name="Group 3183"
        transform="translate(66 122)"
        clipPath="url(#b)"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path
          data-name="Path 48"
          d="M24.397 3.641A23.735 23.735 0 0 0 .662 27.376h6.3"
          stroke="#2726dd"
        />
        <path
          data-name="Path 49"
          d="M24.277 50.992a23.735 23.735 0 0 0 23.735-23.731h-6.175"
          stroke="#2726dd"
        />
        <path
          data-name="Path 50"
          d="m21.223.468 3.174 3.174-3.174 3.172"
          stroke="#2726dd"
        />
        <path
          data-name="Path 51"
          d="m27.45 54.165-3.174-3.174 3.174-3.173"
          stroke="#2726dd"
        />
        <ellipse
          data-name="Ellipse 8"
          cx={11.247}
          cy={4.174}
          rx={11.247}
          ry={4.174}
          transform="translate(13.09 20.108)"
          stroke="#2726dc"
        />
        <path
          data-name="Path 52"
          d="M35.584 29.548a2.1 2.1 0 0 1-.732 1.485c-1.613 1.572-5.712 2.689-10.515 2.689s-8.9-1.116-10.513-2.688a2.1 2.1 0 0 1-.734-1.486"
          stroke="#2726dc"
        />
      </g>
    </g>
    <g data-name="Group 3187">
      <path
        data-name="Path 53"
        d="M1103.979 166.061v-29.48h6.409v29.48"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 54"
        d="M1144.727 166.06v-7.69h6.409v7.69"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 55"
        d="M1131.145 166.06v-12.817h6.409v12.817"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 56"
        d="M1117.561 166.061v-20.508h6.409v20.508"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 57"
        d="M1144.615 150.637h6.521v-6.521"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Line 144"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="m1137.067 136.581 14.056 14.056"
      />
      <g data-name="Group 3186">
        <g
          data-name="Group 3185"
          clipPath="url(#c)"
          transform="translate(1094 130.603)"
        >
          <path
            data-name="Path 58"
            d="M65 41.436H.961V0"
            fill="none"
            stroke="#2726dc"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
