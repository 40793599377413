import React from 'react';
import { ASSETS } from 'assets'

const DropdownIndicator = (props : any) => {
  return (
    <div {...props} style={{ width: 50 }}>
      <ASSETS.SVGDropdownIcon />
    </div>
  );
};

export default DropdownIndicator;