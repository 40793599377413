import React, { useState } from 'react';
import { BgImageContainer, Text, SliderRange, ScaledScreenRatio } from 'components';
import { ASSETS } from 'assets';
import cls from 'classnames';
import cc from '../@common-classes/Common.module.scss';
import StatsDisplay from './comps/StatsDisplay';
import { VarHelper } from 'helpers';
import { useStore } from 'store';

const Step1 = () => {
  const [store, setStore, sheetData, displayValues, api] = useStore();
  console.log('store', store);
  const onBack = () => {
    window.location.pathname = '/home';
  }
  const onNext = () => {
    const {
      annualTransactionValue,
      averageTransactionValue,
      salesMarginBeforeFixedCosts,
    } = store;
    if (!annualTransactionValue || !averageTransactionValue || !salesMarginBeforeFixedCosts) {
      return alert('Please check your input values');
    }
    window.location.pathname = '/step-2';
  }
  const onSave = async () => {
    if (!!store.Id && !!store.sessionCode) await api.updateData(store.sessionCode, store);
    else {
      const res = await api.saveDataAndGenerateCode(store);
      if (!!res)
        setStore({
          ...store,
          sessionCode: res.Code,
          Id: res.Id,
        })
    }
    alert('Your session has been saved');
  };

  const renderSlider = ({ label, min, max, value, onChange, formatDisplay, step }: any) => {
    return (
      <div className={cc.row} style={{ marginBottom: 50 }}>
        <div className={cls(cc.flex, cc.flex1)}>
          <Text style={{ width: 200, marginTop: 35 }}>{label}</Text>
        </div>
        <div className={cls(cc.flex, cc.flex3)}>
          <SliderRange
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            formatDisplay={formatDisplay}
            style={{
              paddingLeft: 60,
              paddingRight: 60,
              paddingTop: 30,
            }}
          />
        </div>
      </div>
    )
  };

  return (
    <BgImageContainer src={''} overlayColor='white' middle>
      <ScaledScreenRatio width={1920} height={1080}>
        <div style={{ padding: 50 }}>
          <a href="/">
            <img src={ASSETS.imgACILogoBlue} className={cc.blueLogo} />
          </a>
          <div className={cls(cc.flex, cc.flex1)} style={{ padding: 50 }}>
            <Text h2 blue style={{ marginBottom: 30 }}>Step 1: Input Current Business Incomes, Costs and Liabilities</Text>
            <Text h4 blue style={{ marginBottom: 30 }}>Existing Business</Text>
            <div className={cc.row}>
              <div className={cls(cc.flex, cc.flex3)}>
                {renderSlider({
                  label: 'Annual Transaction Volume',
                  min: 0,
                  max: 50000000,
                  step: 1000,
                  value: store.annualTransactionValue || 0,
                  onChange: (v: number) => setStore({
                    ...store,
                    annualTransactionValue: v,
                  }),
                  formatDisplay: (v: number) => VarHelper.money(v, ''),
                })}
                {renderSlider({
                  label: 'Average Transaction Value (ATV)',
                  min: 0,
                  max: 1000,
                  step: 1,
                  value: store.averageTransactionValue || 0,
                  onChange: (v: number) => setStore({
                    ...store,
                    averageTransactionValue: v,
                  }),
                  formatDisplay: (v: number) => `$${v}`,
                })}
                {renderSlider({
                  label: ' Sales Margin (Before Fixed Costs)',
                  min: 0,
                  max: 100,
                  step: 0.5,
                  value: store.salesMarginBeforeFixedCosts || 0,
                  onChange: (v: number) => setStore({
                    ...store,
                    salesMarginBeforeFixedCosts: v,
                  }),
                  formatDisplay: (v: number) => `${v}%`,
                })}

              </div>
              <div className={cc.flex2}>
                <div className={cc.row}>
                  <StatsDisplay
                    label='Total Revenue'
                    value={displayValues.step1.totalRevenue}
                  />
                  <StatsDisplay
                    label='Margin Before Fixed Costs'
                    value={displayValues.step1.marginBeforeFixedCosts}
                  />
                </div>
              </div>

            </div>
            {/* BUTTONS */}
            <div className={cc.row} style={{ justifyContent: 'space-between' }}>
              {/* <button onClick={onBack} className={cc.mainButton} style={{ width: 222 }}>Back</button> */}
              <div className={cc.row}>
                <button onClick={onBack} className={cc.mainButton} style={{ width: 222, marginRight: 20 }}>Back</button>
                <button onClick={onSave} className={cc.blueButton} style={{ width: 222, marginRight: 20 }}>Save Your Session</button>
                <Text blue semiBold style={{ fontSize: 12, marginRight: 20 }}>Enter This Code {store.sessionCode || '0000'} to <br />Resume Your Latest Session</Text>
                <button className={cc.blueButtonOutline} style={{ width: 222, marginRight: 20, paddingTop: 5 }}>
                  <Text blue semiBold style={{ fontSize: 40 }}>{store.sessionCode || '0000'}</Text>
                </button>
              </div>
              <button onClick={onNext} className={cc.mainButton} style={{ width: 222 }}>Next</button>
            </div>
          </div>
        </div>
      </ScaledScreenRatio>
    </BgImageContainer>
  );
}

export default Step1;