import { ASSETS } from 'assets';

export const svgAcceptingAlternativePayment = ({ width, height }: any) => 
`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width || 1920}" height="${height || 1920}" viewBox="0 0 1920 1080">
  <defs>
    <pattern id="pattern" width="1" height="1" viewBox="-374.739 188.378 1094.696 729.347">
      <image preserveAspectRatio="none" width="1621" height="1080" xlink:href="${ASSETS.imgStep4AcceptAlternativePayment}" />
    </pattern>
    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0a43ff"/>
      <stop offset="1" stop-color="#0a43ff"/>
    </linearGradient>
    <clipPath id="clip-path">
      <rect id="Rectangle_834" data-name="Rectangle 834" width="67.246" height="75.264" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_837" data-name="Rectangle 837" width="76.354" height="59.253" fill="none" stroke="#fff" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_848" data-name="Rectangle 848" width="73.508" height="59.233" fill="none" stroke="#fff" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-4">
      <rect id="Rectangle_850" data-name="Rectangle 850" width="82.12" height="51.188" transform="translate(0 0)" fill="none" stroke="#fff" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-5">
      <rect id="Rectangle_851" data-name="Rectangle 851" width="66.327" height="66.327" fill="none" stroke="#fff" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-6">
      <rect id="Rectangle_852" data-name="Rectangle 852" width="90" height="70.218" fill="none" stroke="#fff" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-7">
      <rect id="Rectangle_853" data-name="Rectangle 853" width="56.955" height="53.42" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-8">
      <rect id="Rectangle_854" data-name="Rectangle 854" width="45.497" height="38.641" fill="none" stroke="#707070" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-9">
      <rect id="Rectangle_856" data-name="Rectangle 856" width="48.81" height="48.81" fill="none" stroke="#707070" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-path-10">
      <rect id="Rectangle_855" data-name="Rectangle 855" width="45.178" height="62.531" fill="none" stroke="#707070" stroke-width="2"/>
    </clipPath>
    <clipPath id="clip-Lightbox_9">
      <rect width="1920" height="1080"/>
    </clipPath>
  </defs>
  <g id="Lightbox_9" data-name="Lightbox – 9" clip-path="url(#clip-Lightbox_9)">
    <rect width="1920" height="1080" fill="#fff"/>
    <rect id="GettyImages-1161286250" width="1621" height="1080" transform="translate(-622)" opacity="0.799" fill="url(#pattern)"/>
    <path id="city" d="M0,0H999V1080H0Z" opacity="0.6" fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate"/>
    <text id="Growth_-_Accepting_Alternative_Payments" data-name="Growth - Accepting Alternative Payments" transform="translate(120 288.448)" fill="#fff" font-size="40" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="36">Growth - Accepting Alternative Payments</tspan></text>
    <text id="ACI_PayAfter_ACI_GoPay_ACI_Crypto" data-name="ACI PayAfter &amp; ACI GoPay &amp; ACI Crypto" transform="translate(120 524)" fill="#fff" font-size="18" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="16">ACI PayAfter &amp; ACI </tspan><tspan x="0" y="37">GoPay &amp; ACI Crypto</tspan></text>
    <text id="Multi-Acquirer_with_Higher_Functionality_3DS_Wallets_" data-name="Multi-Acquirer with
Higher Functionality
(3DS, Wallets)" transform="translate(120 733.552)" fill="#fff" font-size="18" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="16">Multi-Acquirer with</tspan><tspan x="0" y="37">Higher Functionality</tspan><tspan x="0" y="58">(3DS, Wallets)</tspan></text>
    <text id="_BNPL_attracts_younger_demographic_and_critical_in_many_sectors_countries_Crypto_emerging_in_retail_space_42_of_GenZ_69_of_Millennials_more_likely_to_purchase_items_if_a_BNPL_service_is_offered_at_Checkout_" data-name="• BNPL attracts younger demographic and
  critical in many sectors / countries

• Crypto emerging in retail space

• 42% of GenZ &amp; 69% of Millennials more
  likely to purchase items if a BNPL service
  is offered at Checkout
" transform="translate(1159 200)" fill="#111" font-size="18" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="22">• BNPL attracts younger demographic and</tspan><tspan x="0" y="48" xml:space="preserve">  critical in many sectors / countries</tspan><tspan x="0" y="74"></tspan><tspan x="0" y="100">• Crypto emerging in retail space</tspan><tspan x="0" y="126"></tspan><tspan x="0" y="152">• 42% of GenZ &amp; 69% of Millennials more</tspan><tspan x="0" y="178" xml:space="preserve">  likely to purchase items if a BNPL service</tspan><tspan x="0" y="204" xml:space="preserve">  is offered at Checkout</tspan><tspan x="0" y="230"></tspan></text>
    <text id="Faster_Payments_cross_border" data-name="Faster Payments – 
cross border" transform="translate(396 524)" fill="#fff" font-size="18" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="16">Faster Payments – </tspan><tspan x="0" y="37">cross border</tspan></text>
    <text id="Payouts_Marketplace" data-name="Payouts &amp;
Marketplace" transform="translate(396 733.552)" fill="#fff" font-size="18" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="16">Payouts &amp;</tspan><tspan x="0" y="37">Marketplace</tspan></text>
    <text id="Pay_by_Link_QR_code" data-name="Pay by Link 
QR code" transform="translate(631 524)" fill="#fff" font-size="18" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="16">Pay by Link </tspan><tspan x="0" y="37">QR code</tspan></text>
    <text id="ACI_MSDK_IOT_-_Payments_anywhere" data-name="ACI MSDK 
IOT - Payments anywhere" transform="translate(631 733.552)" fill="#fff" font-size="18" font-family="Arial-BoldMT, Arial" font-weight="700"><tspan x="0" y="16">ACI MSDK </tspan><tspan x="0" y="37">IOT - Payments anywhere</tspan></text>
    <text id="Crypto_demand" data-name="Crypto demand" transform="translate(1159 430)" fill="#2726dd" font-size="40" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="49">Crypto demand</tspan></text>
    <text id="Consumer_demand_for_crypto_payments_already_exceeds_merchant_availability_across_four_major_industries_" data-name="Consumer demand for crypto payments already exceeds merchant availability across four major industries*" transform="translate(1159 511)" fill="#2726dd" font-size="25" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="31">Consumer demand for crypto payments </tspan><tspan x="0" y="61">already exceeds merchant availability across </tspan><tspan x="0" y="91">four major industries*</tspan></text>
    <g id="Group_3227" data-name="Group 3227" transform="translate(396 430)" clip-path="url(#clip-path)">
      <ellipse id="Ellipse_11" data-name="Ellipse 11" cx="17.676" cy="17.676" rx="17.676" ry="17.676" transform="translate(30.697 38.716)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_609" data-name="Path 609" d="M51.959,64.971a3.789,3.789,0,0,1,3.788-3.788h9.319" transform="translate(-10.503 -12.367)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_610" data-name="Path 610" d="M65.977,75.427a3.789,3.789,0,0,1-3.789,3.788H52.869" transform="translate(-10.687 -15.246)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_611" data-name="Path 611" d="M51.959,65.931a3.789,3.789,0,0,0,3.788,3.788H62" transform="translate(-10.503 -13.327)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_612" data-name="Path 612" d="M66.753,74.467a3.789,3.789,0,0,0-3.789-3.788H56.707" transform="translate(-11.463 -14.286)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_152" data-name="Line 152" y1="22.967" transform="translate(48.373 44.909)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_613" data-name="Path 613" d="M31.7,63.243A23.939,23.939,0,1,1,50.932,39.768q0,1.136-.1,2.245" transform="translate(-0.619 -3.2)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_614" data-name="Path 614" d="M36.493,51.137a67.071,67.071,0,0,1-9.5.653c-13.22,0-23.936-3.572-23.936-7.979S13.776,35.832,27,35.832,50.932,39.4,50.932,43.811a3.348,3.348,0,0,1-.881,2.152" transform="translate(-0.619 -7.243)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_615" data-name="Path 615" d="M34.934,61.254a5.908,5.908,0,0,1-4.4,2.449c-5.508,0-9.973-10.716-9.973-23.936s4.465-23.936,9.973-23.936,9.973,10.717,9.973,23.936a55.464,55.464,0,0,1-.476,7.326" transform="translate(-4.156 -3.2)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_616" data-name="Path 616" d="M62.2,4.322V12.44H54.08" transform="translate(-10.932 -0.873)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_617" data-name="Path 617" d="M1.5,85.055V76.937H9.618" transform="translate(-0.303 -15.551)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_618" data-name="Path 618" d="M57.951,11.87A35.294,35.294,0,0,0,33.067,1.5" transform="translate(-6.684 -0.303)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_619" data-name="Path 619" d="M1.5,76.937a35.294,35.294,0,0,0,25.035,10.37" transform="translate(-0.303 -15.551)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
    </g>
    <g id="Group_3231" data-name="Group 3231" transform="translate(631 656.059)" clip-path="url(#clip-path-2)">
      <rect id="Rectangle_836" data-name="Rectangle 836" width="74.128" height="45.368" rx="8.561" transform="translate(1.113 1.113)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_153" data-name="Line 153" x1="47.2" transform="translate(14.577 58.14)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_154" data-name="Line 154" y1="11.66" x2="5.357" transform="translate(30.048 46.481)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_155" data-name="Line 155" x1="5.357" y1="11.66" transform="translate(40.949 46.481)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_624" data-name="Path 624" d="M61.328,49.971V46.406A5.408,5.408,0,0,0,55.92,41H49.747l-1.09,1.457a3.07,3.07,0,0,1-4.918,0L42.649,41H36.475a5.408,5.408,0,0,0-5.407,5.408v3.565" transform="translate(-8.021 -10.584)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_625" data-name="Path 625" d="M56.584,20.658A7.856,7.856,0,1,1,48.728,12.8,7.856,7.856,0,0,1,56.584,20.658Z" transform="translate(-10.552 -3.305)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
    </g>
    <g id="Group_3249" data-name="Group 3249" transform="translate(396 656.06)" clip-path="url(#clip-path-3)">
      <path id="Path_635" data-name="Path 635" d="M0,67.826H6.6V42.755H0" transform="translate(0 -9.751)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_636" data-name="Path 636" d="M15.547,47.3h0a28.1,28.1,0,0,1,15.01-1.941l3.832.556,17.626,3.1a3.23,3.23,0,0,1-1,6.381l-14.6-2.021" transform="translate(-3.546 -10.279)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_637" data-name="Path 637" d="M54.588,53.061l16.606-7.549a3.325,3.325,0,0,1,3.037,5.907L56.661,61.547a27.958,27.958,0,0,1-11.626,3.638L32.829,66.208a27.967,27.967,0,0,1-6.018-.146l-11.264-1.5" transform="translate(-3.546 -10.312)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_638" data-name="Path 638" d="M55.077,22.8a5.84,5.84,0,1,1-5.84-5.839A5.839,5.839,0,0,1,55.077,22.8Z" transform="translate(-9.899 -3.869)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <ellipse id="Ellipse_14" data-name="Ellipse 14" cx="4.438" cy="4.438" rx="4.438" ry="4.438" transform="translate(49.005 23.405)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_639" data-name="Path 639" d="M70.017,5.735A4.235,4.235,0,1,1,65.782,1.5,4.235,4.235,0,0,1,70.017,5.735Z" transform="translate(-14.038 -0.342)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
    </g>
    <g id="Group_3251" data-name="Group 3251" transform="translate(120 454.044)" clip-path="url(#clip-path-4)">
      <rect id="Rectangle_849" data-name="Rectangle 849" width="79.727" height="48.794" rx="8.561" transform="translate(1.197 1.197)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_640" data-name="Path 640" d="M85.766,35.713V47H74.483" transform="translate(-15.056 -7.218)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_641" data-name="Path 641" d="M75.983,42.855V37.213h5.642" transform="translate(-15.359 -7.522)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_157" data-name="Line 157" x2="79.727" transform="translate(1.197 12.49)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <line id="Line_158" data-name="Line 158" x2="79.727" transform="translate(1.197 20.544)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
    </g>
    <g id="Group_3258" data-name="Group 3258" transform="translate(631 439.12)">
      <g id="Group_3257" data-name="Group 3257" transform="translate(0 0)">
        <path id="Path_642" data-name="Path 642" d="M57.327,28.876,52.552,24.1,28.674,47.978,16.736,36.039,11.96,40.814,26.287,55.141l2.387,2.388Z" transform="translate(-1.597 -7.651)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
        <g id="Group_3254" data-name="Group 3254" transform="translate(0 0)">
          <g id="Group_3253" data-name="Group 3253" transform="translate(0 0)" clip-path="url(#clip-path-5)">
            <ellipse id="Ellipse_15" data-name="Ellipse 15" cx="31.915" cy="31.915" rx="31.915" ry="31.915" transform="translate(1.506 1.152)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </g>
      </g>
    </g>
    <g id="Group_3256" data-name="Group 3256" transform="translate(120 645.016)" clip-path="url(#clip-path-6)">
      <path id="Path_643" data-name="Path 643" d="M102.277,26.473l5.8,5.8-5.8,5.8" transform="translate(-19.79 -5.122)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_644" data-name="Path 644" d="M25.969,11.464a34.021,34.021,0,0,1,48.112,0" transform="translate(-5.025 -0.29)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_645" data-name="Path 645" d="M74.081,73.21a34.021,34.021,0,0,1-48.112,0" transform="translate(-5.025 -14.166)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_646" data-name="Path 646" d="M50.126,62.058a24.2,24.2,0,1,1,24.2-24.2,24.195,24.195,0,0,1-24.2,24.2" transform="translate(-5.018 -2.645)" fill="none" stroke="#fff" stroke-width="2"/>
      <ellipse id="Ellipse_16" data-name="Ellipse 16" cx="24.195" cy="24.195" rx="24.195" ry="24.195" transform="translate(20.914 11.023)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="10.081" cy="24.195" rx="10.081" ry="24.195" transform="translate(35.028 11.023)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_647" data-name="Path 647" d="M25.931,41.733c0-4.454,10.832-8.065,24.2-8.065h43.18" transform="translate(-5.018 -6.515)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_648" data-name="Path 648" d="M69.715,43.668c0,4.454-10.832,8.065-24.2,8.065H2.122" transform="translate(-0.411 -8.45)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
      <path id="Path_649" data-name="Path 649" d="M7.924,58.078l-5.8-5.8,5.8-5.8" transform="translate(-0.41 -8.992)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="2"/>
    </g>
    <g id="Group_3397" data-name="Group 3397">
      <path id="Path_672" data-name="Path 672" d="M112-115.164A14.836,14.836,0,0,1,126.836-130H201.61a14.836,14.836,0,0,1,14.836,14.836h0a14.836,14.836,0,0,1-14.836,14.836H126.836A14.836,14.836,0,0,1,112-115.164Z" transform="translate(1286.745 768)" fill="#2725dd" fill-rule="evenodd"/>
      <g id="Group_3284" data-name="Group 3284" transform="translate(1399.338 638)">
        <path id="Path_674" data-name="Path 674" d="M0,14.836A14.836,14.836,0,0,1,14.836,0h380.99a14.836,14.836,0,0,1,14.836,14.836h0a14.836,14.836,0,0,1-14.836,14.836H14.836A14.836,14.836,0,0,1,0,14.836Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3286" data-name="Group 3286" transform="translate(1754.877 641.479)">
        <text id="_25_" data-name="25%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">25%</tspan></text>
      </g>
      <path id="Path_673" data-name="Path 673" d="M112-100.164A14.836,14.836,0,0,1,126.836-115H357.092a14.836,14.836,0,0,1,14.836,14.836h0a14.836,14.836,0,0,1-14.836,14.836H126.836A14.836,14.836,0,0,1,112-100.164Z" transform="translate(1286.745 791)" fill="#7e7e7e" fill-rule="evenodd"/>
      <g id="Group_3285" data-name="Group 3285" transform="translate(1399.338 676)">
        <path id="Path_675" data-name="Path 675" d="M0,14.836A14.836,14.836,0,0,1,14.836,0h380.99a14.836,14.836,0,0,1,14.836,14.836h0a14.836,14.836,0,0,1-14.836,14.836H14.836A14.836,14.836,0,0,1,0,14.836Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3287" data-name="Group 3287" transform="translate(1754.877 681.378)">
        <text id="_64_" data-name="64%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">64%</tspan></text>
      </g>
      <path id="Path_676" data-name="Path 676" d="M112-51.257A14.243,14.243,0,0,1,126.243-65.5H202.2a14.242,14.242,0,0,1,14.243,14.243h0A14.243,14.243,0,0,1,202.2-37.015H126.243A14.243,14.243,0,0,1,112-51.257Z" transform="translate(1286.745 854.5)" fill="#2725dd" fill-rule="evenodd"/>
      <g id="Group_3288" data-name="Group 3288" transform="translate(1399.338 789)">
        <path id="Path_678" data-name="Path 678" d="M0,14.243A14.243,14.243,0,0,1,14.242,0H396.42a14.243,14.243,0,0,1,14.242,14.243h0A14.243,14.243,0,0,1,396.42,28.485H14.242A14.243,14.243,0,0,1,0,14.243Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3290" data-name="Group 3290" transform="translate(1754.877 794.286)">
        <text id="_25_2" data-name="25%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">25%</tspan></text>
      </g>
      <path id="Path_677" data-name="Path 677" d="M112-36.257A14.243,14.243,0,0,1,126.243-50.5H279.351a14.243,14.243,0,0,1,14.243,14.243h0a14.243,14.243,0,0,1-14.243,14.243H126.243A14.243,14.243,0,0,1,112-36.257Z" transform="translate(1286.745 876.5)" fill="#7e7e7e" fill-rule="evenodd"/>
      <g id="Group_3289" data-name="Group 3289" transform="translate(1399.338 826)">
        <path id="Path_679" data-name="Path 679" d="M0,14.243A14.243,14.243,0,0,1,14.242,0H396.42a14.243,14.243,0,0,1,14.242,14.243h0A14.243,14.243,0,0,1,396.42,28.485H14.242A14.243,14.243,0,0,1,0,14.243Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3291" data-name="Group 3291" transform="translate(1754.877 832.591)">
        <text id="_44_" data-name="44%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">44%</tspan></text>
      </g>
      <path id="Path_689" data-name="Path 689" d="M112-83.257A14.243,14.243,0,0,1,126.243-97.5H202.2a14.242,14.242,0,0,1,14.243,14.243h0A14.243,14.243,0,0,1,202.2-69.015H126.243A14.243,14.243,0,0,1,112-83.257Z" transform="translate(1286.745 812.5)" fill="#2725dd" fill-rule="evenodd"/>
      <g id="Group_3301" data-name="Group 3301" transform="translate(1399.338 715)">
        <path id="Path_691" data-name="Path 691" d="M0,14.243A14.243,14.243,0,0,1,14.242,0H396.42a14.243,14.243,0,0,1,14.242,14.243h0A14.243,14.243,0,0,1,396.42,28.485H14.242A14.243,14.243,0,0,1,0,14.243Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3303" data-name="Group 3303" transform="translate(1754.877 718.034)">
        <text id="_25_3" data-name="25%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">25%</tspan></text>
      </g>
      <path id="Path_690" data-name="Path 690" d="M112-68.257A14.243,14.243,0,0,1,126.243-82.5h144.8a14.243,14.243,0,0,1,14.243,14.243h0a14.243,14.243,0,0,1-14.243,14.243h-144.8A14.243,14.243,0,0,1,112-68.257Z" transform="translate(1286.745 834.5)" fill="#7e7e7e" fill-rule="evenodd"/>
      <g id="Group_3302" data-name="Group 3302" transform="translate(1399.338 752)">
        <path id="Path_692" data-name="Path 692" d="M0,14.243A14.243,14.243,0,0,1,14.242,0H396.42a14.243,14.243,0,0,1,14.242,14.243h0A14.243,14.243,0,0,1,396.42,28.485H14.242A14.243,14.243,0,0,1,0,14.243Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3304" data-name="Group 3304" transform="translate(1754.877 755.396)">
        <text id="_42_" data-name="42%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">42%</tspan></text>
      </g>
      <path id="Path_693" data-name="Path 693" d="M112-18.664A14.836,14.836,0,0,1,126.836-33.5h172.1A14.836,14.836,0,0,1,313.77-18.664h0A14.836,14.836,0,0,1,298.934-3.828h-172.1A14.836,14.836,0,0,1,112-18.664Z" transform="translate(1286.745 898.5)" fill="#2725dd" fill-rule="evenodd"/>
      <g id="Group_3305" data-name="Group 3305" transform="translate(1399.338 865)">
        <path id="Path_695" data-name="Path 695" d="M0,14.836A14.836,14.836,0,0,1,14.836,0h380.99a14.836,14.836,0,0,1,14.836,14.836h0a14.836,14.836,0,0,1-14.836,14.836H14.836A14.836,14.836,0,0,1,0,14.836Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3307" data-name="Group 3307" transform="translate(1754.877 868.896)">
        <text id="_50_" data-name="50%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">50%</tspan></text>
      </g>
      <path id="Path_694" data-name="Path 694" d="M112-3.664A14.836,14.836,0,0,1,126.836-18.5h182.78A14.836,14.836,0,0,1,324.453-3.664h0a14.836,14.836,0,0,1-14.836,14.836H126.836A14.836,14.836,0,0,1,112-3.664Z" transform="translate(1286.745 921.5)" fill="#7e7e7e" fill-rule="evenodd"/>
      <g id="Group_3306" data-name="Group 3306" transform="translate(1399.338 903)">
        <path id="Path_696" data-name="Path 696" d="M0,14.836A14.836,14.836,0,0,1,14.836,0h380.99a14.836,14.836,0,0,1,14.836,14.836h0a14.836,14.836,0,0,1-14.836,14.836H14.836A14.836,14.836,0,0,1,0,14.836Z" fill="none" stroke="#2725dd" stroke-miterlimit="8" stroke-width="0.5"/>
      </g>
      <g id="Group_3308" data-name="Group 3308" transform="translate(1754.877 908.202)">
        <text id="_51_" data-name="51%" transform="translate(0 19)" fill="#2725dd" font-size="21" font-family="NeuePlakText-Regular, Neue Plak Text" letter-spacing="-0.006em"><tspan x="0" y="0">51%</tspan></text>
      </g>
      <g id="Group_3310" data-name="Group 3310" transform="translate(1333 642.723)">
        <g id="Group_3310-2" data-name="Group 3310" transform="translate(0 2.277)" clip-path="url(#clip-path-7)">
          <path id="Path_697" data-name="Path 697" d="M46.2,32.557A22.349,22.349,0,1,1,23.849,10.208" transform="translate(-0.383 -2.603)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_698" data-name="Path 698" d="M36.4,32.557c0,12.343-3.335,22.349-7.45,22.349S21.5,44.9,21.5,32.557s3.335-22.349,7.45-22.349" transform="translate(-5.483 -2.603)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_699" data-name="Path 699" d="M46.2,37.02c0,5.143-10.006,9.312-22.349,9.312S1.5,42.163,1.5,37.02s10.006-9.312,22.349-9.312" transform="translate(-0.383 -7.067)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_700" data-name="Path 700" d="M50.193,15.821l3,10.007,2.35-1.363.2-11.867,7.392-4.285a2.98,2.98,0,0,0-2.989-5.156L52.761,7.444l-10.4-5.721L40.012,3.086l7.192,7.58L41.621,13.9l-4.359-.284-2.8,1.625,6.388,2.552-.96,6.811,2.8-1.625,1.92-3.924Z" transform="translate(-8.788 -0.439)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
        </g>
      </g>
      <g id="Group_3311" data-name="Group 3311" transform="translate(1338 727)">
        <g id="Group_3311-2" data-name="Group 3311" transform="translate(0 0)" clip-path="url(#clip-path-8)">
          <path id="Path_701" data-name="Path 701" d="M57.174,36.948a3.047,3.047,0,1,1,3.047,3.047" transform="translate(-25.264 -14.98)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_702" data-name="Path 702" d="M13.426,36.948a3.047,3.047,0,1,1,3.047,3.047" transform="translate(-5.933 -14.98)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <line id="Line_159" data-name="Line 159" x1="4.546" transform="translate(0 11.491)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <line id="Line_160" data-name="Line 160" x1="4.546" transform="translate(40.951 11.491)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_703" data-name="Path 703" d="M8.6,56.058v4.535c0,.455-.172.824-.383.824H2.877c-.212,0-.383-.369-.383-.824V53.438" transform="translate(-1.102 -23.613)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_704" data-name="Path 704" d="M74.194,53.438v7.155c0,.455-.172.824-.383.824H68.476c-.212,0-.383-.369-.383-.824V56.058" transform="translate(-30.089 -23.613)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_705" data-name="Path 705" d="M38.348,25.283h-29a6.859,6.859,0,0,0-6.859,6.859V41H45.207V32.142a6.858,6.858,0,0,0-6.859-6.859Z" transform="translate(-1.102 -11.172)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <path id="Path_706" data-name="Path 706" d="M34.135,1.5H21.723a7.579,7.579,0,0,0-7.16,5.093l-2.84,8.18H44.134l-2.84-8.18A7.579,7.579,0,0,0,34.135,1.5Z" transform="translate(-5.18 -0.663)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
        </g>
      </g>
      <g id="Group_3314" data-name="Group 3314" transform="translate(1338 871)">
        <g id="Group_3314-2" data-name="Group 3314" transform="translate(0 0)" clip-path="url(#clip-path-9)">
          <path id="Path_709" data-name="Path 709" d="M25.074,48.648A23.574,23.574,0,1,1,48.648,25.074,23.574,23.574,0,0,1,25.074,48.648" transform="translate(-0.669 -0.669)" fill="none" stroke="#707070" stroke-width="2"/>
          <circle id="Ellipse_18" data-name="Ellipse 18" cx="23.574" cy="23.574" r="23.574" transform="translate(0.831 0.831)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <ellipse id="Ellipse_19" data-name="Ellipse 19" cx="12.966" cy="23.574" rx="12.966" ry="23.574" transform="translate(11.44 0.831)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <ellipse id="Ellipse_20" data-name="Ellipse 20" cx="23.574" cy="12.966" rx="23.574" ry="12.966" transform="translate(0.831 11.439)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <line id="Line_167" data-name="Line 167" y1="47.148" transform="translate(24.405 0.831)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
          <line id="Line_168" data-name="Line 168" x2="47.148" transform="translate(0.831 24.405)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="2"/>
        </g>
      </g>
      <text id="Travel" transform="translate(1193 658)" fill="#2726dd" font-size="25" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="50.2" y="31">Travel</tspan></text>
      <text id="Automotive" transform="translate(1167 732)" fill="#2726dd" font-size="25" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="14.9" y="31">Automotive</tspan></text>
      <text id="Hospitality" transform="translate(1167 807)" fill="#2726dd" font-size="25" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="26.35" y="31">Hospitality</tspan></text>
      <text id="Digital_Media" data-name="Digital Media" transform="translate(1159 882)" fill="#2726dd" font-size="25" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="6" y="31">Digital Media</tspan></text>
      <g id="Group_3323" data-name="Group 3323" transform="translate(1341 790)">
        <line id="Line_161" data-name="Line 161" x2="21.984" transform="translate(11.597 16.898)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
        <line id="Line_162" data-name="Line 162" x1="21.984" transform="translate(11.597 23.615)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
        <line id="Line_163" data-name="Line 163" x1="21.984" transform="translate(11.597 30.331)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
        <line id="Line_164" data-name="Line 164" x1="21.984" transform="translate(11.597 37.048)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_707" data-name="Path 707" d="M42.24,61.673V15.716H9.942V61.673" transform="translate(-3.502 -5.535)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
        <path id="Path_708" data-name="Path 708" d="M46.363,79.937V67.563H39.032V79.937" transform="translate(-13.748 -23.797)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
        <g id="Group_3313" data-name="Group 3313" transform="translate(0 0)">
          <g id="Group_3312" data-name="Group 3312" clip-path="url(#clip-path-10)">
            <line id="Line_165" data-name="Line 165" x2="45.178" transform="translate(0 61.559)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
            <line id="Line_166" data-name="Line 166" y1="10.181" transform="translate(12.563 0)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

`;

export const svgMobile = ({ width, height } : any) => 
`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width || 1920}" height="${height || 1920}" viewBox="0 0 1920 1080">
  <defs>
    <pattern id="pattern" preserveAspectRatio="xMidYMid slice" width="100%" height="100%" viewBox="0 0 5700 3784">
      <image width="5700" height="3784" xlink:href="${ASSETS.imgStep4Mobile}" />
    </pattern>
    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0a43ff"/>
      <stop offset="1" stop-color="#0a43ff"/>
    </linearGradient>
    <clipPath id="clip-Lightbox_10">
      <rect width="1920" height="1080"/>
    </clipPath>
  </defs>
  <g id="Lightbox_10" data-name="Lightbox – 10" clip-path="url(#clip-Lightbox_10)">
    <rect width="1920" height="1080" fill="#fff"/>
    <rect id="dreamstime_xxl_177798617" width="1978" height="1080" transform="translate(-43)" fill="url(#pattern)"/>
    <path id="city" d="M0,0H1935.226V1080H0Z" transform="translate(-8)" opacity="0.6" fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate"/>
    <text id="One-click_Check_Out" data-name="One-click Check Out" transform="translate(829 484.498)" fill="#fff" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">One-click Check Out</tspan></text>
    <text id="ACI_Smart_Engage" data-name="ACI Smart Engage" transform="translate(829 729)" fill="#fff" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">ACI Smart Engage</tspan></text>
    <g id="Group_3363" data-name="Group 3363" transform="translate(-48 23)">
      <path id="Path_725" data-name="Path 725" d="M41.732,88.05H33.943A5.452,5.452,0,0,1,28.49,82.6V69.356H47.184V82.6A5.452,5.452,0,0,1,41.732,88.05Z" transform="translate(872.558 600.185)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_726" data-name="Path 726" d="M37.06,16.532q-.539-.028-1.086-.028t-1.085.028c-14.072.713-22.534,16.038-16.233,28.641l7.971,15.942H45.321l7.971-15.942C59.593,32.57,51.131,17.245,37.06,16.532ZM25.845,36.39A10.129,10.129,0,0,1,35.974,26.261" transform="translate(874.421 608.426)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_173" data-name="Line 173" x2="9.347" transform="translate(910.394 676.048)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_174" data-name="Line 174" x2="8.349" transform="translate(877 644.394)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_175" data-name="Line 175" x2="8.349" transform="translate(935.44 644.394)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_176" data-name="Line 176" y1="8.349" transform="translate(910.394 611)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_177" data-name="Line 177" x2="5.903" y2="5.903" transform="translate(886.781 620.781)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_178" data-name="Line 178" y1="5.903" x2="5.903" transform="translate(928.105 620.781)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <rect id="Rectangle_862" data-name="Rectangle 862" width="66.789" height="78.502" transform="translate(877 611)" fill="none"/>
    </g>
    <text id="ACI_Token" data-name="ACI Token" transform="translate(471 493)" fill="#fff" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">ACI Token</tspan></text>
    <text id="Token_Optimisation_Scheme_Network_Device_Omni_eCommerce" data-name="Token Optimisation

Scheme | Network Device | Omni eCommerce" transform="translate(471 729)" fill="#fff" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Token Optimisation</tspan><tspan x="0" y="55"></tspan><tspan font-size="18"><tspan x="0" y="83">Scheme | Network Device | </tspan><tspan x="0" y="111">Omni eCommerce</tspan></tspan></text>
    <g id="Group_3366" data-name="Group 3366" transform="translate(-60 14)">
      <path id="Path_710" data-name="Path 710" d="M55.211,21.541a4.686,4.686,0,1,0-6.627,0A4.686,4.686,0,0,0,55.211,21.541Z" transform="translate(522.09 392.445)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <circle id="Ellipse_21" data-name="Ellipse 21" cx="4.686" cy="4.686" r="4.686" transform="translate(569.302 405.986)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_711" data-name="Path 711" d="M58.612,10.169,49.943,1.5H37.22L2.122,36.6,23.514,57.991l35.1-35.1Z" transform="translate(530.6 394.717)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_169" data-name="Line 169" x1="8.823" y2="8.823" transform="translate(542.47 423.269)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_712" data-name="Path 712" d="M56.007,38.459h12.2a9.57,9.57,0,1,0,0-19.141H52.987" transform="translate(521.001 391.355)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <rect id="Rectangle_857" data-name="Rectangle 857" width="68.999" height="59.429" transform="translate(531 395)" fill="none"/>
    </g>
    <g id="Group_3362" data-name="Group 3362" transform="translate(-61 23)">
      <path id="Path_727" data-name="Path 727" d="M34.5,7.055A34.639,34.639,0,0,1,0-27.722,34.639,34.639,0,0,1,34.5-62.5,34.639,34.639,0,0,1,69-27.722,34.676,34.676,0,0,1,34.5,7.055Zm0-67.385A32.477,32.477,0,0,0,2.154-27.722,32.477,32.477,0,0,0,34.5,4.885,32.477,32.477,0,0,0,66.845-27.722,32.513,32.513,0,0,0,34.5-60.329Z" transform="translate(532 681.5)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_728" data-name="Path 728" d="M42.973-35.04a12.466,12.466,0,0,0-16.144-6.279L26-43.351a14.626,14.626,0,0,1,18.919,7.335Z" transform="translate(534.935 683.532)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_729" data-name="Path 729" d="M10.3-15.073A28.1,28.1,0,0,1,7.476-39l2.045.722a25.979,25.979,0,0,0,2.6,22.087Z" transform="translate(532.677 684.153)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_730" data-name="Path 730" d="M10.9-40.976,9-42.085A28.489,28.489,0,0,1,28.007-56l.469,2.174A26.283,26.283,0,0,0,10.9-40.976Z" transform="translate(533.016 682.234)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_731" data-name="Path 731" d="M14.676-17.549a21.908,21.908,0,0,1,8.2-29.445,20.825,20.825,0,0,1,9.2-2.7v-6.8h1.072A27.659,27.659,0,0,1,57.628-41.507l-1.894,1.03A25.555,25.555,0,0,0,34.219-54.286v6.715H33.148A19.247,19.247,0,0,0,14.124-28.109a19.8,19.8,0,0,0,2.417,9.486Z" transform="translate(533.354 682.177)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_732" data-name="Path 732" d="M48.779-7.767,47.344-9.4a25.957,25.957,0,0,0,8.8-19.495,26.362,26.362,0,0,0-.8-6.534l-6.521,1.177-.294-.881A19.133,19.133,0,0,0,35.5-47.406l.567-2.094A21.29,21.29,0,0,1,50.235-36.706L56.864-37.9l.273.924A28.2,28.2,0,0,1,48.779-7.767Z" transform="translate(536.007 682.968)" fill="#fff" fill-rule="evenodd"/>
      <path id="Path_733" data-name="Path 733" d="M32.913-2.162a27.914,27.914,0,0,1-19.657-8.019L12.5-10.9l6.082-6.318.785.772a19.285,19.285,0,0,0,19.592,4.61L39.643-9.8a21.626,21.626,0,0,1-6.73,1.072A21.225,21.225,0,0,1,18.64-14.169l-3.1,3.216A25.953,25.953,0,0,0,45.186-7.379l-2.958-5.875.835-.522A18.9,18.9,0,0,0,52.045-28.85H47.561A14.651,14.651,0,0,1,31.833-15.386,14.614,14.614,0,0,1,18.274-31a14.524,14.524,0,0,1,3.288-8.168l.72-.829,6.8,5.539-.684.829a5.819,5.819,0,0,0,.828,8.227,5.916,5.916,0,0,0,8.285-.823,5.818,5.818,0,0,0-.828-8.227,5.913,5.913,0,0,0-3.736-1.322V-37.92a8.012,8.012,0,0,1,8.032,7.991,8.012,8.012,0,0,1-8.048,7.976A8.012,8.012,0,0,1,24.9-29.944a7.942,7.942,0,0,1,1.2-4.181L22.62-36.984a12.382,12.382,0,0,0,3.246,17.29,12.58,12.58,0,0,0,17.412-3.223,12.375,12.375,0,0,0,2.2-7.034v-1.072h8.76v1.072A21.021,21.021,0,0,1,45.02-12.518l3.009,5.947-.864.507A28.042,28.042,0,0,1,32.913-2.162Z" transform="translate(533.41 684.04)" fill="#fff" fill-rule="evenodd"/>
    </g>
    <text id="Digital_Journeys_Evolution_IOT_-_Instore_" data-name="Digital Journeys Evolution (IOT - Instore)" transform="translate(1157 491)" fill="#fff" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Digital Journeys </tspan><tspan x="0" y="55">Evolution (IOT - Instore)</tspan></text>
    <text id="Create_loyalty_through_tokens_and_smart_checkout_experiences" data-name="Create loyalty through
tokens and smart checkout experiences" transform="translate(1157 729)" fill="#fff" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Create loyalty through</tspan><tspan x="0" y="55">tokens and smart checkout </tspan><tspan x="0" y="83">experiences</tspan></text>
    <g id="Group_3364" data-name="Group 3364" transform="translate(-71 21)">
      <rect id="Rectangle_860" data-name="Rectangle 860" width="47.192" height="28.882" rx="6" transform="translate(1245.144 403.327)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_720" data-name="Path 720" d="M76.507,43.6v5.94H69.828" transform="translate(1209.784 376.627)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_171" data-name="Line 171" x2="47.192" transform="translate(1245.144 410.012)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_172" data-name="Line 172" x2="47.192" transform="translate(1245.144 415.518)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_721" data-name="Path 721" d="M13.15,2.224a39.773,39.773,0,0,0,0,56.247" transform="translate(1227.609 387.42)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_722" data-name="Path 722" d="M92.978,58.472a39.773,39.773,0,0,0,0-56.247" transform="translate(1203.745 387.42)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_723" data-name="Path 723" d="M7.086,2.224h7.521V9.745" transform="translate(1226.151 387.42)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_724" data-name="Path 724" d="M100.5,75.669H92.978V68.149" transform="translate(1203.745 370.222)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <rect id="Rectangle_861" data-name="Rectangle 861" width="81.48" height="59" transform="translate(1228 388)" fill="none"/>
    </g>
    <g id="Group_3365" data-name="Group 3365" transform="translate(-102 22)">
      <rect id="Rectangle_863" data-name="Rectangle 863" width="39.977" height="60.818" rx="8.561" transform="translate(1260.287 606.287)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_179" data-name="Line 179" x1="7.92" transform="translate(1283.255 648.294)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_180" data-name="Line 180" x1="7.92" transform="translate(1283.255 656.328)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_181" data-name="Line 181" x1="7.92" transform="translate(1269.377 648.294)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_182" data-name="Line 182" x1="7.92" transform="translate(1269.377 656.328)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_734" data-name="Path 734" d="M33.381,11.532A23.752,23.752,0,0,1,12.155,35.148" transform="translate(1257.276 603.364)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_183" data-name="Line 183" y2="16.568" transform="translate(1285.911 671.825)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_184" data-name="Line 184" y2="16.568" transform="translate(1280.633 671.825)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_735" data-name="Path 735" d="M34.884,77.873V91.008a3.433,3.433,0,0,1-3.433,3.433H14.137A3.433,3.433,0,0,1,10.7,91.008V77.873" transform="translate(1257.481 593.952)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="3"/>
      <rect id="Rectangle_864" data-name="Rectangle 864" width="42.551" height="84.68" transform="translate(1259 605)" fill="none"/>
    </g>
    <text id="Growth_-_Mobile_Tokenization_One-Click_" data-name="Growth - Mobile / Tokenization / One-Click " transform="translate(50 210)" fill="#fff" font-size="40" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="49">Growth - Mobile / Tokenization / One-Click </tspan></text>
    <g id="Group_3391" data-name="Group 3391" transform="translate(0 0.176)">
      <g id="Group_3390" data-name="Group 3390" transform="translate(828.5 468.324)">
        <path id="Path_787" data-name="Path 787" d="M60.258-20.5A19.627,19.627,0,0,1,40.5-39.992,19.627,19.627,0,0,1,60.243-59.5,19.627,19.627,0,0,1,80-40.007V-40A19.649,19.649,0,0,1,60.258-20.5Zm0-36.755A17.376,17.376,0,0,0,42.766-40,17.375,17.375,0,0,0,60.243-22.729,17.375,17.375,0,0,0,77.734-39.985V-40A17.39,17.39,0,0,0,60.258-57.255Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_788" data-name="Path 788" d="M56.094-31.5,48.5-39.345l1.574-1.618,6.021,6.228L68.934-48,70.5-46.382Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_789" data-name="Path 789" d="M63.5-.5H.5v-46H37.9v2.252H2.749v41.5h58.5V-17.077H63.5Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_790" data-name="Path 790" d="M57.5-12H34V-32h5.277v2.256H36.249v15.489h19v-3.038H57.5Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_791" data-name="Path 791" d="M30-7H6.5V-27H30ZM8.75-9.252h19v-15.5h-19Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_792" data-name="Path 792" d="M30-37h7.5v-2.5H30Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_793" data-name="Path 793" d="M10.5-38.25A1.75,1.75,0,0,1,8.75-36.5,1.75,1.75,0,0,1,7-38.25,1.75,1.75,0,0,1,8.75-40,1.75,1.75,0,0,1,10.5-38.25Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_794" data-name="Path 794" d="M17.5-38.25a1.75,1.75,0,0,1-1.75,1.75A1.75,1.75,0,0,1,14-38.25,1.75,1.75,0,0,1,15.75-40,1.75,1.75,0,0,1,17.5-38.25Z" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_795" data-name="Path 795" d="M24-38.25a1.75,1.75,0,0,1-1.75,1.75,1.75,1.75,0,0,1-1.75-1.75A1.75,1.75,0,0,1,22.25-40,1.75,1.75,0,0,1,24-38.25Z" fill="#fff" fill-rule="evenodd"/>
      </g>
    </g>
  </g>
</svg>
`

export const svgChargeBack = ({ width, height } : any) => 
`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width || 1920}" height="${height || 1920}" viewBox="0 0 1920 1080">
  <defs>
    <pattern id="pattern" width="1" height="1" viewBox="526.968 64.468 532.774 620.707">
      <image preserveAspectRatio="xMidYMid slice" width="1618.861" height="1080" xlink:href="${ASSETS.imgStep4CostOfDoingBusiness}" />
    </pattern>
    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#0a43ff"/>
      <stop offset="1" stop-color="#0a43ff"/>
    </linearGradient>
    <clipPath id="clip-path">
      <rect id="Rectangle_880" data-name="Rectangle 880" width="46.783" height="65.171" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_886" data-name="Rectangle 886" width="117.166" height="58.117" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-4">
      <rect id="Rectangle_879" data-name="Rectangle 879" width="64.266" height="71.069" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-5">
      <rect id="Rectangle_887" data-name="Rectangle 887" width="52.354" height="62.635" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-6">
      <rect id="Rectangle_878" data-name="Rectangle 878" width="80.556" height="66.845" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-7">
      <rect id="Rectangle_889" data-name="Rectangle 889" width="76.373" height="55.557" fill="none"/>
    </clipPath>
    <clipPath id="clip-Lightbox_15">
      <rect width="1920" height="1080"/>
    </clipPath>
  </defs>
  <g id="Lightbox_15" data-name="Lightbox – 15" clip-path="url(#clip-Lightbox_15)">
    <rect width="1920" height="1080" fill="#fff"/>
    <rect id="dreamstime_xxl_177798617" width="927" height="1080" transform="translate(993)" opacity="0.802" fill="url(#pattern)"/>
    <path id="city" d="M0,0H926.924V1080H0Z" transform="translate(993)" opacity="0.6" fill="url(#linear-gradient)" style="mix-blend-mode: multiply;isolation: isolate"/>
    <text id="Cost_Reduction_-_Cost_of_Doing_Business" data-name="Cost Reduction - Cost of Doing Business" transform="translate(81 203.5)" fill="#2726dd" font-size="40" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="49">Cost Reduction - Chargebacks</tspan></text>
    <g id="Group_3368" data-name="Group 3368" transform="translate(-48 41.393)">
      <g id="Group_2561" data-name="Group 2561" transform="translate(61 17.607)">
        <g id="Group_1795" data-name="Group 1795" transform="translate(1304.978 388.978)" opacity="0.35">
          <path id="Path_46" data-name="Path 46" d="M91.154,182.309A136.926,136.926,0,1,0-45.75,45.4,136.9,136.9,0,0,0,91.154,182.309Z" transform="translate(45.75 91.5)" fill="none" stroke="#fff" stroke-width="12"/>
        </g>
        <path id="Path_46-2" data-name="Path 46" d="M158.767,303.975A136.957,136.957,0,0,0,291.455,407.083h.013a136.9,136.9,0,1,0-.008-273.8" transform="translate(1150.422 255.703)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="12"/>
      </g>
      <text id="_70_" data-name="70%" transform="translate(1415 576.607)" fill="#fff" font-size="90" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="0">70%</tspan></text>
      <text id="Dedicated_fraud_platform_can_reduce_chargebacks_by" data-name="Dedicated fraud platform – can reduce chargebacks by" transform="translate(1316 317.607)" fill="#fff" font-size="25" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="11.012" y="31">Dedicated fraud platform – can </tspan><tspan x="53.887" y="68">reduce chargebacks by</tspan></text>
    </g>
    <text id="Risk_Service_Reviews_A_detailed_analytics_package_delivers_to_meet_customer_needs" data-name="Risk Service 
Reviews 

A detailed analytics package delivers to meet customer needs" transform="translate(81 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Risk Service </tspan><tspan x="0" y="51">Reviews </tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">A detailed analytics package </tspan><tspan x="0" y="123">delivers to meet customer needs</tspan></tspan></text>
    <text id="Multiple_Layer_end_to_end_Platform_Incremental_uplifts_against_each_module_that_supplements_complements_overall_performance_" data-name="Multiple Layer end to end Platform

Incremental uplifts against each module that supplements / complements overall performance " transform="translate(81 744.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Multiple Layer end to </tspan><tspan x="0" y="51">end Platform</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Incremental uplifts against </tspan><tspan x="0" y="123">each module that </tspan><tspan x="0" y="147">supplements / complements </tspan><tspan x="0" y="171">overall performance </tspan></tspan></text>
    <g id="Group_3377" data-name="Group 3377" transform="translate(81 358.793)">
      <g id="Group_3374" data-name="Group 3374" transform="translate(0 0)">
        <g id="Group_3373" data-name="Group 3373" clip-path="url(#clip-path)">
          <path id="Path_771" data-name="Path 771" d="M1.5,64.355V1.5H32.843L45.967,14.625v49.73Z" transform="translate(-0.342 -0.342)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
      <line id="Line_233" data-name="Line 233" x1="15.799" transform="translate(22.233 25.438)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_234" data-name="Line 234" x1="15.799" transform="translate(22.233 38.474)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_235" data-name="Line 235" x1="15.799" transform="translate(22.233 51.51)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3376" data-name="Group 3376" transform="translate(0 0)">
        <g id="Group_3375" data-name="Group 3375" clip-path="url(#clip-path)">
          <path id="Path_772" data-name="Path 772" d="M42.1,1.5V14.625H55.229" transform="translate(-9.604 -0.342)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
      <rect id="Rectangle_882" data-name="Rectangle 882" width="6.518" height="6.518" transform="translate(9.909 22.179)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <rect id="Rectangle_883" data-name="Rectangle 883" width="6.518" height="6.518" transform="translate(9.909 35.215)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <rect id="Rectangle_884" data-name="Rectangle 884" width="6.518" height="6.518" transform="translate(9.909 48.251)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
    </g>
    <g id="Group_3378" data-name="Group 3378" transform="translate(81 668.878)">
      <g id="Group_3378-2" data-name="Group 3378" transform="translate(0 0)" clip-path="url(#clip-path-3)">
        <path id="Path_773" data-name="Path 773" d="M32.573,13.556V6.664A5.164,5.164,0,0,1,37.736,1.5H87.677A5.164,5.164,0,0,1,92.84,6.664V16.818" transform="translate(-12.927 -0.595)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_236" data-name="Line 236" x1="33.196" transform="translate(26.926 37.789)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_237" data-name="Line 237" x1="14.245" transform="translate(26.927 9.973)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_238" data-name="Line 238" x1="22.772" transform="translate(26.926 16.222)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_239" data-name="Line 239" x1="18.422" transform="translate(26.926 22.472)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_240" data-name="Line 240" x1="9.211" transform="translate(26.926 28.721)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_774" data-name="Path 774" d="M50.723,72.135h25.8l-4.355-9.479" transform="translate(-20.131 -24.866)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_241" data-name="Line 241" y1="9.479" x2="4.355" transform="translate(43.17 37.789)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_242" data-name="Line 242" x1="12.445" transform="translate(71.95 31.431)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_243" data-name="Line 243" x1="20.328" transform="translate(71.95 36.89)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_775" data-name="Path 775" d="M146.214,61.352V40a4.51,4.51,0,0,0-4.511-4.511H108.162A4.511,4.511,0,0,0,103.651,40V61.352l-7.541,7.327A1.523,1.523,0,0,0,97.171,71.3h55.523a1.523,1.523,0,0,0,1.062-2.616Z" transform="translate(-37.959 -14.083)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_244" data-name="Line 244" x2="36.305" transform="translate(65.692 47.269)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_245" data-name="Line 245" x1="4.191" transform="translate(9.235 47.57)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <rect id="Rectangle_885" data-name="Rectangle 885" width="20.852" height="34.408" rx="8.561" transform="translate(0.905 17.503)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_776" data-name="Path 776" d="M17.473,29.02V39.033A15.973,15.973,0,0,1,1.5,55.006" transform="translate(-0.595 -11.517)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <text id="CB_Indemnification_CB_Defence_Options_Restructuring_of_end_to_end_fraud_strategy_and_commercials_-_reduce_cost_of_ownership_improve_conversion_" data-name="CB Indemnification &amp; CB Defence Options

Restructuring of end to end fraud strategy and commercials - reduce cost of ownership / improve conversion " transform="translate(389 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">CB Indemnification &amp; CB </tspan><tspan x="0" y="51">Defence Options</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Restructuring of end to end </tspan><tspan x="0" y="123">fraud strategy and commercials </tspan><tspan x="0" y="147">- reduce cost of ownership / </tspan><tspan x="0" y="171">improve conversion </tspan></tspan></text>
    <text id="Global_Consortium_Customers_and_Expertise_ACI_has_a_large_customer_base_dedicated_developers_and_experts_in_fraud_management" data-name="Global Consortium, Customers and Expertise

ACI has a large customer base, dedicated developers and experts in fraud management" transform="translate(389 744.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Global Consortium, </tspan><tspan x="0" y="51">Customers and Expertise</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">ACI has a large customer base, </tspan><tspan x="0" y="123">dedicated developers and </tspan><tspan x="0" y="147">experts in fraud management</tspan></tspan></text>
    <g id="Group_3372" data-name="Group 3372" transform="translate(389 353.154)">
      <g id="Group_3371" data-name="Group 3371" transform="translate(0 0)" clip-path="url(#clip-path-4)">
        <path id="Path_767" data-name="Path 767" d="M48.7,10.613A26.7,26.7,0,0,1,29.233,2.191,26.7,26.7,0,0,1,9.771,10.613H1.5V27.591A38.515,38.515,0,0,0,14.205,56.179L29.233,69.747,44.261,56.179A38.515,38.515,0,0,0,56.967,27.591V10.613Z" transform="translate(-0.395 -0.577)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_768" data-name="Path 768" d="M76.736,21.9V32.1a47.639,47.639,0,0,1-15.715,35.36l-9.476,8.555" transform="translate(-13.575 -5.766)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_769" data-name="Path 769" d="M48.8,57.506v-3.54a5.37,5.37,0,0,0-5.369-5.37H37.3L36.22,50.043a3.049,3.049,0,0,1-4.884,0L30.254,48.6h-6.13a5.37,5.37,0,0,0-5.369,5.37v3.54" transform="translate(-4.939 -12.799)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_770" data-name="Path 770" d="M44.16,28.2a7.8,7.8,0,1,1-7.8-7.8A7.8,7.8,0,0,1,44.16,28.2Z" transform="translate(-7.522 -5.373)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <g id="Group_3380" data-name="Group 3380" transform="translate(389 664.101)">
      <g id="Group_3379" data-name="Group 3379" transform="translate(0 0)" clip-path="url(#clip-path-5)">
        <path id="Path_777" data-name="Path 777" d="M51.465,73.406V61.773a8.929,8.929,0,0,0-8.929-8.929H32.343l-1.8,2.406a5.07,5.07,0,0,1-8.122,0l-1.8-2.406H10.429A8.929,8.929,0,0,0,1.5,61.773V73.406" transform="translate(-0.306 -10.771)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_246" data-name="Line 246" x1="7.339" transform="translate(34.996 55.469)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_778" data-name="Path 778" d="M41.922,18.513a12.972,12.972,0,1,1-16.286-8.444A12.972,12.972,0,0,1,41.922,18.513Z" transform="translate(-3.38 -1.928)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_779" data-name="Path 779" d="M27.388,10.437a12.969,12.969,0,0,0,16.277,8.421c.155-.049.3-.115.451-.169" transform="translate(-5.582 -2.127)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_780" data-name="Path 780" d="M8.77,13.615,4.58,16.751a5.07,5.07,0,0,0,0,8.122l2.406,1.8h.4v-5.86a19.312,19.312,0,1,1,38.623,0v5.86h.4l2.406-1.8a5.07,5.07,0,0,0,0-8.122l-4.189-3.135" transform="translate(-0.519 -0.306)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <text id="MOR_Outsourcing_Expertize_in_MOR_via_Arvato_partnership_" data-name="MOR 
Outsourcing

Expertize in MOR via 
Arvato (partnership)" transform="translate(701 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">MOR </tspan><tspan x="0" y="51">Outsourcing</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Expertize in MOR via </tspan><tspan x="0" y="123">Arvato (partnership)</tspan></tspan></text>
    <text id="CB_Automation_and_Business_Analytics_Immediate_access_and_insights_into_emerging_fraud_without_manual_work" data-name="CB Automation and Business Analytics 

Immediate access and insights into emerging fraud without manual work" transform="translate(701 744.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">CB Automation and </tspan><tspan x="0" y="51">Business Analytics </tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Immediate access and </tspan><tspan x="0" y="123">insights into emerging </tspan><tspan x="0" y="147">fraud without manual work</tspan></tspan></text>
    <g id="Group_3370" data-name="Group 3370" transform="translate(701 357.183)">
      <g id="Group_3369" data-name="Group 3369" transform="translate(0 0)" clip-path="url(#clip-path-6)">
        <path id="Path_763" data-name="Path 763" d="M25.124,91.169V85.99A4.222,4.222,0,0,0,20.9,81.768H5.722A4.222,4.222,0,0,0,1.5,85.99v5.179" transform="translate(-0.446 -24.324)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <ellipse id="Ellipse_30" data-name="Ellipse 30" cx="6.133" cy="6.133" rx="6.133" ry="6.133" transform="translate(6.733 39.709)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_764" data-name="Path 764" d="M103.162,91.169V85.99a4.222,4.222,0,0,0-4.221-4.222H83.76a4.222,4.222,0,0,0-4.222,4.222v5.179" transform="translate(-23.661 -24.324)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <ellipse id="Ellipse_31" data-name="Ellipse 31" cx="6.133" cy="6.133" rx="6.133" ry="6.133" transform="translate(61.557 39.709)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_765" data-name="Path 765" d="M64.143,36.146V30.967a4.222,4.222,0,0,0-4.221-4.222H44.741a4.222,4.222,0,0,0-4.222,4.222v5.179" transform="translate(-12.053 -7.956)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <ellipse id="Ellipse_32" data-name="Ellipse 32" cx="6.133" cy="6.133" rx="6.133" ry="6.133" transform="translate(34.145 1.054)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_766" data-name="Path 766" d="M41.882,69.142l5.424-5.423a7.669,7.669,0,0,1,10.847,0l5.439,5.438" transform="translate(-12.459 -18.286)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_232" data-name="Line 232" y1="10.944" transform="translate(40.271 32.242)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <g id="Group_3385" data-name="Group 3385" transform="translate(701 671.267)">
      <path id="Path_781" data-name="Path 781" d="M80.117,51.985H46.463V23.532" transform="translate(-11.986 -6.07)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_247" data-name="Line 247" y2="4.14" transform="translate(38.542 41.775)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_248" data-name="Line 248" y2="4.14" transform="translate(51.322 41.775)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_249" data-name="Line 249" y2="4.14" transform="translate(64.334 41.775)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_782" data-name="Path 782" d="M77.841,30.441h6.574v6.574" transform="translate(-20.081 -7.853)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_783" data-name="Path 783" d="M76.314,30.441,66.84,39.915,56.681,34.007,46.463,44.5" transform="translate(-11.986 -7.853)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3382" data-name="Group 3382" transform="translate(0 0)">
        <g id="Group_3381" data-name="Group 3381" clip-path="url(#clip-path-7)">
          <rect id="Rectangle_888" data-name="Rectangle 888" width="74.147" height="53.331" transform="translate(1.113 1.113)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
      <rect id="Rectangle_890" data-name="Rectangle 890" width="18.941" height="20.628" transform="translate(8.533 18.608)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_250" data-name="Line 250" x1="21.167" transform="translate(7.42 45.915)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3384" data-name="Group 3384" transform="translate(0 0)">
        <g id="Group_3383" data-name="Group 3383" clip-path="url(#clip-path-7)">
          <line id="Line_251" data-name="Line 251" x1="39.671" transform="translate(35.589 9.795)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_784" data-name="Path 784" d="M14.873,12.426A2.226,2.226,0,1,1,12.647,10.2a2.226,2.226,0,0,1,2.226,2.226" transform="translate(-2.688 -2.631)" fill="#2726dc"/>
          <path id="Path_785" data-name="Path 785" d="M26.128,12.426A2.226,2.226,0,1,1,23.9,10.2a2.226,2.226,0,0,1,2.226,2.226" transform="translate(-5.592 -2.631)" fill="#2726dc"/>
          <path id="Path_786" data-name="Path 786" d="M37.064,12.426A2.226,2.226,0,1,1,34.838,10.2a2.226,2.226,0,0,1,2.226,2.226" transform="translate(-8.413 -2.631)" fill="#2726dc"/>
        </g>
      </g>
    </g>
  </g>
</svg>

`

export const svgCostOfDoingBusiness = ({ width, height }: any) => 
`
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width || 1920}" height="${height || 1920}" viewBox="0 0 1920 1080">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_865" data-name="Rectangle 865" width="83.714" height="85.796" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <rect id="Rectangle_866" data-name="Rectangle 866" width="93" height="93" transform="translate(0 0)" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-3">
      <rect id="Rectangle_868" data-name="Rectangle 868" width="88.422" height="93" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-4">
      <rect id="Rectangle_869" data-name="Rectangle 869" width="64.159" height="85.796" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-6">
      <rect id="Rectangle_871" data-name="Rectangle 871" width="90.5" height="70.111" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-7">
      <rect id="Rectangle_874" data-name="Rectangle 874" width="123.717" height="78" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-8">
      <rect id="Rectangle_875" data-name="Rectangle 875" width="92.129" height="82.69" transform="translate(0 0)" fill="none"/>
    </clipPath>
    <clipPath id="clip-path-10">
      <rect id="Rectangle_877" data-name="Rectangle 877" width="123.717" height="93" fill="none"/>
    </clipPath>
    <clipPath id="clip-Lightbox_16">
      <rect width="1920" height="1080"/>
    </clipPath>
  </defs>
  <g id="Lightbox_16" data-name="Lightbox – 16" clip-path="url(#clip-Lightbox_16)">
    <rect width="1920" height="1080" fill="#fff"/>
    <text id="Cost_Reduction_-_Cost_of_Doing_Business" data-name="Cost Reduction - Cost of Doing Business" transform="translate(120 152.448)" fill="#2726dd" font-size="40" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="49">Cost Reduction - Cost of Doing Business</tspan></text>
    <text id="Single_Rest_API_to_Platform_Simple_Code_Last_integration_you_ever_need_to_make_" data-name="Single Rest API to Platform / Simple Code

Last integration you ever 
need to make!!!!!!" transform="translate(120 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Single Rest API to </tspan><tspan x="0" y="51">Platform / Simple Code</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Last integration you ever </tspan><tspan x="0" y="123">need to make!!!!!!</tspan></tspan></text>
    <g id="Group_3340" data-name="Group 3340" transform="translate(120 332)">
      <g id="Group_3339" data-name="Group 3339" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path id="Path_736" data-name="Path 736" d="M31.932,81.879h-4.86a5.214,5.214,0,0,1-5.282-4.916,5.072,5.072,0,0,1,5.07-5.228h5.072V61.591H21.788V56.732a5.213,5.213,0,0,0-4.916-5.282,5.072,5.072,0,0,0-5.229,5.069v5.072H1.5A30.432,30.432,0,0,0,31.932,92.023Z" transform="translate(-0.219 -7.508)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_737" data-name="Path 737" d="M79.441,63.324v4.86a5.214,5.214,0,0,1-4.916,5.282A5.072,5.072,0,0,1,69.3,68.4V63.324H59.153V73.468H54.294a5.213,5.213,0,0,0-5.282,4.916,5.072,5.072,0,0,0,5.069,5.229h5.072V93.756A30.432,30.432,0,0,0,89.585,63.324Z" transform="translate(-7.152 -9.241)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_738" data-name="Path 738" d="M60.887,11.644h4.86a5.214,5.214,0,0,1,5.282,4.916,5.072,5.072,0,0,1-5.07,5.228H60.887V31.932H71.031v4.859a5.213,5.213,0,0,0,4.916,5.282A5.072,5.072,0,0,0,81.175,37V31.932H91.319A30.432,30.432,0,0,0,60.887,1.5Z" transform="translate(-8.886 -0.219)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_739" data-name="Path 739" d="M11.644,31.932v-4.86A5.214,5.214,0,0,1,16.56,21.79a5.072,5.072,0,0,1,5.228,5.07v5.072H31.932V21.788h4.859a5.213,5.213,0,0,0,5.283-4.916A5.073,5.073,0,0,0,37,11.644H31.932V1.5A30.432,30.432,0,0,0,1.5,31.932Z" transform="translate(-0.219 -0.219)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <text id="Configuration_Over_Development_Majority_of_payment_ecosystem_available_through_configs_in_BIP_-_not_build_or_dev_work" data-name="Configuration Over Development

Majority of payment ecosystem available through configs in BIP - not build or dev work" transform="translate(452 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Configuration Over </tspan><tspan x="0" y="51">Development</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Majority of payment ecosystem </tspan><tspan x="0" y="123">available through configs in BIP - </tspan><tspan x="0" y="147">not build or dev work</tspan></tspan></text>
    <text id="Orchestration_to_Drive_Efficiency_in_Cost_of_Ownership_Optimize_payment_flows" data-name="Orchestration to Drive Efficiency in Cost of Ownership

Optimize payment flows" transform="translate(120 771.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Orchestration to Drive </tspan><tspan x="0" y="51">Efficiency in Cost of </tspan><tspan x="0" y="75">Ownership</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="99"></tspan><tspan x="0" y="123">Optimize payment flows</tspan></tspan></text>
    <text id="Single_Vendor_-_Full_Functionality_Stronger_partnerships_Lower_vendor_management_costs_Influence_roadmap" data-name="Single Vendor - Full Functionality

Stronger partnershipsLower vendor management costsInfluence roadmap" transform="translate(784 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Single Vendor - Full </tspan><tspan x="0" y="51">Functionality</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Stronger partnerships</tspan><tspan x="0" y="123">Lower vendor management costs</tspan><tspan x="0" y="147">Influence roadmap</tspan></tspan></text>
    <text id="Configuration_over_development_Reduce_internal_cost_to_operate_" data-name="Configuration over development

Reduce internal
cost to operate " transform="translate(784 771.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Configuration over </tspan><tspan x="0" y="51">development</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Reduce internal</tspan><tspan x="0" y="123">cost to operate </tspan></tspan></text>
    <text id="Unified_Settlements_Reporting_Efficient_processes_across_multiple_end_points" data-name="Unified Settlements &amp; Reporting

Efficient processes across multiple end points" transform="translate(452 771.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Unified Settlements &amp; </tspan><tspan x="0" y="51">Reporting</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Efficient processes across </tspan><tspan x="0" y="123">multiple end points</tspan></tspan></text>
    <text id="PCI_and_Compliance_Burden_Critical_cost_and_risk_internally_at_merchant_-_reducing_PCI_burden_80_" data-name="PCI and Compliance Burden 

Critical cost and risk internally
at merchant - reducing PCI burden 80+%
" transform="translate(1115 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">PCI and Compliance </tspan><tspan x="0" y="51">Burden </tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="75"></tspan><tspan x="0" y="99">Critical cost and risk internally</tspan><tspan x="0" y="123">at merchant - reducing PCI </tspan><tspan x="0" y="147">burden 80+%</tspan></tspan></text>
    <text id="Reduce_time_to_market_for_new_products_save_IT_project_resources" data-name="Reduce time to market for new products / save IT project resources" transform="translate(1115 771.072)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Reduce time to market </tspan><tspan x="0" y="51">for new products / save </tspan><tspan x="0" y="75">IT project resources</tspan></text>
    <text id="Speed_to_Market_Fast_Deployment_On-Boarding_APIs_UAT_environment_Tech_Support_Reduced_staff_internally_at_merchant_PSP_Realize_revenue_quicker_for_merchants" data-name="Speed to Market / Fast Deployment / On-Boarding APIs / UAT environment / Tech Support

Reduced staff internally at merchant / PSP Realize revenue quicker for merchants" transform="translate(1447 438.5)" fill="#2726dd" font-size="22" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="27">Speed to Market / Fast </tspan><tspan x="0" y="51">Deployment / On-Boarding </tspan><tspan x="0" y="75">APIs / UAT environment / </tspan><tspan x="0" y="99">Tech Support</tspan><tspan fill="#111" font-size="18" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="123"></tspan><tspan x="0" y="147">Reduced staff internally at </tspan><tspan x="0" y="171">merchant / PSP Realize revenue </tspan><tspan x="0" y="195">quicker for merchants</tspan></tspan></text>
    <g id="Group_3345" data-name="Group 3345" transform="translate(452 325)">
      <g id="Group_3345-2" data-name="Group 3345" clip-path="url(#clip-path-2)">
        <path id="Path_740" data-name="Path 740" d="M74.164,81.98a43.82,43.82,0,0,0,7.817-7.817l-6.63-10.15a33.556,33.556,0,0,0,3.937-9.5l11.861-2.489a43.7,43.7,0,0,0,0-11.055L79.288,38.483a33.518,33.518,0,0,0-3.938-9.5l6.631-10.152a43.82,43.82,0,0,0-7.817-7.817l-10.152,6.63a33.541,33.541,0,0,0-9.5-3.938L52.027,1.85a43.823,43.823,0,0,0-11.055,0L38.483,13.711a33.594,33.594,0,0,0-9.5,3.938l-10.151-6.63a43.82,43.82,0,0,0-7.817,7.817l6.63,10.151a33.565,33.565,0,0,0-3.938,9.5L1.85,40.973a43.815,43.815,0,0,0,0,11.054l11.861,2.489a33.541,33.541,0,0,0,3.938,9.5l-6.63,10.152a43.82,43.82,0,0,0,7.817,7.817l10.151-6.63a33.565,33.565,0,0,0,9.5,3.938L40.972,91.15a43.823,43.823,0,0,0,11.055,0l2.489-11.862a33.521,33.521,0,0,0,9.5-3.937ZM46.5,66.75A20.25,20.25,0,1,1,66.75,46.5,20.25,20.25,0,0,1,46.5,66.75Z" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <g id="Group_3346" data-name="Group 3346" transform="translate(784 325)">
      <path id="Path_741" data-name="Path 741" d="M44.359,8.934l5.818,5.818-5.818,5.819" transform="translate(-4.609 -0.928)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3347" data-name="Group 3347">
        <g id="Group_3346-2" data-name="Group 3346" clip-path="url(#clip-path-3)">
          <path id="Path_742" data-name="Path 742" d="M47.911,15.427A35.781,35.781,0,0,0,22.533,25.938" transform="translate(-2.341 -1.603)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_743" data-name="Path 743" d="M74.673,51.068l-5.818-5.818,5.818-5.819" transform="translate(-7.154 -4.097)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_744" data-name="Path 744" d="M68.854,44.7A35.781,35.781,0,0,0,94.232,34.193" transform="translate(-7.153 -3.552)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_185" data-name="Line 185" y1="27.698" transform="translate(14.32 56.592)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_745" data-name="Path 745" d="M50.131,92.076V74.94H37.359V92.076" transform="translate(-3.881 -7.786)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_186" data-name="Line 186" y1="21.924" transform="translate(65.408 62.366)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_187" data-name="Line 187" x2="79.728" transform="translate(0 91.656)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_746" data-name="Path 746" d="M8.854,53.953V44.9l8.8-8.836H48.283" transform="translate(-0.92 -3.747)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_747" data-name="Path 747" d="M21.626,56.027a6.386,6.386,0,0,1-12.772,0" transform="translate(-0.92 -5.821)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_748" data-name="Path 748" d="M35.878,56.027a6.386,6.386,0,0,1-12.772,0" transform="translate(-2.401 -5.821)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_749" data-name="Path 749" d="M50.131,56.027a6.386,6.386,0,0,1-12.772,0" transform="translate(-3.881 -5.821)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_188" data-name="Line 188" x2="39.43" transform="translate(7.934 41.152)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_189" data-name="Line 189" x2="6.729" transform="translate(66.973 8.314)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Rectangle_867" data-name="Rectangle 867" d="M8.561,0H24.92a8.561,8.561,0,0,1,8.561,8.561V46.687a8.562,8.562,0,0,1-8.562,8.562H8.561A8.561,8.561,0,0,1,0,46.688V8.561A8.561,8.561,0,0,1,8.561,0Z" transform="translate(53.597 1.344)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
    </g>
    <g id="Group_3352" data-name="Group 3352" transform="translate(1115 332)">
      <g id="Group_3349" data-name="Group 3349" transform="translate(0 0)">
        <g id="Group_3348" data-name="Group 3348" clip-path="url(#clip-path-4)">
          <path id="Path_750" data-name="Path 750" d="M1.5,75.458V1.5H38.379L53.822,16.943V75.458Z" transform="translate(-0.138 -0.137)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
      <line id="Line_190" data-name="Line 190" x1="20.092" transform="translate(10.297 26.097)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_191" data-name="Line 191" x1="34.453" transform="translate(10.297 33.766)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_192" data-name="Line 192" x1="27.944" transform="translate(10.297 41.436)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_193" data-name="Line 193" x1="14.427" transform="translate(10.296 49.105)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3351" data-name="Group 3351" transform="translate(0 0)">
        <g id="Group_3350" data-name="Group 3350" clip-path="url(#clip-path-4)">
          <path id="Path_751" data-name="Path 751" d="M42.1,1.5V16.943H57.547" transform="translate(-3.863 -0.137)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_752" data-name="Path 752" d="M63.856,28.536V87.051H11.534" transform="translate(-1.058 -2.618)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
    </g>
    <g id="Group_3355" data-name="Group 3355" transform="translate(1447 348.275)">
      <line id="Line_194" data-name="Line 194" y1="31.441" transform="translate(16.255 28.933)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
      <path id="Path_753" data-name="Path 753" d="M55.891,64.026V44.574h-14.5V64.026" transform="translate(-3.392 -3.653)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
      <line id="Line_195" data-name="Line 195" y1="31.441" transform="translate(74.246 28.933)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3354" data-name="Group 3354" transform="translate(0 0)">
        <g id="Group_3353" data-name="Group 3353" clip-path="url(#clip-path-6)">
          <line id="Line_196" data-name="Line 196" x2="90.5" transform="translate(0 68.734)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_754" data-name="Path 754" d="M9.81,21.807V11.53L19.8,1.5H72.308L82.3,11.53V21.807" transform="translate(-0.804 -0.123)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_755" data-name="Path 755" d="M24.308,23.62a7.249,7.249,0,1,1-14.5,0" transform="translate(-0.804 -1.936)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_756" data-name="Path 756" d="M40.1,23.62a7.249,7.249,0,0,1-14.5,0" transform="translate(-2.098 -1.936)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_757" data-name="Path 757" d="M55.891,23.62a7.249,7.249,0,1,1-14.5,0" transform="translate(-3.392 -1.936)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_758" data-name="Path 758" d="M71.683,23.62a7.249,7.249,0,1,1-14.5,0" transform="translate(-4.686 -1.936)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <path id="Path_759" data-name="Path 759" d="M87.475,23.62a7.249,7.249,0,1,1-14.5,0" transform="translate(-5.98 -1.936)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_197" data-name="Line 197" x2="57.991" transform="translate(23.504 11.406)" fill="none" stroke="#2726dd" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
    </g>
    <g id="Group_3356" data-name="Group 3356" transform="translate(120 676.072)">
      <line id="Line_210" data-name="Line 210" y1="25" transform="translate(84.717 9)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      <g id="Group_3358" data-name="Group 3358">
        <g id="Group_3357" data-name="Group 3357" clip-path="url(#clip-path-7)">
          <circle id="Ellipse_25" data-name="Ellipse 25" cx="37.5" cy="37.5" r="37.5" transform="translate(47.217 1.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_211" data-name="Line 211" x2="7.5" transform="translate(54.717 39)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_212" data-name="Line 212" x2="7.5" transform="translate(107.217 39)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_213" data-name="Line 213" y2="7.5" transform="translate(84.717 61.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <circle id="Ellipse_26" data-name="Ellipse 26" cx="5" cy="5" r="5" transform="translate(79.717 34)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <circle id="Ellipse_27" data-name="Ellipse 27" cx="5" cy="5" r="5" transform="translate(79.717 34)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_214" data-name="Line 214" x1="11.556" y1="11.19" transform="translate(88.252 42.535)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_215" data-name="Line 215" x2="37.933" transform="translate(0 39)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_216" data-name="Line 216" x2="30.267" transform="translate(9.666 50.25)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_217" data-name="Line 217" x2="21.884" transform="translate(23.05 61.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_218" data-name="Line 218" x2="30.267" transform="translate(9.666 27.75)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
          <line id="Line_219" data-name="Line 219" x2="21.884" transform="translate(23.05 16.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        </g>
      </g>
    </g>
    <g id="Group_3359" data-name="Group 3359" transform="translate(452 671.072)">
      <g id="Group_3359-2" data-name="Group 3359" clip-path="url(#clip-path-8)">
        <path id="Path_760" data-name="Path 760" d="M0,46.349l75.865,0v9.149L90.008,41.35,75.866,27.208v9.136L0,36.349" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_761" data-name="Path 761" d="M30.322,8.134,43.371,21.183,36.9,27.652h20v-20l-6.46,6.46L37.391,1.061" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <path id="Path_762" data-name="Path 762" d="M37.39,81.629,50.439,68.58l6.469,6.469v-20h-20l6.46,6.46L30.317,74.56" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <g id="Group_3360" data-name="Group 3360" transform="translate(784 661.072)">
      <g id="Group_3345-3" data-name="Group 3345" clip-path="url(#clip-path-2)">
        <path id="Path_740-2" data-name="Path 740" d="M74.164,81.98a43.82,43.82,0,0,0,7.817-7.817l-6.63-10.15a33.556,33.556,0,0,0,3.937-9.5l11.861-2.489a43.7,43.7,0,0,0,0-11.055L79.288,38.483a33.518,33.518,0,0,0-3.938-9.5l6.631-10.152a43.82,43.82,0,0,0-7.817-7.817l-10.152,6.63a33.541,33.541,0,0,0-9.5-3.938L52.027,1.85a43.823,43.823,0,0,0-11.055,0L38.483,13.711a33.594,33.594,0,0,0-9.5,3.938l-10.151-6.63a43.82,43.82,0,0,0-7.817,7.817l6.63,10.151a33.565,33.565,0,0,0-3.938,9.5L1.85,40.973a43.815,43.815,0,0,0,0,11.054l11.861,2.489a33.541,33.541,0,0,0,3.938,9.5l-6.63,10.152a43.82,43.82,0,0,0,7.817,7.817l10.151-6.63a33.565,33.565,0,0,0,9.5,3.938L40.972,91.15a43.823,43.823,0,0,0,11.055,0l2.489-11.862a33.521,33.521,0,0,0,9.5-3.937ZM46.5,66.75A20.25,20.25,0,1,1,66.75,46.5,20.25,20.25,0,0,1,46.5,66.75Z" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
    <g id="Group_3361" data-name="Group 3361" transform="translate(1115 661.072)">
      <g id="Group_3361-2" data-name="Group 3361" clip-path="url(#clip-path-10)">
        <circle id="Ellipse_28" data-name="Ellipse 28" cx="37.5" cy="37.5" r="37.5" transform="translate(47.217 16.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <rect id="Rectangle_876" data-name="Rectangle 876" width="22.5" height="7.5" transform="translate(73.467 1.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_220" data-name="Line 220" y2="7.5" transform="translate(88.467 9)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_221" data-name="Line 221" y1="7.5" transform="translate(80.967 9)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_222" data-name="Line 222" y1="25" transform="translate(84.717 24)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_223" data-name="Line 223" x2="7.5" transform="translate(54.717 54)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_224" data-name="Line 224" x2="7.5" transform="translate(107.217 54)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_225" data-name="Line 225" y2="7.5" transform="translate(84.717 76.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <circle id="Ellipse_29" data-name="Ellipse 29" cx="5" cy="5" r="5" transform="translate(79.717 49)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_226" data-name="Line 226" x1="11.19" y2="11.556" transform="translate(88.252 38.909)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_227" data-name="Line 227" x2="37.933" transform="translate(0 54)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_228" data-name="Line 228" x2="30.267" transform="translate(9.666 65.25)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_229" data-name="Line 229" x2="21.884" transform="translate(23.05 76.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_230" data-name="Line 230" x2="30.267" transform="translate(9.666 42.75)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
        <line id="Line_231" data-name="Line 231" x2="21.884" transform="translate(23.05 31.5)" fill="none" stroke="#2726dc" stroke-miterlimit="10" stroke-width="3"/>
      </g>
    </g>
  </g>
</svg>

`