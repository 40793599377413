import React, { useState } from 'react';
import { BgImageContainer, Text, SVGUI, ScaledScreenRatio } from 'components';
import { ASSETS } from 'assets';
import cc from '../@common-classes/Common.module.scss';
import cls from 'classnames';
import { useStore } from 'store';
import AddOnsPopup from './comps/AddOnsPopup';

const Step5 = () => {
  const [openAddOnsPopup, setOpenAddOnsPopup] = useState(true);
  const [store, setStore] = useStore();
  const onBack = () => {
    window.location.pathname = '/step-4';
  }
  const onNext = () => {
    window.location.pathname = '/step-6';
  };

  return (
    <BgImageContainer src={ASSETS.imgStep2BG} overlayColor={'rgba(19,39,68,0.35)'} middle>
      <ScaledScreenRatio width={1920} height={1080} >
        <div className={cls(cc.flex, cc.flex1)} style={{ padding: '15px 130px 30px 60px' }}>
          
        </div>
      </ScaledScreenRatio>
      <AddOnsPopup
        open={openAddOnsPopup}
        onClose={() => setOpenAddOnsPopup(false)}
      />
    </BgImageContainer>
  );
};

export default Step5;
