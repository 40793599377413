import React, { useEffect, useState } from 'react';
import { ASSETS } from 'assets';
import { BgImageContainer, Text, ScaledScreenRatio } from 'components';
import cls from 'classnames';
import cc from '../@common-classes/Common.module.scss';
import { useStore } from 'store';

import CurrencyAndVerticalDropdown from './comps/CurrencyAndVerticalDropdown';

const HomePage = () => {
  const [store, setStore, sheetData, displayValues, api] = useStore();
  const [selectedCurrency, setSelectedCurrency] = useState(store.currency ? {
    ...store.currency,
    label: store.currency.cc,
    value: store.currency.cc,
  } : undefined);
  const [selectedVertical, setSelectedVertical] = useState<undefined | { value: string, label: string }>(
    store.vertical ? { value: store.vertical, label: store.vertical } : undefined
  );

  const onInputCode = async () => {
    const code = window.prompt('Please provide your seassion code');
    if (!code) return alert('Please fill in the code');
    const data = await api.getDataFromCode(code);
    if (!data) return alert('Wrong code. Please try again');
    console.log('onInputCode', data);
    setSelectedVertical(
      data.vertical ? { value: data.vertical, label: data.vertical } : undefined
    );
    setSelectedCurrency({
      ...data.currency,
      label: data.currency.cc,
      value: data.currency.cc,
    });
    setStore({
      ...data,
    });
    alert('Data imported succesfully');
    window.location.pathname = '/step-1';
  }

  const handleNextStep = () => {
    if (!selectedCurrency || !selectedVertical) {
      return alert('Please select Currency and Vertical');
    }
    setStore({
      ...store,
      currency: selectedCurrency,
      vertical: selectedVertical?.value,
    });
    window.location.pathname = '/step-1';
  }

  const MiddleContainer = ({ children }: any) => (
    <div className={cls(cc.middle, cc.flex1, cc.p20)}>
      <div className={cc.middle} style={{ width: '50%' }}>
        {children}
      </div>
    </div>
  );
  return (
    <BgImageContainer src={ASSETS.imgHomeBG} overlayColor={'rgba(19,39,68,0.35)'} middle>
      <ScaledScreenRatio width={1920} height={1080}>
        <MiddleContainer>

          <img src={ASSETS.imgACILogo} style={{ width: 278, height: 'autos', marginBottom: 30, }} />

          <Text h1 white center style={{ marginBottom: 30 }}>
            Payments Optimization Packages
          </Text>

          <Text bold h4 white center style={{ marginBottom: 30 }}>
            Select Your Preferred Currency and Vertical
          </Text>

          <CurrencyAndVerticalDropdown
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            selectedVertical={selectedVertical}
            setSelectedVertical={setSelectedVertical}
            onNext={handleNextStep}
          />

          <Text bold h4 white center style={{ marginBottom: 30 }}>
            Enter Code to Resume Your Previous Session
          </Text>

          <button className={cc.whiteButton} style={{ width: 197 }} onClick={onInputCode}>
            Input Code
          </button>

        </MiddleContainer>
      </ScaledScreenRatio>
    </BgImageContainer>
  );
};

export default HomePage;