import React, { useEffect } from 'react';
import classes from './BgImageContainer.module.scss';

interface IProps {
  src: string,
  style?: any,
  overlayColor: string,
  middle?: boolean,
  [styleProps : string]: any,
}

const BgImageContainer = (props : IProps) => {
  // will send src information to the parent iframe and set the bg there
  const isInIframe = window.location !== window.parent.location;
  useEffect(() => {
    if (!isInIframe) return;
    window.parent.postMessage(`iframeMessage:bgImage:${props.src}__n__${props.overlayColor}`, '*');
  }, [props.src, props.overlayColor, isInIframe]);

  if (isInIframe) return (
    <div
      className={classes.imageContainer}
      style={props.style}
    >
      {props.children}
    </div>
  );

  return (
    <div
      className={classes.imageContainer}
      style={{
        backgroundImage: !!props.src ? `url("${props.src}")` : undefined,
      }}
    >
      {props.overlayColor ? (
        <div
          className={classes.overlay}
          style={{
            backgroundColor: !!props.overlayColor ? props.overlayColor : undefined,
            ...(props.middle ? {
              justifyContent: 'center',
              alignItems: 'center',
            } : {}),
            ...props.style,
          }}
        >
          {props.children}
        </div>
      ) : props.children}
    </div>
  )
};

export default BgImageContainer;