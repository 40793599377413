import React, { useEffect } from 'react';
import { Text, ScaledScreenRatio } from 'components';
import classes from './AddOnsPopup.module.scss';
import cc from '../../@common-classes/Common.module.scss';
import cls from 'classnames';
import { useStore } from 'store';

interface IDialogProps {
  open: boolean;
  onClose(): void;
}

const AddOnsPopup = (props: IDialogProps) => {
  const [store, setStore, sheetData, displayValues, api] = useStore();

  const onBack = () => {
    window.location.pathname = '/step-5';
  }
  const onNext = () => {
    window.location.pathname = '/step-7';
  };
  const onSave = async () => {
    if (!!store.Id && !!store.sessionCode) await api.updateData(store.sessionCode, store);
    else {
      const res = await api.saveDataAndGenerateCode(store);
      if (!!res)
        setStore({
          ...store,
          sessionCode: res.Code,
          Id: res.Id,
        })
      
    }
    alert('Your session has been saved');
  };

  if (!props.open) return null;

  const renderRow = ({ name, value, onChange }: any, index: number) => {
    return (
      <div className={classes.tableRow} key={'row-'+index}>
        <div>
          <span>{name}</span>
        </div>
        <div>
          <input
            value={value}
            onChange={(e: any) => onChange(e.target.value)}
          />
          <span>%</span>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <ScaledScreenRatio width={1920} height={1080}>
        <div className={classes.content}>
          <Text h2 blue>Step 6: Additional Functionality can be Purchased as Add-ons</Text>
          <div className={classes.addOnsTable}>
            <div className={classes.tableRow}>
              <div></div>
              <div>
                <Text h4 blue center>Add Uplift</Text>
              </div>
            </div>
            {store.addOnsTable.map((val, i) => (
              renderRow({
                name: val.name,
                value: val.uplift,
                onChange: (newValue : string) => {
                  const newList = store.addOnsTable.slice();
                  newList[i].uplift = Number(newValue);
                  if (isNaN(newList[i].uplift)) newList[i].uplift = 0;
                  setStore({
                    ...store,
                    addOnsTable: newList,
                  })
                } 
              }, i)
            ))}
          </div>
          <div className={cls(cc.row, cc.hiddenPrint)} style={{ justifyContent: 'space-between', width: 1182.5, marginTop: 30 }}>
              <div className={cc.row}>
                <button onClick={onBack} className={cc.mainButton} style={{ width: 222, marginRight: 20 }}>Back</button>
                <button onClick={onSave} className={cc.blueButton} style={{ width: 222, marginRight: 20 }}>Save</button>
                <Text blue semiBold style={{ fontSize: 12, marginRight: 20 }}>Enter This Code {store.sessionCode || '0000'} to <br />Resume Your Latest Session</Text>
                <button className={cc.blueButtonOutline} style={{ width: 222, marginRight: 20, paddingTop: 5 }}>
                  <Text blue semiBold style={{ fontSize: 40 }}>{store.sessionCode || '0000'}</Text>
                </button>
              </div>
              <div className={cc.row}>
                {/* <button onClick={onViewAddOns} className={cc.mainButton} style={{ width: 222, marginRight: 20 }}>View Add-ons</button> */}
                <button onClick={onNext} className={cc.mainButton} style={{ width: 222 }}>Next</button>
              </div>
            </div>
        </div>
      </ScaledScreenRatio>
    </div>
  );
};

export default AddOnsPopup; 