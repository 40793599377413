import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22.493}
    height={14.527}
    {...props}
  >
    <path
      data-name="Icon awesome-angle-down"
      d="M10.059 14.031.496 4.469a1.681 1.681 0 0 1 0-2.384L2.085.496a1.681 1.681 0 0 1 2.384 0l6.778 6.778L18.025.496a1.681 1.681 0 0 1 2.384 0l1.589 1.589a1.681 1.681 0 0 1 0 2.384l-9.562 9.562a1.672 1.672 0 0 1-2.377 0Z"
      fill="#111"
    />
  </svg>
)

export default SvgComponent
