import React, { useState } from 'react';
import { BgImageContainer, Text, SVGUI, ScaledScreenRatio } from 'components';
import { ASSETS } from 'assets';
import cc from '../@common-classes/Common.module.scss';
import cls from 'classnames';
import classes from './Step2.module.scss';
import { useStore } from 'store';
import PackagePopUp from './comps/PackagePopUp';
type TPackage = 'Silver' | 'Gold' | 'Platinum'
type TPopup = {
  open: boolean,
  package: TPackage
}

const Step2 = () => {
  const [store, setStore] = useStore();
  const [selectedPackage, setSelectedPackage] = useState<TPackage>();
  const [popupData, setPopupData] = useState<TPopup>({
    open: false,
    package: 'Silver',
  });
  const onBack = () => {
    window.location.pathname = '/step-1';
  }
  const onNext = () => {
    window.location.pathname = '/step-3';
  };
  const selectPackage = (value: TPackage) => {
    setSelectedPackage(value);
    setStore({
      ...store,
      selectedPackage: value,
    })
    onNext();
  };

  return (
    <BgImageContainer src={ASSETS.imgStep2BG} overlayColor={'rgba(19,39,68,0.35)'} middle>
      <ScaledScreenRatio width={1920} height={1080} >
        <div className={cls(cc.flex, cc.flex1)} style={{ padding: '15px 130px 30px 60px' }}>
          <div className={cls(cc.flex, cc.flex1, cc.middle)} style={{ paddingTop: 20 }}>
            <Text h2 white>Step 2: Please Select Your Preferred Package – Secure eCommerce</Text>

            <div className={cls(cc.flex, cc.row)} style={{ justifyContent: 'space-between', width: '50%', marginTop: 159 }}>
              <div
                onClick={() => selectPackage('Silver')}
                className={cls(classes.packageItem, classes.silver)}
              >
                <Text h2 blue center>Silver</Text>
                <button
                  onClick={() => selectPackage('Silver')}
                  className={cls(classes.selectBtn)}>Select</button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopupData({ open: true, package: 'Silver' });
                  }}
                  className={cls(classes.viewFeatureBtn)}>View features</button>
              </div>
              <div 
                className={cls(classes.packageItem, classes.gold)}>
                <Text h2 blue center>Gold</Text>
                <button
                  onClick={() => selectPackage('Gold')}
                  className={cls(classes.selectBtn)}>Select</button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopupData({ open: true, package: 'Gold' });
                  }}
                  className={cls(classes.viewFeatureBtn)}>View features</button>
              </div>
              <div 
                className={cls(classes.packageItem, classes.platinum)}>
                <Text h2 blue center>Platinum</Text>
                <button
                  onClick={() => selectPackage('Platinum')}
                  className={cls(classes.selectBtn)}>Select</button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopupData({ open: true, package: 'Platinum' });
                  }}
                  className={cls(classes.viewFeatureBtn)}>View features</button>
              </div>
            </div>
          </div>
          <div className={cc.row} style={{ justifyContent: 'space-between' }}>
            <button onClick={onBack} className={cc.mainButton} style={{ width: 222 }}>Back</button>
            <button onClick={onNext} className={cc.mainButton} style={{ width: 222 }}>Next</button>
          </div>

        </div>
      </ScaledScreenRatio>
      <PackagePopUp
        {...popupData}
        onClose={() => {
          setPopupData({
            ...popupData,
            open: false,
          })
        }}
      />
    </BgImageContainer>
  );
};

export default Step2;
