import React, { useEffect } from 'react';
import { Text, ScaledScreenRatio } from 'components';
import classes from './AddOnsPopup.module.scss';
import cc from '../../@common-classes/Common.module.scss';
import cls from 'classnames';
import { useStore } from 'store';
import { svgAcceptingAlternativePayment, svgMobile, svgChargeBack, svgCostOfDoingBusiness } from './svgContent';
import CloseIconSVG from './CloseIconSVG';

interface IDialogProps {
  open: boolean;
  type: string;
  onClose(): void;
}

const InfoPopup = (props: IDialogProps) => {
  let infoSVGPureHTML = null;
  switch (props.type) {
    case 'acceptingAlternativePayment':
      infoSVGPureHTML = svgAcceptingAlternativePayment;
      break;
    case 'mobile':
      infoSVGPureHTML = svgMobile;
      break;
    case 'costOfDoingBusiness':
      infoSVGPureHTML = svgCostOfDoingBusiness;
      break;
    case 'chargeBack':
      infoSVGPureHTML = svgChargeBack;
      break;
    default:
  }
  return !props.open ? null : (
    <div className={classes.container}>
      <ScaledScreenRatio width={1920} height={1080}>
        <div style={{ marginLeft: 1920 * 0.025, marginTop: 1080 * 0.025, position: 'relative' }}>
          {!!infoSVGPureHTML && (
            <div
              dangerouslySetInnerHTML={{
                __html: infoSVGPureHTML({
                  width: 1920 * 0.95,
                  height: 1080 * 0.95
                })
              }}>

            </div>
          )}
          <CloseIconSVG
            style={{
              position: 'absolute',
              top: '5%',
              right: '5%',
              cursor: 'pointer'
            }}
            onClick={props.onClose}
          />
        </div>
      </ScaledScreenRatio>
    </div>
  );
}

export default InfoPopup;