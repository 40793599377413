import { Child } from '../Child';

export const svgStep2Children = {
  SilverButton: ({ children }: any) => {
    return (
      <Child
        children={children}
        top={(855 * 100) / 912 + '%'}
        height={(45 * 100) / 912 + '%'}
        left={(16 * 100) / 1728 + '%'}
        width={(189 * 100) / 1728 + '%'}
      />
    );
  },
  GoldButton: ({ children }: any) => {
    return (
      <Child
        children={children}
        top={(855 * 100) / 912 + '%'}
        height={(45 * 100) / 912 + '%'}
        left={(600 * 100) / 1728 + '%'}
        width={(189 * 100) / 1728 + '%'}
      />
    );
  },
  PlatinumButton: ({ children }: any) => {
    return (
      <Child
        children={children}
        top={(855 * 100) / 912 + '%'}
        height={(45 * 100) / 912 + '%'}
        left={(1184 * 100) / 1728 + '%'}
        width={(189 * 100) / 1728 + '%'}
      />
    );
  },
};
export const svgStep2 = () =>
  `
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1728 912">
  <g id="Content" transform="translate(-60 -81)">
    <rect id="PlatinumContainer" width="560" height="912" rx="28" transform="translate(1228 81)" fill="#2726dd"/>
    <rect id="SilverContainer" width="560" height="912" rx="28" transform="translate(60 81)" fill="#e4e4e4"/>
    <g id="SilverContent">
      <text id="Silver_Conversion" data-name="Silver
Conversion" transform="translate(76 84.5)" fill="#2726dd" font-size="20" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="25">Silver</tspan><tspan font-size="14"><tspan x="0" y="45">Conversion</tspan></tspan></text>
      <text id="Cost_Reduction" data-name="Cost Reduction" transform="translate(76 567.5)" fill="#2726dd" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Cost Reduction</tspan></text>
      <text id="_Integrated_Fraud_Protection_-_Platform_Maintenance_-_Access_to_Fraud_Transaction_Interface_-_Access_to_Rule_Manager_-_5_Fraud_Sub-clients_with_Dedicated_Fraud_Strategies_-_Single_Call_Flow_Pre_or_Post_Auth_-_Active_Pa" data-name="•   Integrated Fraud Protection 
     - Platform Maintenance
     - Access to Fraud Transaction Interface
     - Access to Rule Manager
     - 5 Fraud Sub-clients with Dedicated Fraud Strategies
     - Single Call Flow (Pre or Post Auth)
     - Active &amp; Pa" transform="translate(76 161)" fill="#2726dd" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Integrated Fraud Protection </tspan><tspan x="0" y="27" xml:space="preserve">     - Platform Maintenance</tspan><tspan x="0" y="41" xml:space="preserve">     - Access to Fraud Transaction Interface</tspan><tspan x="0" y="55" xml:space="preserve">     - Access to Rule Manager</tspan><tspan x="0" y="69" xml:space="preserve">     - 5 Fraud Sub-clients with Dedicated Fraud Strategies</tspan><tspan x="0" y="83" xml:space="preserve">     - Single Call Flow (Pre or Post Auth)</tspan><tspan x="0" y="97" xml:space="preserve">     - Active &amp; Passive Mode Options</tspan><tspan x="0" y="111" xml:space="preserve">     - Address Verification &amp; Card Verification Value Checks</tspan><tspan x="0" y="125" xml:space="preserve">     - IP Geolocation Checks</tspan><tspan x="0" y="139" xml:space="preserve">     - CPF Validation (Brazil only)</tspan></text>
      <text id="Authentication_Fraud_Management" data-name="Authentication / Fraud Management" transform="translate(76 138)" fill="#2726dd" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Authentication / Fraud Management</tspan></text>
      <text id="_Platform_Maintenance_Access_to_Fraud_Transaction_Interface_Access_to_Rule_Manager_5_Fraud_Sub-clients_with_Dedicated_Fraud_Strategies_Single_Call_Flow_Pre_or_Post_Auth_Active_Passive_Mode_Options_Address_Verification_Card_" data-name="•   Platform Maintenance
•   Access to Fraud Transaction Interface
•   Access to Rule Manager
•   5 Fraud Sub-clients with Dedicated Fraud Strategies
•   Single Call Flow (Pre or Post Auth)
•   Active &amp; Passive Mode Options
•   Address Verification &amp; Card " transform="translate(76 615)" fill="#2726dd" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Platform Maintenance</tspan><tspan x="0" y="27" xml:space="preserve">•   Access to Fraud Transaction Interface</tspan><tspan x="0" y="41" xml:space="preserve">•   Access to Rule Manager</tspan><tspan x="0" y="55" xml:space="preserve">•   5 Fraud Sub-clients with Dedicated Fraud Strategies</tspan><tspan x="0" y="69" xml:space="preserve">•   Single Call Flow (Pre or Post Auth)</tspan><tspan x="0" y="83" xml:space="preserve">•   Active &amp; Passive Mode Options</tspan><tspan x="0" y="97" xml:space="preserve">•   Address Verification &amp; Card Verification Value Checks</tspan><tspan x="0" y="111" xml:space="preserve">•   IP Geolocation Checks</tspan><tspan x="0" y="125" xml:space="preserve">•   CPF Validation (Brazil only)</tspan></text>
      <text id="Chargebacks" transform="translate(76 592)" fill="#2726dd" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Chargebacks</tspan></text>
      <text id="_Single_API_Driven_SAAS_Payment_Orchestration_Platform_Low_Code_Development_Integration_Portal_PCI-DSS_Compliant_Customizable_Payment_Widget_Interfaces_Bulk_Merchants_Onboarding_Automation_Unified_Settlement_Chargeback_Reporting_" data-name="•   Single API Driven (SAAS) Payment Orchestration Platform
•   Low Code Development &amp; Integration Portal
•   PCI-DSS Compliant &amp; Customizable Payment Widget Interfaces
•   Bulk Merchants Onboarding Automation
•   Unified Settlement &amp; Chargeback Reporting " transform="translate(76 768)" fill="#2726dd" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Single API Driven (SAAS) Payment Orchestration Platform</tspan><tspan x="0" y="27" xml:space="preserve">•   Low Code Development &amp; Integration Portal</tspan><tspan x="0" y="41" xml:space="preserve">•   PCI-DSS Compliant &amp; Customizable Payment Widget Interfaces</tspan><tspan x="0" y="55" xml:space="preserve">•   Bulk Merchants Onboarding Automation</tspan><tspan x="0" y="69" xml:space="preserve">•   Unified Settlement &amp; Chargeback Reporting (Available for the Top 22 Acquirers)</tspan><tspan x="0" y="83" xml:space="preserve">•   Automated Webhook Reporting</tspan><tspan x="0" y="97" xml:space="preserve">•   Standard Reports &amp; Email Notifications</tspan><tspan x="0" y="111" xml:space="preserve">•   Business Intelligence Platform &amp; Monitoring</tspan><tspan x="0" y="125" xml:space="preserve">•   Project Based Development &amp; Testing Environment</tspan><tspan x="0" y="139" xml:space="preserve">•   24x7 Platform Availability &amp; Help Desk Support</tspan><tspan x="0" y="153" xml:space="preserve">•   Standard Access &amp; Maintenance, Regular Security / API / Compliance updates</tspan></text>
      <text id="Cost_of_Doing_Business" data-name="Cost of Doing Business" transform="translate(76 749)" fill="#2726dd" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Cost of Doing Business</tspan></text>
      <text id="Growth" transform="translate(76 426)" fill="#2726dd" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Growth</tspan></text>
      <text id="_ACI_PayAfter_Access_to_70_Global_BNPL_Providers_Ex._Klarna_One_Zip_Sezzle_etc._Crypto_Payments_with_Zero_Percent_Transaction_Processing_Fee_with_Volatility_Protection_and_One-Click_Payment" data-name="•   ACI PayAfter – Access to 70+ Global BNPL Providers (Ex. Klarna One, Zip, Sezzle, etc.)
•   Crypto Payments with Zero Percent Transaction Processing Fee with Volatility Protection, 
      and One-Click Payment" transform="translate(76 473.5)" fill="#2726dd" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   ACI PayAfter – Access to 70+ Global BNPL Providers (Ex. Klarna One, Zip, Sezzle, etc.)</tspan><tspan x="0" y="27" xml:space="preserve">•   Crypto Payments with Zero Percent Transaction Processing Fee with Volatility Protection, </tspan><tspan x="0" y="41" xml:space="preserve">      and One-Click Payment</tspan></text>
      <text id="Accepting_Alternative_Payments" data-name="Accepting Alternative Payments" transform="translate(76 450.5)" fill="#2726dd" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Accepting Alternative Payments</tspan></text>
      <text id="_ACI_Issuer_and_Acquirer_Tokenization-_Enabling_One-Click_Recurring_Payments" data-name="•   ACI, Issuer and Acquirer Tokenization- Enabling One-Click &amp; Recurring Payments" transform="translate(76 545.5)" fill="#2726dd" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   ACI, Issuer and Acquirer Tokenization- Enabling One-Click &amp; Recurring Payments</tspan></text>
      <text id="Mobile_Tokenization_One_click" data-name="Mobile / Tokenization / One click" transform="translate(76 522.5)" fill="#2726dd" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Mobile / Tokenization / One click</tspan></text>
      <text id="_Multi-Language_Multi-Currency_for_Global_Expansion_No._of_Acquirer_Processor_Connections_Included_3_No._of_Global_Branded_Wallets_Mobile_Pay_Connections_Included_2_Ex._ApplePay_GooglePay_PayPal_etc._Integration_with_All_Major_eC" data-name="•   Multi-Language Multi-Currency for Global Expansion
•   No. of Acquirer / Processor Connections Included – 3
•   No. of Global Branded Wallets / Mobile Pay Connections Included – 2 (Ex. ApplePay, GooglePay, PayPal etc.)
•   Integration with All Major eC" transform="translate(76 333)" fill="#2726dd" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Multi-Language Multi-Currency for Global Expansion</tspan><tspan x="0" y="27" xml:space="preserve">•   No. of Acquirer / Processor Connections Included – 3</tspan><tspan x="0" y="41" xml:space="preserve">•   No. of Global Branded Wallets / Mobile Pay Connections Included – 2 (Ex. ApplePay, GooglePay, PayPal etc.)</tspan><tspan x="0" y="55" xml:space="preserve">•   Integration with All Major eCommerce Platforms (Shopify, Magento)</tspan><tspan x="0" y="69" xml:space="preserve">•   Integration with Major CRM Software (Salesforce, CC, SAP Hybris)</tspan><tspan x="0" y="83" xml:space="preserve">•   Payment &amp; Fraud Analytics</tspan></text>
      <text id="Acceptance_Bank_Approvals" data-name="Acceptance / Bank Approvals" transform="translate(76 310)" fill="#2726dd" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Acceptance / Bank Approvals</tspan></text>
    </g>
    <g id="SilverButton" transform="translate(-748 195)">
      <g id="Group_2" data-name="Group 2" transform="translate(21.3 -16)">
        <rect id="bg_choosen" data-name="bg choosen" width="185" height="43" rx="21.5" transform="translate(802.7 757)" fill="#2726dd"/>
        <text id="Select_Package" data-name="Select Package" transform="translate(840.7 762)" fill="#fff" font-size="16" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="3.536" y="20">Select Package</tspan></text>
      </g>
    </g>
    <rect id="GoldContainer" width="560" height="912" rx="28" transform="translate(644 81)" fill="#26d7da"/>
    <g id="GoldButton" transform="translate(-164 195)">
      <g id="Group_2-2" data-name="Group 2" transform="translate(21.3 -16)">
        <rect id="bg_choosen-2" data-name="bg choosen" width="185" height="43" rx="21.5" transform="translate(802.7 757)" fill="#2726dd"/>
        <text id="Select_Package-2" data-name="Select Package" transform="translate(840.7 762)" fill="#fff" font-size="16" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="3.536" y="20">Select Package</tspan></text>
      </g>
    </g>
    <g id="GoldContent" transform="translate(-37 8)">
      <g id="Group_3121" data-name="Group 3121" transform="translate(0 27)">
        <g id="Group_3116" data-name="Group 3116">
          <text id="Cost_Reduction-2" data-name="Cost Reduction" transform="translate(697 567.5)" fill="#111" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Cost Reduction</tspan></text>
          <g id="Group_3115" data-name="Group 3115">
            <text id="_Global_Realtime_Self_Learning_Model_Case_Manager_Access_for_Manual_Reviews_Up_to_20_Sub-clients_Risk_Analytics_support_6_Reviews_Per_Year_Fuzzy_Matching_Click_Block_Positive_Negative_Listing_Autopilot_Rules_Rule_Categ" data-name="•   Global Realtime Self Learning Model
•   Case Manager Access for Manual Reviews
•   Up to 20 Sub-clients 
•   Risk Analytics support (6 Reviews Per Year)
•   Fuzzy Matching
•   Click &amp; Block Positive / Negative Listing
•   Autopilot Rules
•   Rule Categ" transform="translate(697 615)" fill="#111" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Global Realtime Self Learning Model</tspan><tspan x="0" y="27" xml:space="preserve">•   Case Manager Access for Manual Reviews</tspan><tspan x="0" y="41" xml:space="preserve">•   Up to 20 Sub-clients </tspan><tspan x="0" y="55" xml:space="preserve">•   Risk Analytics support (6 Reviews Per Year)</tspan><tspan x="0" y="69" xml:space="preserve">•   Fuzzy Matching</tspan><tspan x="0" y="83" xml:space="preserve">•   Click &amp; Block Positive / Negative Listing</tspan><tspan x="0" y="97" xml:space="preserve">•   Autopilot Rules</tspan><tspan x="0" y="111" xml:space="preserve">•   Rule Category Flags</tspan><tspan x="0" y="125" xml:space="preserve">•   Business Intelligence Reports &amp; Dashboards (ReDi)</tspan><tspan x="0" y="139" xml:space="preserve">•   Dedicated Development &amp; Testing Environment</tspan></text>
            <text id="Chargebacks-2" data-name="Chargebacks" transform="translate(697 592)" fill="#111" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Chargebacks</tspan></text>
          </g>
        </g>
        <g id="Group_3114" data-name="Group 3114" transform="translate(0 -17)">
          <text id="_Bespoke_Customizations_Included_90_hours_year_Ex._Functionality_Services_Transaction_Flows_Unified_Settlement_Chargeback_Reporting_for_Additional_Acquirers_Dedicated_Development_Testing_Environment" data-name="•   Bespoke Customizations Included – 90 hours/year (Ex. Functionality, Services, Transaction Flows)
•   Unified Settlement &amp; Chargeback Reporting (for Additional Acquirers)
•   Dedicated Development &amp; Testing Environment" transform="translate(697 798)" fill="#111" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Bespoke Customizations Included – 90 hours/year (Ex. Functionality, Services, Transaction Flows)</tspan><tspan x="0" y="27" xml:space="preserve">•   Unified Settlement &amp; Chargeback Reporting (for Additional Acquirers)</tspan><tspan x="0" y="41" xml:space="preserve">•   Dedicated Development &amp; Testing Environment</tspan></text>
          <text id="Cost_of_Doing_Business-2" data-name="Cost of Doing Business" transform="translate(697 779)" fill="#111" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Cost of Doing Business</tspan></text>
        </g>
      </g>
      <g id="Group_3120" data-name="Group 3120" transform="translate(0 18.5)">
        <g id="Group_3118" data-name="Group 3118">
          <text id="Growth-2" data-name="Growth" transform="translate(697 426)" fill="#111" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Growth</tspan></text>
          <text id="_No._of_Additional_Global_Branded_Wallets_Mobile_Pay_Connections_Included_1_Total_3_No._of_Local_Wallets_Included_1_Bank_Payments_2_ex._ACH_for_US2_SEPA_SEDA2_for_EU_etc._" data-name="•   No. of Additional Global Branded Wallets / Mobile Pay Connections Included – 1 (Total 3)
•   No. of Local Wallets Included – 1
•   Bank Payments 2 (ex. ACH for US2, SEPA/SEDA2 for EU, etc.)" transform="translate(697 473.5)" fill="#111" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   No. of Additional Global Branded Wallets / Mobile Pay Connections Included – 1 (Total 3)</tspan><tspan x="0" y="27" xml:space="preserve">•   No. of Local Wallets Included – 1</tspan><tspan x="0" y="41" xml:space="preserve">•   Bank Payments 2 (ex. ACH for US2, SEPA/SEDA2 for EU, etc.)</tspan></text>
          <text id="Accepting_Alternative_Payments-2" data-name="Accepting Alternative Payments" transform="translate(697 450.5)" fill="#111" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Accepting Alternative Payments</tspan></text>
        </g>
        <g id="Group_3117" data-name="Group 3117">
          <text id="_Mobile_Development_Kit_MSDK_" data-name="•  Mobile Development Kit (MSDK)" transform="translate(697 545.5)" fill="#111" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•  Mobile Development Kit (MSDK)</tspan></text>
          <text id="Mobile_Tokenization_One_click-2" data-name="Mobile / Tokenization / One click" transform="translate(697 522.5)" fill="#111" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Mobile / Tokenization / One click</tspan></text>
        </g>
      </g>
      <g id="Group_3122" data-name="Group 3122" transform="translate(0 25)">
        <text id="_3D_Secure_2.1_Advanced_Fraud_Protection_-_Global_Realtime_Self_Learning_Model_-_Case_Manager_access_for_Manual_Reviews_Auto_Analyst_-_Up_to_20_Sub-clients_5_in_Silver_Additional_15_in_Gold_-_Risk_Analytics_Support_6_revi" data-name="•   3D Secure 2.1
•   Advanced Fraud Protection
     - Global Realtime Self Learning Model
     - Case Manager access for Manual Reviews &amp; Auto Analyst 
     - Up to 20 Sub-clients (5 in Silver + Additional 15 in Gold)
     - Risk Analytics Support (6 revi" transform="translate(697 161)" fill="#111" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   3D Secure 2.1</tspan><tspan x="0" y="27" xml:space="preserve">•   Advanced Fraud Protection</tspan><tspan x="0" y="41" xml:space="preserve">     - Global Realtime Self Learning Model</tspan><tspan x="0" y="55" xml:space="preserve">     - Case Manager access for Manual Reviews &amp; Auto Analyst </tspan><tspan x="0" y="69" xml:space="preserve">     - Up to 20 Sub-clients (5 in Silver + Additional 15 in Gold)</tspan><tspan x="0" y="83" xml:space="preserve">     - Risk Analytics Support (6 reviews per year)</tspan><tspan x="0" y="97" xml:space="preserve">     - Fuzzy Matching</tspan><tspan x="0" y="111" xml:space="preserve">     - Click &amp; Block Positive/Negative Listing</tspan><tspan x="0" y="125" xml:space="preserve">     - Autopilot Rules</tspan><tspan x="0" y="139" xml:space="preserve">     - Rule Category Flags</tspan><tspan x="0" y="153" xml:space="preserve">     - Business Intelligence Reports &amp; Dashboards (ReDi)</tspan><tspan x="0" y="167" xml:space="preserve">     - Dedicated Development &amp; Testing Environment</tspan></text>
        <text id="Authentication_Fraud_Management-2" data-name="Authentication / Fraud Management" transform="translate(697 138)" fill="#111" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Authentication / Fraud Management</tspan></text>
        <g id="Group_3119" data-name="Group 3119">
          <text id="_No._of_Additional_Acquirer_Processor_Connections_Included_5_Total_8_No._of_Local_Acquirer_Processor_Connections_-_1_Intelligent_Payment_Routing_Ex._Least_Cost_Routing_" data-name="•   No. of Additional Acquirer / Processor Connections Included – 5 (Total 8)
•   No. of Local Acquirer / Processor Connections - 1
•   Intelligent Payment Routing (Ex. Least Cost Routing)" transform="translate(697 359)" fill="#111" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   No. of Additional Acquirer / Processor Connections Included – 5 (Total 8)</tspan><tspan x="0" y="27" xml:space="preserve">•   No. of Local Acquirer / Processor Connections - 1</tspan><tspan x="0" y="41" xml:space="preserve">•   Intelligent Payment Routing (Ex. Least Cost Routing)</tspan></text>
          <text id="Acceptance_Bank_Approvals-2" data-name="Acceptance / Bank Approvals" transform="translate(697 336)" fill="#111" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Acceptance / Bank Approvals</tspan></text>
        </g>
      </g>
      <text id="Conversion" transform="translate(697 138)" fill="#111" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Conversion</tspan></text>
      <text id="Gold_EVERYTHING_IN_SLIVER_PLUS:" data-name="Gold 
EVERYTHING IN SLIVER, PLUS:" transform="translate(697 76.5)" fill="#111" font-size="20" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="25">Gold </tspan><tspan font-size="12" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="45">EVERYTHING IN SLIVER, PLUS:</tspan></tspan></text>
    </g>
    <g id="PlatinumContentButton" transform="translate(420 195)">
      <g id="Group_2-3" data-name="Group 2" transform="translate(21.3 -16)">
        <path id="bg_choosen-3" data-name="bg choosen" d="M21.5,0h142a21.582,21.582,0,0,1,21.138,17.548A20.864,20.864,0,0,1,185,21.5,21.5,21.5,0,0,1,163.5,43H21.5a21.5,21.5,0,0,1,0-43Z" transform="translate(802.7 757)" fill="none" stroke="#fff" stroke-width="1"/>
        <text id="Select_Package-3" data-name="Select Package" transform="translate(840.7 762)" fill="#fff" font-size="16" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="3.536" y="20">Select Package</tspan></text>
      </g>
    </g>
    <g id="PlatinumContent" transform="translate(-73)">
      <text id="Platinum_EVERYTHING_IN_SILVER_AND_GOLD_PLUS:" data-name="Platinum 
EVERYTHING IN SILVER AND GOLD, PLUS:" transform="translate(1317 84.5)" fill="#fff" font-size="20" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="25">Platinum </tspan><tspan font-size="12" font-family="NeuePlakText-Regular, Neue Plak Text" font-weight="400"><tspan x="0" y="45">EVERYTHING IN SILVER AND GOLD, PLUS:</tspan></tspan></text>
      <g id="Group_3128" data-name="Group 3128" transform="translate(0 -7.5)">
        <text id="Cost_Reduction-3" data-name="Cost Reduction" transform="translate(1317 602.5)" fill="#fff" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Cost Reduction</tspan></text>
        <text id="_Vertical_Based_Realtime_Self_Learning_Model_Unlimited_Sub-clients_Orchestration_to_3rd_Parties_Device_ID_Checks_at_No_Additional_Cost_Multiple_Calls_to_Fraud_Platform_Customized_Fraud_Strategy_Manager_Customized_List_Manager_" data-name="•   Vertical Based Realtime Self Learning Model
•   Unlimited Sub-clients
•   Orchestration to 3rd Parties
•   Device ID Checks at No Additional Cost
•   Multiple Calls to Fraud Platform
•   Customized Fraud Strategy Manager
•   Customized List Manager
•  " transform="translate(1317 650)" fill="#fff" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Vertical Based Realtime Self Learning Model</tspan><tspan x="0" y="27" xml:space="preserve">•   Unlimited Sub-clients</tspan><tspan x="0" y="41" xml:space="preserve">•   Orchestration to 3rd Parties</tspan><tspan x="0" y="55" xml:space="preserve">•   Device ID Checks at No Additional Cost</tspan><tspan x="0" y="69" xml:space="preserve">•   Multiple Calls to Fraud Platform</tspan><tspan x="0" y="83" xml:space="preserve">•   Customized Fraud Strategy Manager</tspan><tspan x="0" y="97" xml:space="preserve">•   Customized List Manager</tspan><tspan x="0" y="111" xml:space="preserve">•   Tieback to Custom Merchant URL</tspan><tspan x="0" y="125" xml:space="preserve">•   Auto-analyst in Case Manager</tspan><tspan x="0" y="139" xml:space="preserve">•   Custom Business Intelligence Reports &amp; Dashboards</tspan><tspan x="0" y="153" xml:space="preserve">•   Dedicated Risk Analyst (Access to Support &amp; Analytics as Required)</tspan></text>
        <text id="Chargebacks-3" data-name="Chargebacks" transform="translate(1317 627)" fill="#fff" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Chargebacks</tspan></text>
      </g>
      <g id="Group_3127" data-name="Group 3127" transform="translate(0 -15)">
        <g id="Group_3114-2" data-name="Group 3114" transform="translate(620 40)">
          <text id="_White_Label_Offering_Bespoke_Customizations_Included_160_hours_year_Ex._Functionality_Services_Transaction_Flows_Monitoring_Advanced_Grafana_dashboards_1" data-name="•   White Label Offering
•   Bespoke Customizations Included – 160 hours/year (Ex. Functionality, Services, Transaction Flows) 
•   Monitoring Advanced (Grafana dashboards)1" transform="translate(697 798)" fill="#fff" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   White Label Offering</tspan><tspan x="0" y="27" xml:space="preserve">•   Bespoke Customizations Included – 160 hours/year (Ex. Functionality, Services, Transaction Flows) </tspan><tspan x="0" y="41" xml:space="preserve">•   Monitoring Advanced (Grafana dashboards)1</tspan></text>
          <text id="Cost_of_Doing_Business-3" data-name="Cost of Doing Business" transform="translate(697 779)" fill="#fff" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Cost of Doing Business</tspan></text>
        </g>
      </g>
      <g id="Group_3130" data-name="Group 3130">
        <g id="Group_3118-2" data-name="Group 3118" transform="translate(620 32)">
          <text id="Growth-3" data-name="Growth" transform="translate(697 426)" fill="#fff" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Growth</tspan></text>
          <text id="_Unlimited_Wallets_in_Platform_Network_Integration_of_New_Local_Wallets_5_When_Solution_is_Available_" data-name="•   Unlimited Wallets in Platform Network
•   Integration of New Local Wallets – 5 (When Solution is Available)" transform="translate(697 473.5)" fill="#fff" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Unlimited Wallets in Platform Network</tspan><tspan x="0" y="27" xml:space="preserve">•   Integration of New Local Wallets – 5 (When Solution is Available)</tspan></text>
          <text id="Accepting_Alternative_Payments-3" data-name="Accepting Alternative Payments" transform="translate(697 450.5)" fill="#fff" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Accepting Alternative Payments</tspan></text>
        </g>
        <g id="Group_3117-2" data-name="Group 3117" transform="translate(620 18.5)">
          <text id="_Support_for_Scheme_Network_Tokens1" data-name="•  Support for Scheme/ Network Tokens1" transform="translate(697 545.5)" fill="#fff" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•  Support for Scheme/ Network Tokens1</tspan></text>
          <text id="Mobile_Tokenization_One_click-3" data-name="Mobile / Tokenization / One click" transform="translate(697 522.5)" fill="#fff" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Mobile / Tokenization / One click</tspan></text>
        </g>
      </g>
      <g id="Group_3129" data-name="Group 3129">
        <text id="_3D_Secure_2.1_Exemption_Processing_Delegated_Authentication_Application_Fraud_Platinum_Refer_to_Fraud_Section_for_Details_-_Vertical_Based_Realtime_Self_Learning_Model_-_Unlimited_Sub-clients_-_Orchestration_of_Fraud_Management" data-name="•   3D Secure 2.1 &amp; Exemption Processing (Delegated Authentication Application)
•   Fraud Platinum (Refer to Fraud Section for Details)
     - Vertical Based Realtime Self Learning Model
     - Unlimited Sub-clients
     - Orchestration of Fraud Management" transform="translate(1317 194)" fill="#fff" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   3D Secure 2.1 &amp; Exemption Processing (Delegated Authentication Application)</tspan><tspan x="0" y="27" xml:space="preserve">•   Fraud Platinum (Refer to Fraud Section for Details)</tspan><tspan x="0" y="41" xml:space="preserve">     - Vertical Based Realtime Self Learning Model</tspan><tspan x="0" y="55" xml:space="preserve">     - Unlimited Sub-clients</tspan><tspan x="0" y="69" xml:space="preserve">     - Orchestration of Fraud Management &amp; Payment Flows</tspan><tspan x="0" y="83" xml:space="preserve">       (Includes Pre &amp; Post Auth Flow, Also Possible with 3rd Party Services)</tspan><tspan x="0" y="97" xml:space="preserve">     - Device ID Checks </tspan><tspan x="0" y="111" xml:space="preserve">     - Customized Fraud Strategy Manager</tspan><tspan x="0" y="125" xml:space="preserve">     - Customized List Manager</tspan><tspan x="0" y="139" xml:space="preserve">     - Tieback to Custom Merchant URL</tspan><tspan x="0" y="153" xml:space="preserve">     - Custom Business Intelligence Report &amp; Dashboards</tspan><tspan x="0" y="167" xml:space="preserve">     - Dedicated Risk Analyst (Access to Support and Analytics as Required)</tspan></text>
        <text id="Authentication_Fraud_Management-3" data-name="Authentication / Fraud Management" transform="translate(1317 171)" fill="#fff" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Authentication / Fraud Management</tspan></text>
      </g>
      <g id="Group_3126" data-name="Group 3126">
        <text id="_Unlimited_Acquirer_Connections_in_Platform_Network_Connect-IN_Connect_Your_Own_End-Points_Real_Time_Account_Updater_Intelligent_Payment_Dynamic_Routing_Smart_Retry_AB_Testing_" data-name="•   Unlimited Acquirer Connections in Platform Network
•   Connect-IN (Connect Your Own End-Points)
•   Real Time Account Updater
•   Intelligent Payment Dynamic Routing  (Smart Retry / AB Testing)" transform="translate(1317 392)" fill="#fff" font-size="11" font-family="NeuePlakText-Regular, Neue Plak Text"><tspan x="0" y="13" xml:space="preserve">•   Unlimited Acquirer Connections in Platform Network</tspan><tspan x="0" y="27" xml:space="preserve">•   Connect-IN (Connect Your Own End-Points)</tspan><tspan x="0" y="41" xml:space="preserve">•   Real Time Account Updater</tspan><tspan x="0" y="55" xml:space="preserve">•   Intelligent Payment Dynamic Routing  (Smart Retry / AB Testing)</tspan></text>
        <text id="Acceptance_Bank_Approvals-3" data-name="Acceptance / Bank Approvals" transform="translate(1317 369)" fill="#fff" font-size="12" font-family="NeuePlakText-SemiBold, Neue Plak Text" font-weight="600"><tspan x="0" y="15">Acceptance / Bank Approvals</tspan></text>
      </g>
      <text id="Conversion-2" data-name="Conversion" transform="translate(1317 146)" fill="#fff" font-size="14" font-family="NeuePlakText-Bold, Neue Plak Text" font-weight="700"><tspan x="0" y="17">Conversion</tspan></text>
    </g>
  </g>
</svg>



`;
