import React from 'react';
import classes from './SliderRange.module.scss';
import cls from 'classnames';
interface IProps {
  style?: any,
  min: number,
  max: number,
  step?: number,
  value: number,
  onChange(newValue: number): void,
  formatDisplay?(value: number): string,
  small?: boolean,
}

const SliderRange = (props: IProps) => {
  const percentage = props.max - props.min !== 0 ? 100 * (props.value - props.min) / (props.max - props.min) : 0;
  return (
    <div className={classes.container} style={props.style}>

      <div className={cls(classes.rawInputContainer, { [classes.small]: props.small })}>
        <input
          max={props.max}
          min={props.min}
          onChange={(e: any) => {
            const { value } = e.target;
            props.onChange(value);
          }}
          step={props.step}
          type="range"
          value={props.value}
          style={{
            width: '100%',
          }}
        />
        <div className={classes.desContainer}>
          <div
            className={classes.desThumb}
            style={{
              // left: percentage <= 5 ? `${percentage}%`
              //   : percentage <= 10 ? `calc( ${percentage}% - 5px )`
              //   : percentage <= 20 ? `calc( ${percentage}% - 10px )`
              //   : percentage <= 30 ? `calc( ${percentage}% - 15px )`
              //   : percentage <= 40 ? `calc( ${percentage}% - 20px )`
              //   : percentage <= 50 ? `calc( ${percentage}% - 25px )`
              //   : percentage <= 60 ? `calc( ${percentage}% - 30px )`
              //   : percentage <= 70 ? `calc( ${percentage}% - 35px )`
              //   : percentage <= 80 ? `calc( ${percentage}% - 40px )`
              //   : percentage <= 90 ? `calc( ${percentage}% - 45px )`
              //   : `calc( ${percentage}% - 50px )`,

              left: `calc( ${percentage}% - ${5 * (percentage/10)}px )`
            }}
          >
            <div className={classes.tick}>
              <div className={classes.display}>
                {props.formatDisplay ? props.formatDisplay(props.value) : props.value}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderRange;
