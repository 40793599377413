import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={29.25}
    height={29.25}
    {...props}
  >
    <path
      data-name="Icon ionic-ios-information-circle"
      d="M14.625 0A14.625 14.625 0 1 0 29.25 14.625 14.623 14.623 0 0 0 14.625 0Zm1.336 21.375h-2.686V11.243h2.686Zm-1.343-11.236a1.4 1.4 0 1 1 1.455-1.406 1.409 1.409 0 0 1-1.455 1.406Z"
      fill="#ffcb00"
    />
  </svg>
)

export default SvgComponent
