import React, { useState, useRef } from 'react';
import { BgImageContainer, Text, SliderRange, PackageSelection, ScaledScreenRatio } from 'components';
import { ASSETS } from 'assets';
import cc from '../@common-classes/Common.module.scss';
import cls from 'classnames';
import classes from './Step3.module.scss';
import AddOnsPopup from './comps/AddOnsPopup';
import LeftSection from './comps/LeftSection';
import { useStore } from 'store';
import InfoPopup from './comps/InfoPopup';

const RightRow = ({ children, style }: any) => {
  const childrenComps = React.Children.toArray(children);
  return (
    <div className={cc.row} style={{ marginBottom: 20, ...style }}>
      <div className={cls(cc.flex, cc.flex1, cc.middle)}>
        {childrenComps[0]}
      </div>
      <div className={cls(cc.flex, cc.flex1, cc.middle)}>
        {childrenComps[1]}
      </div>
      <div className={cls(cc.flex, cc.flex1, cc.middle)}>
        {childrenComps[2]}
      </div>
    </div>
  );
};

const Step3 = () => {
  const [store, setStore, currentPackage, displayValues, api] = useStore();
  const [infoPopupData, setInfoPopupData] = useState<{ show: boolean, type: 'authenAndFraud' | 'bankApproval' }>({
    show: false,
    type: 'authenAndFraud',
  });
  const { packageData, setPackage } = currentPackage;

  const onBack = () => {
    window.location.pathname = '/step-2';
  };

  const formRef = useRef<any>(null);

  const onNext = () => {
    const {
      upliftFraudManagementPercentage,
      upliftBankApprovalsPercentage,
    } = packageData;
    if (!upliftFraudManagementPercentage || !upliftBankApprovalsPercentage) {
      return alert('Please fill in uplift values');
    } else {
      window.location.pathname = '/step-4';
    }
  };

  const onSave = async () => {
    if (!!store.Id && !!store.sessionCode) await api.updateData(store.sessionCode, store);
    else {
      const res = await api.saveDataAndGenerateCode(store);
      if (!!res)
        setStore({
          ...store,
          sessionCode: res.Code,
          Id: res.Id,
        })
      
    }
    alert('Your session has been saved');
  };

  const renderRight = () => {
    return (
      <form style={{ padding: 15 }} ref={formRef} className={classes.rightSection} onSubmit={(e: any) => e.preventDefault()}>
        <RightRow style={{ marginBottom: 80 }}>
          <Text white bold style={{ fontSize: 16 }}>{store.selectedPackage} Revenue <br /> Uplift Percentage</Text>
          <Text white bold style={{ fontSize: 16 }}>{store.selectedPackage} Revenue <br /> Uplift Value</Text>
          <Text white bold style={{ fontSize: 16 }}>Total Margin <br />Saved</Text>
        </RightRow>

        <RightRow style={{ marginBottom: 35, marginTop: 20 }}>
          <div className={cc.row}>
            <ASSETS.SVGIconInfo
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInfoPopupData({
                  show: true,
                  type: 'authenAndFraud'
                })
              }}
            />
            <input
              className={classes.outlineValue}
              value={packageData.upliftAuthenManagementPercentage}
              onChange={(e: any) => {
                setPackage({
                  ...packageData,
                  upliftAuthenManagementPercentage: e.target.value,
                })
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  e.target.value = '0'
                }
              }}
            />
            <div className={classes.percentageSymbol}>%</div>
            <Text white style={{ fontSize: 22 }}>Uplift</Text>
          </div>
          <Text white style={{ fontSize: 22 }} money>{displayValues.step3.rightSection.row1UpliftValue_authen}</Text>
          <Text white style={{ fontSize: 22 }} money>{displayValues.step3.rightSection.row1TotalMarginSaved_authen}</Text>
        </RightRow>

        <RightRow style={{ marginBottom: 25, marginTop: 20 }}>
          <div className={cc.row}>
            <ASSETS.SVGIconInfo
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInfoPopupData({
                  show: true,
                  type: 'authenAndFraud'
                })
              }}
            />
            <input
              className={classes.outlineValue}
              value={packageData.upliftFraudManagementPercentage}
              onChange={(e: any) => {
                setPackage({
                  ...packageData,
                  upliftFraudManagementPercentage: e.target.value,
                })
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  e.target.value = '0'
                }
              }}
            />
            <div className={classes.percentageSymbol}>%</div>
            <Text white style={{ fontSize: 22 }}>Uplift</Text>
          </div>
          <Text white style={{ fontSize: 22 }} money>{displayValues.step3.rightSection.row1UpliftValue_fraud}</Text>
          <Text white style={{ fontSize: 22 }} money>{displayValues.step3.rightSection.row1TotalMarginSaved_fraud}</Text>
        </RightRow>

        <RightRow style={{ marginBottom: 35, marginTop: 20 }}>
          <div className={cc.row}>
          <ASSETS.SVGIconInfo
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setInfoPopupData({
                  show: true,
                  type: 'bankApproval'
                })
              }}
            />
            <input
              className={classes.outlineValue}
              value={packageData.upliftBankApprovalsPercentage}
              onChange={(e: any) => {
                setPackage({
                  ...packageData,
                  upliftBankApprovalsPercentage: e.target.value,
                })
              }}
              onBlur={(e) => {
                if (e.target.value === '') {
                  e.target.value = '0'
                }
              }}
            />
            <div className={classes.percentageSymbol}>%</div>
            <Text white style={{ fontSize: 22 }}>Uplift</Text>
          </div>
          <Text white style={{ fontSize: 22 }} money>{displayValues.step3.rightSection.row2UpliftValue}</Text>
          <Text white style={{ fontSize: 22 }} money>{displayValues.step3.rightSection.row2TotalMarginSaved}</Text>
        </RightRow>

        <div className={classes.liner} />

        <RightRow style={{ height: 110 }}>
          <Text h4 white style={{ fontSize: 24 }}>Total Revenue Saving</Text>
          <Text h1 white></Text>
          <Text h3 white money>{displayValues.step3.rightSection.totalRevenueSaving}</Text>
        </RightRow>

        <div className={classes.liner} />

        <RightRow>
          <div style={{ width: '100%' }}>
            <Text h4 white style={{ fontSize: 24 }}>Total margin Saving</Text>
          </div>
          <Text h1 white></Text>
          <Text h3 white money>{displayValues.step3.rightSection.totalMarginSaving}</Text>
        </RightRow>
      </form>
    );
  };

  return (
    <BgImageContainer src={''} overlayColor='white' middle>
      <ScaledScreenRatio width={1920} height={1080}>
        <div style={{ padding: 50, paddingBottom: 0 }}>
          <a href="/">
            <img src={ASSETS.imgACILogoBlue} className={cc.blueLogo} />
          </a>
          <div className={cls(cc.flex, cc.flex1)} style={{ padding: 10 }}>
            <Text h2 blue style={{ marginBottom: 30 }}>Step 3: Input and Amend Uplift Amounts</Text>
            <div className={cls(cc.flex, cc.flex1, cc.rowStretch)} style={{ margin: '20px 0px', marginTop: 0 }}>
              <div className={cls(cc.flex, cc.flex1)} style={{ marginRight: 30 }}>
                <LeftSection />
              </div>
              <div className={cls(cc.flex, cc.flex1)}>
                {renderRight()}
              </div>
            </div>
            <div className={cc.row} style={{ justifyContent: 'space-between', marginTop: 20 }}>
              <div className={cc.row}>
                <button onClick={onBack} className={cc.mainButton} style={{ width: 222, marginRight: 20 }}>Back</button>
                <button onClick={onSave} className={cc.blueButton} style={{ width: 222, marginRight: 20 }}>Save Your Session</button>
                <Text blue semiBold style={{ fontSize: 12, marginRight: 20 }}>Enter This Code {store.sessionCode || '0000'} to <br />Resume Your Latest Session</Text>
                <button className={cc.blueButtonOutline} style={{ width: 222, marginRight: 20, paddingTop: 5 }}>
                  <Text blue semiBold style={{ fontSize: 40 }}>{store.sessionCode || '0000'}</Text>
                </button>
              </div>

              <div className={cc.row}>
                <button onClick={onNext} className={cc.mainButton} style={{ width: 222 }}>Next</button>
              </div>
            </div>
          </div>
          <PackageSelection />
        </div>
      </ScaledScreenRatio>
      <InfoPopup
        open={infoPopupData.show}
        type={infoPopupData.type}
        onClose={() => setInfoPopupData({
          ...infoPopupData,
          show: false,
        })}
      />
    </BgImageContainer>
  );
};

export default Step3;
