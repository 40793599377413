import React, { useState } from 'react';
import classes from './Step4.module.scss';
import { BgImageContainer, Text, SliderRange, PackageSelection, ScaledScreenRatio } from 'components';
import { ASSETS } from 'assets';
import cc from '../@common-classes/Common.module.scss';
import cls from 'classnames';
import Part2 from './comps/Part2';
import { useStore } from 'store';
import InfoPopup from './comps/InfoPopup';

const Step5 = () => {
  const [store, setStore, currentPackage, displayValues, api] = useStore();
  const [infoPopupData, setInfoPopupData] = useState<{ show: boolean, type: 'acceptingAlternativePayment' | 'mobile' | 'costOfDoingBusiness' | 'chargeBack' }>({
    show: false,
    type: 'acceptingAlternativePayment',
  });

  const onBack = () => {
    window.location.pathname = '/step-4';
  }
  const onSave = async () => {
    if (!!store.Id && !!store.sessionCode) await api.updateData(store.sessionCode, store);
    else {
      const res = await api.saveDataAndGenerateCode(store);
      if (!!res)
        setStore({
          ...store,
          sessionCode: res.Code,
          Id: res.Id,
        })
      alert('Your session has been saved');
    }
  };
  const onSubmit = () => {
    window.location.pathname = '/step-6';
  };

  return (
    <BgImageContainer src={''} overlayColor='white' middle>
      <ScaledScreenRatio width={1920} height={1080}>
        <div className={cls(cc.flex, cc.flex1)} style={{ padding: 50, paddingBottom: 0 }}>
          <a href="/">
            <img src={ASSETS.imgACILogoBlue} className={cc.blueLogo} />
          </a>
          <div className={cls(cc.flex, cc.flex1)} style={{ padding: 50 }}>
            <Text h2 blue style={{ marginBottom: 30 }}>Step 5: Input and Amend Uplift Amounts</Text>
            <div className={cls(cc.flex, cc.flex1, cc.rowStretch)} style={{ marginTop: 20, marginBottom: 100 }}>
              <Part2
                  onClickCostOfDoingBusinessInfo={() => {
                    setInfoPopupData({
                      show: true,
                      type: 'costOfDoingBusiness'
                    })
                  }}
                  onClickChargebackInfo={() => {
                    setInfoPopupData({
                      show: true,
                      type: 'chargeBack'
                    })
                  }}
                />
            </div>
            <div className={cc.row} style={{ justifyContent: 'space-between' }}>
              <div className={cc.row}>
                <button onClick={onBack} className={cc.mainButton} style={{ width: 222, marginRight: 20 }}>Back</button>
                <button onClick={onSave} className={cc.blueButton} style={{ width: 222, marginRight: 20 }}>Save</button>
                <Text blue semiBold style={{ fontSize: 12, marginRight: 20 }}>Enter This Code {store.sessionCode || '0000'} to <br />Resume Your Latest Session</Text>
                <button className={cc.blueButtonOutline} style={{ width: 222, marginRight: 20, paddingTop: 5 }}>
                  <Text blue semiBold style={{ fontSize: 40 }}>{store.sessionCode || '0000'}</Text>
                </button>
              </div>
              <button onClick={onSubmit} className={cc.mainButton} style={{ width: 222 }}>Next</button>
            </div>
          </div>
          <PackageSelection />
        </div>
      </ScaledScreenRatio>
      <InfoPopup
        open={infoPopupData.show}
        type={infoPopupData.type}
        onClose={() => setInfoPopupData({
          ...infoPopupData,
          show: false,
        })}
      />
    </BgImageContainer>
  );
};

export default Step5;
