import accounting from 'accounting-js';
// handle all variable processing, converting,...

const voucher_codes = require('voucher-code-generator');

class VarHelper {
  erria = async (callback: Function) => {
    try {
      const data = await callback();
      return [data, null];
    } catch (err) {
      return [null, err];
    }
  }

  money = (number: number, symbol: string, options = {}) => {
    return accounting.formatMoney(Number(number), { thousand: ',', decimal: '.', symbol: symbol, ...options });
  }

  moneyRound = (number: number, symbol: string, precision : number = 0) => {
    return this.money(number, symbol, {
      precision,
    })
  }

  generateCode = () => {
    return voucher_codes.generate({
      length: 4,
      count: 1,
      charset: "0123456789"
    })[0];
  }
}

export default new VarHelper();
