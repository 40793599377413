import React, { useState, useEffect } from 'react';
import { useListCurrencies } from 'components';
import classes from './CurrencyAndVerticalDropdown.module.scss';
import Select from 'react-select';
import cc from '../../@common-classes/Common.module.scss';
import { useStore } from 'store';
import DropdownIndicator from './DropdownIndicator';

interface IProps {
  selectedCurrency: any,
  setSelectedCurrency: any,
  selectedVertical: any,
  setSelectedVertical: any,
  onNext(): void,
}

const CurrencyAndVerticalDropdown = (props : IProps) => {
  const [store, setStore, sheetData, displayValues, api] = useStore();
  const listCurrencies = useListCurrencies();
  const [spacerHeight,  setSpacerHeight] = useState(0);

  

  const selectStyles = {
    indicatorSeparator: () => ({ display: 'none' }),
    container: (style : any) => ({
      ...style,
      borderRadius: 20,
      position: 'relative',
      width: '100%',
      height: 56,
    }),
    control: (style : any) => ({
      ...style,
      borderRadius: 28,
      border: 0,
      boxShadow: 'none',
      zIndex: 100,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }),
    menu: (style: any) => ({
      ...style,
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderRadius: 28,
      overflow: 'hidden',
      paddingTop: 58,
      // top: -135,
      top: 0,
      zIndex: 0,
      fontFamily: 'var(--font-semi-bold)',
    }),
    placeholder: (style: any) => ({
      ...style,
      textAlign: 'center',
      color: 'var(--color-text)',
      transform: 'translate(0px, -3px)',
      fontSize: 20,
      fontFamily: 'var(--font-semi-bold)',
    }),
    singleValue: (style: any) => ({
      ...style,
      textAlign: 'center',
      color: 'var(--color-text)',
      transform: 'translate(0px, -3px)',
      fontSize: 20,
      fontFamily: 'var(--font-semi-bold)',
    }),
    option: (style: any) => ({
      ...style,
      textAlign: 'center',
      color: 'var(--color-text)',
      // paddingRight: 50,
    }),
  };

  const currencyOptions = listCurrencies.map(v => ({ ...v, label: v.cc, value: v.cc }));
  // [
  //   { label: 'AUD', value: 'AUD' },
  //   { label: 'CNY', value: 'CNY' },
  //   { label: 'EURO', value: 'EURO' },
  //   { label: 'GBP', value: 'GBP' },
  //   { label: 'JPY', value: 'JPY' },
  //   { label: 'USD', value: 'USD' },
  // ];

  const verticalOptions = [
    { label: 'Digital Content', value: 'Digital Content' },
    { label: 'Gaming', value: 'Gaming' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Telco', value: 'Telco' },
    { label: 'Ticketing', value: 'Ticketing' },
    { label: 'Other', value: 'Other' },
  ];

  // useEffect(() => {
  //   if (
  //     (!!props.selectedCurrency && store.currency.cc !== props.selectedCurrency.value)
  //     || (!props.selectedCurrency && store.currency.cc)
  //   ) {
  //     props.setSelectedCurrency({
  //       ...store.currency,
  //       value: store.currency.cc,
  //       label: store.currency.cc,
  //     })
  //   }

  //   if (
  //       (!!store.vertical && !props.selectedVertical)
  //       || (!!store.vertical && !!props.selectedVertical && store.vertical !== props.selectedVertical.value)
  //     ) {
  //     props.setSelectedVertical({
  //       value: store.vertical,
  //       label: store.vertical,
  //     })
  //   }
  // }, [store, currencyOptions, props.selectedCurrency, props.selectedVertical]);

  return (
    <>
      <div className={classes.container} style={{ marginBottom: 30 }}>
        <div className={classes.item}>
          <Select
            styles={selectStyles}
            isSearchable={false}
            placeholder="Currency"
            options={currencyOptions}
            // menuPortalTarget={document.body}
            onMenuOpen={() => setSpacerHeight(245)}
            onMenuClose={() => setSpacerHeight(0)}
            value={props.selectedCurrency}
            onChange={props.setSelectedCurrency}
            components={{ DropdownIndicator }}
          />
        </div>
        <div className={classes.item}>
          <Select
            styles={selectStyles}
            isSearchable={false}
            placeholder="Vertical"
            options={verticalOptions}
            // menuPortalTarget={document.body}
            onMenuOpen={() => setSpacerHeight(245)}
            onMenuClose={() => setSpacerHeight(0)}
            value={props.selectedVertical}
            onChange={props.setSelectedVertical}
            components={{ DropdownIndicator }}
          />
        </div>
        <div className={classes.item}>
          <button className={cc.mainButton} onClick={props.onNext}>Next Step</button>
        </div>
      </div>
      <div style={{ height: 245 }} />
    </>
  );
};

export default CurrencyAndVerticalDropdown;