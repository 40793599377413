import React from 'react';
import { Text } from 'components';
import classes from './PackageSelection.module.scss';
import { useStore } from 'store';

const PackageSelection = () => {
  const [store, setStore] = useStore();
  const { selectedPackage } = store;
  const changePackage = (v : 'Silver' | 'Gold' | 'Platinum') => {
    setStore({
      ...store,
      selectedPackage: v,
    });
  };
  return (
    <div className={classes.container}>
      <Text white semiBold center style={{ fontSize: 18 }}>Package Selection</Text>
      <Text white semiBold center style={{ fontSize: 18 }}>
      • <span onClick={() => changePackage('Silver')} className={selectedPackage === 'Silver' ? classes.active : ''}>Silver</span>
      • <span onClick={() => changePackage('Gold')} className={selectedPackage === 'Gold' ? classes.active : ''}>Gold</span>
      • <span onClick={() => changePackage('Platinum')} className={selectedPackage === 'Platinum' ? classes.active : ''}>Platinum</span>
      </Text>
    </div>
  );
};

export default PackageSelection;