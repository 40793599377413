export const defaultAddOnsTable = [
  {
    name: 'ACI PayAfter White labeling',
    uplift: 0,
  },
  {
    name: 'Minor Customizations - Access to FL Project Management (Max. 16hrs/project)',
    uplift: 0,
  },
  {
    name: 'Chargeback and Reversal',
    uplift: 0,
  },
  {
    name: 'Ekata Pro - Insight (White Pages Pro) - Manual Order Review on Customer-side',
    uplift: 0,
  },
  {
    name: 'Referral: Emailage (3rd Party: Lexisnexis)',
    uplift: 0,
  },
  {
    name: 'US Only: Email Verification (3rd Party: Neustar)',
    uplift: 0,
  },
  {
    name: 'US Only: Address and Phone Validation (3rd Party: Neustar)',
    uplift: 0,
  },
  {
    name: 'Transaction Risk API (3rd Party: Ekata) - Additional 20 Values to Enhance Decision',
    uplift: 0,
  },
  {
    name: 'Perseuss Airline Data Base',
    uplift: 0,
  },
  {
    name: 'Chargeback Defense - Arvato discussion with Issuer for a CB.RV',
    uplift: 0,
  },
  {
    name: 'Manual Order Review - Offshore (3rd Party: Avarto BFF)',
    uplift: 0,
  },
  {
    name: 'Manual Order Review - Nearshore (3rd Party: Avarto BFF)',
    uplift: 0,
  },
  {
    name: 'Manual Order Review - Onshore (3rd Party: Avarto BFF)',
    uplift: 0,
  },
  {
    name: 'AOD Device ID (3rd Party: Threatmetrix, Iovation)',
    uplift: 0,
  },
]