import React, { useState } from 'react';
import { BgImageContainer, Text, SliderRange, PackageSelection } from 'components';
import cc from '../../@common-classes/Common.module.scss';
import cls from 'classnames';
import classes from '../Step3.module.scss';
import { VarHelper } from 'helpers';
import { useStore } from 'store';

const LeftRow = ({ children, style }: any) => {
  const childrenComps = React.Children.toArray(children);
  return (
    <div className={cc.row} style={{ marginBottom: 20, ...style }}>
      <div className={cls(cc.flex, cc.flex1)}>
        {childrenComps[0]}
      </div>
      <div className={cls(cc.flex, cc.flex3, cc.middle)}>
        {childrenComps[1]}
      </div>
      <div className={cls(cc.flex, cc.flex1, cc.middle)}>
        {childrenComps[2]}
      </div>
    </div>
  );
};

const LeftSection = () => {
  const [store, setStore, currentPackage, displayValues] = useStore();
  // const { packageData, setPackage } = currentPackage;
  // const [r1, setR1] = useState(0);
  // const [r2, setR2] = useState(0);

  const renderSlider = ({ text1, min, max, value, onChange, formatDisplay, step, text3 }: any) => {
    return (
      <LeftRow style={{ marginTop: 40 }}>
        <Text style={{ width: 200 }}>{text1}</Text>
        <div style={{ width: '100%' }}>
          <SliderRange
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChange}
            formatDisplay={formatDisplay}
            small
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 0,
            }}
          />
        </div>
        <Text style={{  }} money>{text3}</Text>
      </LeftRow>
    );
  };

  return (
    <div className={classes.leftSection}>

      <LeftRow>
        <Text blue bold style={{ fontSize: 16 }}>Conversion Impact <br />(% of Revenue)</Text>
        <Text blue bold style={{ fontSize: 16 }}>Impact <br />(% Revenue)</Text>
        <Text blue bold style={{ fontSize: 16 }}>Impact <br />(Revenue)</Text>
      </LeftRow>

      <Text blue h4 style={{ fontSize: 24, marginBottom: 20 }}>Current Conversion Impact</Text>
      {renderSlider({
        text1: 'Authentication declines',
        min: 0,
        max: 100,
        step: 0.1,
        value: - (store.revisedImpactAuthenManagementPercentage || 0),
        onChange: (v: number) => {
          setStore({
            ...store,
            revisedImpactAuthenManagementPercentage: -v,
          })
        },
        formatDisplay: (v: number) => `${v} %`,
        text3: displayValues.step3.leftSection.impactRow1,
      })}
      {renderSlider({
        text1: 'Fraud declines',
        min: 0,
        max: 100,
        step: 0.1,
        value: - (store.revisedImpactFraudManagementPercentage || 0),
        onChange: (v: number) => {
          setStore({
            ...store,
            revisedImpactFraudManagementPercentage: -v,
          })
        },
        formatDisplay: (v: number) => `${v} %`,
        text3: displayValues.step3.leftSection.impactRow2,
      })}
      {renderSlider({
        text1: 'Acceptance / Bank Declines',
        min: 0,
        max: 100,
        step: 0.1,
        value: - (store.revisedImpactBankApprovalsPercentage || 0),
        onChange: (v: number) => {
          setStore({
            ...store,
            revisedImpactBankApprovalsPercentage: -v,
          })
        },
        formatDisplay: (v: number) => `${v} %`,
        text3: displayValues.step3.leftSection.impactRow3,
      })}
      {/* <Text style={{ marginBottom: 30 }}>Select Any Add-ons</Text> */}

      <div className={classes.liner} />

      <LeftRow style={{ marginBottom: 0 }}>
        <Text h4 style={{ fontSize: 24 }}>Total Revenue Lost</Text>
        <Text h1 blue money>{displayValues.step3.leftSection.totalRevenueLostPercentage}</Text>
        <Text h3 blue money>{displayValues.step3.leftSection.totalRevenueLost}</Text>
      </LeftRow>

      <div className={classes.liner} />

      <LeftRow style={{ marginBottom: 0 }}>
        <Text h4 style={{ fontSize: 24 }}>Margin Lost</Text>
        <Text h1 blue></Text>
        <Text h3 blue money>{displayValues.step3.leftSection.marginLost}</Text>
      </LeftRow>
    </div>
  );
};

export default LeftSection;