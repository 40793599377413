import React, { useState } from 'react';
import classes from './Step4.module.scss';
import { BgImageContainer, Text, SliderRange, PackageSelection, ScaledScreenRatio } from 'components';
import { ASSETS } from 'assets';
import cc from '../@common-classes/Common.module.scss';
import cls from 'classnames';
import { useStore } from 'store';
import ResultTableSVG from './comps/ResultTableSVG';
import { jsPDF } from 'jspdf'
import 'svg2pdf.js'
import './Step7.scss';
import ResultsTable from './comps/ResultsTable';

const Step5 = () => {
  const [store, setStore, sheetData, displayValues, api] = useStore();
  const [isDownloadMode, setIsDownloadMode] = useState(false);
  const onBack = () => {
    window.location.pathname = '/step-6';
  }
  const onSave = async () => {
    if (!!store.Id && !!store.sessionCode) await api.updateData(store.sessionCode, store);
    else {
      const res = await api.saveDataAndGenerateCode(store);
      if (!!res)
        setStore({
          ...store,
          sessionCode: res.Code,
          Id: res.Id,
        })
      
    }
    alert('Your session has been saved');
  };
  const onDownload = () => {
    // const doc = new jsPDF();
    // const element = document.getElementById("result-table");
    // if (!element) return;
    // doc
    //   .svg(element, {
    //     x:0,
    //     y:0,
    //     width: 1590,
    //     height: 668,
    //     loadExternalStyleSheets: true,
    //   })
    //   .then(() => {
    //     // save the created pdf
    //     doc.save('myPDF.pdf')
    //   })
    setIsDownloadMode(true);
    setTimeout(() => {
      window.print();
      setIsDownloadMode(false);
    }, 500);
  };

  return (
    <BgImageContainer src={''} overlayColor='white' middle>
      <ScaledScreenRatio width={1920} height={1080}>
        <div className={cls(cc.flex, cc.flex1, cc.printPage)} style={{ paddingLeft: 40, paddingTop: 60, paddingRight: 35 }}>
          <a href="/">
            <img src={ASSETS.imgACILogoBlue} className={cc.blueLogo} />
          </a>
          <div className={cls(cc.flex, cc.flex1)} style={{}}>
            <Text h2 blue style={{ marginBottom: 30, marginTop: 70, }}>Step 7: Review Uplift Results</Text>
            <div className={cls(cc.flex, cc.flex1)} >
              {/* <ResultsTable /> */}
              <ResultTableSVG isDownloadMode={isDownloadMode} />
              <div className={cls(cc.row, cc.hiddenPrint)} style={{ justifyContent: 'space-between', marginTop: 30 }}>
                <div className={cc.row}>
                  <button onClick={onBack} className={cc.mainButton} style={{ width: 222, marginRight: 60 }}>Back</button>
                  {/* <button onClick={onSave} className={cc.blueButton} style={{ width: 222 }}>Save</button> */}
                </div>
                <div className={cc.row}>
                  {/* <button onClick={onViewAddOns} className={cc.mainButton} style={{ width: 222, marginRight: 20 }}>View Add-ons</button> */}
                  <button onClick={onDownload} className={cc.mainButton} style={{ width: 222 }}>Download PDF</button>
                </div>
              </div>
            </div>
          </div>
          <PackageSelection />
        </div>
      </ScaledScreenRatio>
    </BgImageContainer>
  );
}

export default Step5;