import * as React from "react"
import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={1652} height={906} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 813"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h67.482v44.016H0z"
        />
      </clipPath>
      <clipPath id="b">
        <path
          data-name="Rectangle 814"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h48.674v54.633H0z"
        />
      </clipPath>
      <clipPath id="c">
        <path
          data-name="Rectangle 815"
          fill="none"
          stroke="#707070"
          strokeWidth={2}
          d="M0 0h65v42.397H0z"
        />
      </clipPath>
    </defs>
    <path
      data-name="Path 59"
      d="M28 0h1596a28 28 0 0 1 28 28v850a28 28 0 0 1-28 28H28a28 28 0 0 1-28-28V28A28 28 0 0 1 28 0Z"
      fill="#26D7DA"
    />
    <text
      data-name="Platinum"
      transform="translate(66 26.356)"
      fill="#2726dd"
      fontSize={40}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={49}>
        {"Platinum"}
      </tspan>
    </text>
    <text
      data-name="Cost Reduction"
      transform="translate(1094 181)"
      fill="#2726dd"
      fontSize={24}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={29}>
        {"Cost Reduction"}
      </tspan>
    </text>
    <text
      data-name="\u2022 3D Secure 2.1 &amp; Exemption Processing (Delegated Authentication Application) \u2022 Fraud Platinum (Refer to Fraud Section for Details) - Vertical Based Realtime Self Learning Model - Unlimited Sub-clients - Orchestration of Fraud Mana"
      transform="translate(66 259)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   3D Secure 2.1 & Exemption Processing"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"      (Delegated Authentication Application)"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"\u2022   Fraud Platinum (Refer to Fraud Section for Details)"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"     - Vertical Based Realtime Self Learning Model"}
      </tspan>
      <tspan x={0} y={116} xmlSpace="preserve">
        {"     - Unlimited Sub-clients"}
      </tspan>
      <tspan x={0} y={140} xmlSpace="preserve">
        {"     - Orchestration of Fraud Management & Payment Flows"}
      </tspan>
      <tspan x={0} y={164} xmlSpace="preserve">
        {"       (Includes Pre & Post Auth Flow, Also Possible with "}
      </tspan>
      <tspan x={0} y={188} xmlSpace="preserve">
        {"        3rd Party Services)"}
      </tspan>
      <tspan x={0} y={212} xmlSpace="preserve">
        {"     - Device ID Checks "}
      </tspan>
      <tspan x={0} y={236} xmlSpace="preserve">
        {"     - Customized Fraud Strategy Manager"}
      </tspan>
      <tspan x={0} y={260} xmlSpace="preserve">
        {"     - Customized List Manager"}
      </tspan>
      <tspan x={0} y={284} xmlSpace="preserve">
        {"     - Tieback to Custom Merchant URL"}
      </tspan>
      <tspan x={0} y={308} xmlSpace="preserve">
        {"     - Custom Business Intelligence Report & Dashboards"}
      </tspan>
      <tspan x={0} y={332} xmlSpace="preserve">
        {"     - Dedicated Risk Analyst "}
      </tspan>
      <tspan x={0} y={356} xmlSpace="preserve">
        {"        (Access to Support and Analytics as Required)"}
      </tspan>
    </text>
    <text
      data-name="Authentication / Fraud Management"
      transform="translate(66 225)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Authentication / Fraud Management"}
      </tspan>
    </text>
    <text
      data-name="Everything in Silver and Gold, Plus:"
      transform="translate(66 81)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Everything in Silver and Gold, Plus:"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Vertical Based Realtime Self Learning Model \u2022 Unlimited Sub-clients \u2022 Orchestration to 3rd Parties \u2022 Device ID Checks at No Additional Cost \u2022 Multiple Calls to Fraud Platform \u2022 Customized Fraud Strategy Manager \u2022 Customized List Manager \u2022"
      transform="translate(1102 257)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   Vertical Based Realtime Self Learning Model"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"\u2022   Unlimited Sub-clients"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"\u2022   Orchestration to 3rd Parties"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Device ID Checks at No Additional Cost"}
      </tspan>
      <tspan x={0} y={116} xmlSpace="preserve">
        {"\u2022   Multiple Calls to Fraud Platform"}
      </tspan>
      <tspan x={0} y={140} xmlSpace="preserve">
        {"\u2022   Customized Fraud Strategy Manager"}
      </tspan>
      <tspan x={0} y={164} xmlSpace="preserve">
        {"\u2022   Customized List Manager"}
      </tspan>
      <tspan x={0} y={188} xmlSpace="preserve">
        {"\u2022   Tieback to Custom Merchant URL"}
      </tspan>
      <tspan x={0} y={212} xmlSpace="preserve">
        {"\u2022   Auto-analyst in Case Manager"}
      </tspan>
      <tspan x={0} y={236} xmlSpace="preserve">
        {"\u2022   Custom Business Intelligence Reports & Dashboards"}
      </tspan>
      <tspan x={0} y={260} xmlSpace="preserve">
        {
          "\u2022   Dedicated Risk Analyst (Access to Support & Analytics as Required)"
        }
      </tspan>
    </text>
    <text
      transform="translate(1102 225)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Chargebacks"}
      </tspan>
    </text>
    <text
      data-name="\u2022 White Label Offering \u2022 Bespoke Customizations Included \u2013 160 hours/year (Ex. Functionality,\xA0Services, Transaction Flows)\xA0\u2022 Monitoring Advanced (Grafana dashboards)1"
      transform="translate(1102 579)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   White Label Offering"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"\u2022   Bespoke Customizations Included \u2013 160 hours/year "}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"      (Ex. Functionality,\xA0Services, Transaction Flows)\xA0"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Monitoring Advanced (Grafana dashboards)1"}
      </tspan>
    </text>
    <text
      data-name="Cost of Doing Business"
      transform="translate(1102 545)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Cost of Doing Business"}
      </tspan>
    </text>
    <text
      transform="translate(573 181)"
      fill="#2726dd"
      fontSize={24}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={29}>
        {"Growth"}
      </tspan>
    </text>
    <text
      transform="translate(66 181)"
      fill="#2726dd"
      fontSize={24}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={0} y={29}>
        {"Conversion"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Unlimited Wallets in Platform Network \u2022 Integration of New Local Wallets \u2013 5 (When Solution is Available)"
      transform="translate(573 259)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   Unlimited Wallets in Platform Network"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {
          "\u2022   Integration of New Local Wallets \u2013 5 (When Solution is Available)"
        }
      </tspan>
    </text>
    <text
      data-name="Accepting Alternative Payments"
      transform="translate(573 225)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Accepting Alternative Payments"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Support for Scheme/ Network Tokens1"
      transform="translate(573 368)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022  Support for Scheme/ Network Tokens1"}
      </tspan>
    </text>
    <text
      data-name="Mobile / Tokenization / One click"
      transform="translate(573 332)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Mobile / Tokenization / One click"}
      </tspan>
    </text>
    <text
      data-name="\u2022 Unlimited Acquirer Connections in Platform Network \u2022 Connect-IN (Connect Your Own End-Points) \u2022 Real Time Account Updater \u2022 Intelligent Payment Dynamic Routing (Smart Retry / AB Testing)"
      transform="translate(66 678)"
      fill="#2726dd"
      fontSize={16}
      fontFamily="NeuePlakText-Regular, Neue Plak Text"
    >
      <tspan x={0} y={20} xmlSpace="preserve">
        {"\u2022   Unlimited Acquirer Connections in Platform Network"}
      </tspan>
      <tspan x={0} y={44} xmlSpace="preserve">
        {"\u2022   Connect-IN (Connect Your Own End-Points)"}
      </tspan>
      <tspan x={0} y={68} xmlSpace="preserve">
        {"\u2022   Real Time Account Updater"}
      </tspan>
      <tspan x={0} y={92} xmlSpace="preserve">
        {"\u2022   Intelligent Payment Dynamic Routing\xA0"}
      </tspan>
      <tspan x={0} y={116} xmlSpace="preserve">
        {"     (Smart Retry / AB Testing)"}
      </tspan>
    </text>
    <text
      data-name="Acceptance / Bank Approvals"
      transform="translate(66 643)"
      fill="#2726dd"
      fontSize={18}
      fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
      fontWeight={600}
    >
      <tspan x={0} y={22}>
        {"Acceptance / Bank Approvals"}
      </tspan>
    </text>
    <path
      data-name="Line 138"
      fill="none"
      stroke="#2726dd"
      d="M550.5 114.356V870"
    />
    <path
      data-name="Line 139"
      fill="none"
      stroke="#2726dd"
      d="M1071.5 114.356V870"
    />
    <g data-name="Group 3182">
      <path
        data-name="Path 42"
        d="M632.317 165.795V135.19h-6.653v30.605"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 43"
        d="M590.014 165.796v-7.984h-6.654v7.984"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 44"
        d="M604.115 165.796v-13.307h-6.654v13.307"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 45"
        d="M618.216 165.796v-21.291h-6.653v21.291"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 46"
        d="M590.865 135.523h6.77v6.772"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Line 143"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="m583.36 149.783 14.26-14.26"
      />
      <g data-name="Group 3181">
        <g
          data-name="Group 3180"
          clipPath="url(#a)"
          transform="translate(573 128.984)"
        >
          <path
            data-name="Path 47"
            d="M67.482 43.018H.998V0"
            fill="none"
            stroke="#2726dc"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </g>
    <g data-name="Group 3184">
      <g
        data-name="Group 3183"
        transform="translate(66 122)"
        clipPath="url(#b)"
        fill="none"
        strokeMiterlimit={10}
        strokeWidth={2}
      >
        <path
          data-name="Path 48"
          d="M24.397 3.641A23.735 23.735 0 0 0 .662 27.376h6.3"
          stroke="#2726dd"
        />
        <path
          data-name="Path 49"
          d="M24.277 50.992a23.735 23.735 0 0 0 23.735-23.731h-6.175"
          stroke="#2726dd"
        />
        <path
          data-name="Path 50"
          d="m21.223.468 3.174 3.174-3.174 3.172"
          stroke="#2726dd"
        />
        <path
          data-name="Path 51"
          d="m27.45 54.165-3.174-3.174 3.174-3.173"
          stroke="#2726dd"
        />
        <ellipse
          data-name="Ellipse 8"
          cx={11.247}
          cy={4.174}
          rx={11.247}
          ry={4.174}
          transform="translate(13.09 20.108)"
          stroke="#2726dc"
        />
        <path
          data-name="Path 52"
          d="M35.584 29.548a2.1 2.1 0 0 1-.732 1.485c-1.613 1.572-5.712 2.689-10.515 2.689s-8.9-1.116-10.513-2.688a2.1 2.1 0 0 1-.734-1.486"
          stroke="#2726dc"
        />
      </g>
    </g>
    <g data-name="Group 3187">
      <path
        data-name="Path 53"
        d="M1103.979 166.061v-29.48h6.409v29.48"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 54"
        d="M1144.727 166.06v-7.69h6.409v7.69"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 55"
        d="M1131.145 166.06v-12.817h6.409v12.817"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 56"
        d="M1117.561 166.061v-20.508h6.409v20.508"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Path 57"
        d="M1144.615 150.637h6.521v-6.521"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        data-name="Line 144"
        fill="none"
        stroke="#2726dd"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="m1137.067 136.581 14.056 14.056"
      />
      <g data-name="Group 3186">
        <g
          data-name="Group 3185"
          clipPath="url(#c)"
          transform="translate(1094 130.603)"
        >
          <path
            data-name="Path 58"
            d="M65 41.436H.961V0"
            fill="none"
            stroke="#2726dc"
            strokeMiterlimit={10}
            strokeWidth={2}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
