// https://docs.google.com/spreadsheets/u/1/d/1Pb2ENvBQ0q7uPP_xIz9xPUiC2IaB1VhWUnsOnQGTC4I/edit#gid=187938334

import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { VarHelper } from 'helpers';
import api from './api';
import { defaultStoreValue as defaultInitialValue } from "./defaultStoreValue";
import { defaultAddOnsTable } from './defaultAddOnsTable';
import { TStoreValue, TPackageUpliftValue, TPackage } from './defaultStoreValue';

export const initial = (() => {
  const saved = window.localStorage.getItem("aci_tiered_store");
  if (!saved) return {
    ...defaultInitialValue,
  };
  try {
    let parsed = JSON.parse(saved);
    // migration
    if (!parsed.addOnsTable) {
      parsed.addOnsTable = Object.assign({}, defaultAddOnsTable);
    }
    if (!parsed.packages) {
      parsed = Object.assign({}, defaultInitialValue);
    }
    return parsed;
  } catch (err) {
    return {
      ...defaultInitialValue,
    };
  }
})();

const StoreContext = createContext<
[TStoreValue, React.Dispatch<React.SetStateAction<TStoreValue>>]
>([initial, () => {}]);

export const StoreProvider = (props : any) => {
  const [store, setStore] = useState<TStoreValue>(initial);
  useEffect(() => {
    window.localStorage.setItem( "aci_tiered_store", JSON.stringify(store));
    // @ts-ignore
    window.activeCurrency = store.currency
  }, [store]);
  return (
    <StoreContext.Provider value={[store, setStore]}>
      {props.children}
    </StoreContext.Provider>
  );
};

export const withStoreProvider = (Component : any) => {
  return () => {
    return (
      <StoreProvider>
        <Component />
      </StoreProvider>
    );
  }
};

type TDisplayValues = {
  step1: {
    totalRevenue: string,
    marginBeforeFixedCosts: string,
  },
  step3: {
    leftSection: {
      impactRow1: string,
      impactRow2: string,
      impactRow3: string,
      totalRevenueLostPercentage: string,
      totalRevenueLost: string,
      totalRevenueLostForStep7: string,
      marginLost: string,
    },
    rightSection: {
      // it was originally 1 row, but split into 2: authenAndFraud + bankApproval
      row1UpliftValue: string,
      row1UpliftValue_authen: string,
      row1UpliftValue_fraud: string,
      row1TotalMarginSaved: string,
      row1TotalMarginSaved_authen: string,
      row1TotalMarginSaved_fraud: string,

      row2UpliftValue: string,
      row2TotalMarginSaved: string,

      totalRevenueSaving: string,
      totalMarginSaving: string,
    }
  },
  step4: {
    part1: {
      middleSection: {
        row1: string,
        row2: string,
        row3: string,
      },
      rightSection: {
        totalrevenueGrowth: string,
        marginGrowth: string,
      }
    },
    part2: {
      leftSection: {
        row1: string,
        row2: string,
        totalPercentage: string,
        totalMoney: string,
      }
      middleSection: {
        row1CostSaving: string,
        row2CostSaving: string,
        row1MarginSaved: string,
        row2MarginSaved: string,
        row3CostSaving: string,
        row3MarginSaved: string,
      },
      rightSection: {
        totalrevenueSaving: string,
        marginSaving: string,
      }
    }
  },
  step5: {
    addOnsPercentage: string,
    totalPercentage: string,
    impactConversion1: string,
    impactConversion2: string,
    impactAddOns: string,
    impactTotalProfit: string,
    upliftValues: {
      Silver: {
        authenManagement: string,
        fraudManagement: string,
        bankApproval: string,
        totalConversion: string,
        alternativePayments: string,
        mobile: string,
        totalGrowth: string,
        chargebacks: string,
        doingBusiness:string,
        totalCost: string,
        addOns: string,
        totalProfitImpact: string,
      },
      Gold: {
        authenManagement: string,
        fraudManagement: string,
        bankApproval: string,
        totalConversion: string,
        alternativePayments: string,
        mobile: string,
        totalGrowth: string,
        chargebacks: string,
        doingBusiness:string,
        totalCost: string,
        addOns: string,
        totalProfitImpact: string,
      },
      Platinum: {
        authenManagement: string,
        fraudManagement: string,
        bankApproval: string,
        totalConversion: string,
        alternativePayments: string,
        mobile: string,
        totalGrowth: string,
        chargebacks: string,
        doingBusiness:string,
        totalCost: string,
        addOns: string,
        totalProfitImpact: string,
      },
    }
  }
}

type TStoreHook = [
  TStoreValue,
  React.Dispatch<React.SetStateAction<TStoreValue>>,
  {
    packageData: TPackageUpliftValue,
    setPackage(newValues : TPackageUpliftValue): void,
  },
  TDisplayValues,
  typeof api,
];

const v = (value : any) => {
  if (!value) return 0;
  return value;
};
const money = (value : number, currency: { rate: number, symbol: string }, precision = 0) : string => {
  if (!value) return `${currency.symbol}0`;
  return VarHelper.moneyRound(value * currency.rate, currency.symbol, precision);
};

export const useStore = () : TStoreHook => {
  const [store, setStore] = useContext(StoreContext);
  const { currency } = store;

  const D3 = v(store.annualTransactionValue);
  const D4 = v(store.averageTransactionValue);

  // Important values
  const D5 = v(store.salesMarginBeforeFixedCosts) / 100;
  const D7 = v(D3 * D4); // Total Revenue
  const D8 = v(D7 * D5); // Margin before fixed costs

  const C12_Authentication = v(store.revisedImpactAuthenManagementPercentage) / 100;
  const C12_FraudManagement = v(store.revisedImpactFraudManagementPercentage) / 100;
  const C12 = C12_Authentication + C12_FraudManagement;

  const D12_Authentication = v(C12_Authentication * D7);
  const D12_FraudManagement = v(C12_FraudManagement * D7);
  const D12 = v(C12 * D7);

  const E12 = v(D12 * D5);
  const C13 = v(store.revisedImpactBankApprovalsPercentage) / 100;
  const D13 = v(C13 * D7);
  const E13 = v(D13 * D5);
  const C14 = v(C12 + C13);
  const D14 = v(D12 + D13);
  const E14 = v(E12 + E13);

  const E15 = 0;

  const C18 = v(store.costChargeBacks) / 100;
  const C19 = v(store.costDoingBusiness) / 100;
  const C20 = C18 + C19;

  const D18 = 0;
  const D19 = 0;
  const D20 = 0;

  const E18 = v(D7 * C18);
  const E19 = v(D7 * C19);
  const E20 = v(E18 + E19);

  const selectedPackage = store.selectedPackage || 'Silver';
  const packageData = (p : TPackage) : TPackageUpliftValue => store.packages[p];

  const C27_Authen = (p : TPackage) => v(packageData(p).upliftAuthenManagementPercentage) / 100;
  const C27_Fraud = (p : TPackage) => v(packageData(p).upliftFraudManagementPercentage) / 100;
  const C27 = (p : TPackage) => C27_Authen(p) + C27_Fraud(p);

  const D27_Authen = (p : TPackage) => v(C12 - C12 * C27_Authen(p));
  const E27_Authen = (p : TPackage) => v(D27_Authen(p)*D7);
  const F27_Authen = (p : TPackage) => v(E27_Authen(p) - D12);
  const J27_Authen = (p : TPackage) => v(E27_Authen(p) * D5);
  const K27_Authen = (p : TPackage) => v(J27_Authen(p) - E12);

  const D27_Fraud = (p : TPackage) => v(C12 - C12 * C27_Fraud(p));
  const E27_Fraud = (p : TPackage) => v(D27_Fraud(p)*D7);
  const F27_Fraud = (p : TPackage) => v(E27_Fraud(p) - D12);
  const J27_Fraud = (p : TPackage) => v(E27_Fraud(p) * D5);
  const K27_Fraud = (p : TPackage) => v(J27_Fraud(p) - E12);

  const D27 = (p : TPackage) => v(C12 - C12 * C27(p));
  const E27 = (p : TPackage) => v(D27(p)*D7);
  const F27 = (p : TPackage) => v(E27(p) - D12);
  const J27 = (p : TPackage) => v(E27(p) * D5);
  const K27 = (p : TPackage) => v(J27(p) - E12);

  const C28 = (p : TPackage) => v(packageData(p).upliftBankApprovalsPercentage) / 100;
  const D28 = (p : TPackage) => v(C13 - C13 * C28(p));
  const E28 = (p : TPackage) => v(D28(p)*D7);
  const F28 = (p : TPackage) => v(E28(p) - D13);
  const J28 = (p : TPackage) => v(E28(p) * D5);
  const K28 = (p : TPackage) => v(J28(p) - E13);

  const F29 = (p : TPackage) => v(F27(p) + F28(p));
  const K29 = (p : TPackage) => v(K27(p) + K28(p));

  const C75 = (() => {
    const { addOnsTable } = store;
    if (!addOnsTable || addOnsTable.length === 0) return 0;
    let value = 0;
    addOnsTable.forEach((row : { uplift: number }) => {
      value += (+row.uplift || 0);
    })
    return value / 100;
  })();
  const D75 = C75 * D7;
  const E75 = C75 * D5;


  // Package - Growth
  const D30 = (p : TPackage) => v(packageData(p).newRevenueAndGrowthAcceptingAlternativePayments) / 100;
  const D31 = (p : TPackage) => v(packageData(p).newRevenueAndGrowthMobileTokenizationOneclick) / 100;
  const E30 = (p : TPackage) => v(D30(p) * D7);
  const J30 = (p : TPackage) => E30(p) * D5;
  const K30 = (p : TPackage) => J30(p) - E15
  const F30 = (p : TPackage) => E30(p);

  const E31 = (p : TPackage) => v(D31(p) * D7);
  const F31 = (p : TPackage) => E31(p);
  const J31 = (p : TPackage) => E31(p) * D5;
  const K31 = (p : TPackage) => J31(p) - E15

  const F32 = (p : TPackage) => F30(p) + F31(p);
  
  const G30 = (p : TPackage) => F30(p) + E75;
  const G31 = (p : TPackage) => F31(p) + E75;
  const G32 = (p : TPackage) => G30(p) + G31(p);

  const L30 = (p : TPackage) => K30(p) + E75;
  const L31 = (p : TPackage) => K31(p) + E75;
  const L32 = (p : TPackage) => L30(p) + L31(p);

  const K32 = (p : TPackage) => K30(p) + K31(p);

  // Package - Cost
  const C33 = (p : TPackage) => v(packageData(p).upliftCostChargeBacks) / 100;
  const C34 = (p : TPackage) => v(packageData(p).upliftCostDoingBusiness) / 100;

  const D33 = (p : TPackage) => C18 - C18 * C33(p);
  const E33 = (p : TPackage) => D33(p) * D7;
  const F33 = (p : TPackage) => E33(p) - D18;
  const J33 = (p : TPackage) => E33(p) * D5;
  const K33 = (p : TPackage) => J33(p) - E18;
  const G33 = (p : TPackage) => F33(p) + E75;
  const L33 = (p : TPackage) => K33(p) + E75;

  const D34 = (p : TPackage) => C19 - C19 * C34(p);
  const E34 = (p : TPackage) => D34(p) * D7;
  const F34 = (p : TPackage) => E34(p) - D19;
  const J34 = (p : TPackage) => E34(p) * D5;
  const K34 = (p : TPackage) => J34(p) - E19;
  const G34 = (p : TPackage) => F34(p) + E75;
  const L34 = (p : TPackage) => K34(p) + E75;

  const F35 = (p : TPackage) => F33(p) + F34(p);
  const K35 = (p : TPackage) => K33(p) + K34(p);
  const G35 = (p : TPackage) => G33(p) + G34(p);
  const L35 = (p : TPackage) => L33(p) + L34(p);

  const upliftValues = (p : TPackage) => {
    return {
      authenManagement: money(K27_Authen(p), currency),
      fraudManagement: money(K27_Fraud(p), currency),
      bankApproval: money(K28(p), currency),
      totalConversion: money(K29(p), currency),
      alternativePayments: money(K30(p), currency),
      mobile: money(K31(p), currency),
      totalGrowth: money(K32(p), currency),
      chargebacks: money(K33(p), currency),
      doingBusiness: money(K34(p), currency),
      totalCost: money(K35(p), currency),
      addOns: money(D75, currency),
      totalProfitImpact: money(K29(p) + K32(p) + K35(p) + D75, currency),
    }
  }

  const displayValue : TDisplayValues = {
    step1: {
      totalRevenue: money(D7, currency),
      marginBeforeFixedCosts: money(D8, currency),
    },
    step3: {
      leftSection: {
        impactRow1: !D12_Authentication ? '' : money(-D12_Authentication, currency) + (D12_Authentication < 0 ? " lost" : ' gain'),
        impactRow2: !D12_FraudManagement ? '' : money(-D12_FraudManagement, currency) + (D12_FraudManagement < 0 ? " lost" : ' gain'),
        impactRow3: !D13 ? '' : money(-D13, currency) + (D13 < 0 ? " lost" : ' gain'),
        totalRevenueLostPercentage: `${(-C14 * 100).toFixed(2)} %`,
        totalRevenueLostForStep7: money(-D14, currency, 0) + (D14 < 0 ? " lost" : ' gain'),
        totalRevenueLost: money(-D14, currency, 2),
        marginLost: money(-E14, currency),
      },
      rightSection: {
        row1UpliftValue: money(F27(selectedPackage), currency),

        row1UpliftValue_authen: money(F27_Authen(selectedPackage), currency),
        row1UpliftValue_fraud: money(F27_Fraud(selectedPackage), currency),

        row1TotalMarginSaved: money(K27(selectedPackage), currency),

        row1TotalMarginSaved_authen: money(K27_Authen(selectedPackage), currency),
        row1TotalMarginSaved_fraud: money(K27_Fraud(selectedPackage), currency),

        row2UpliftValue: money(F28(selectedPackage), currency),
        row2TotalMarginSaved: money(K28(selectedPackage), currency),

        // row3UpliftValue: money(F28(selectedPackage), currency),
        // row3TotalMarginSaved: money(K28(selectedPackage), currency),

        totalRevenueSaving: money(F29(selectedPackage), currency, 2),
        totalMarginSaving: money(K29(selectedPackage), currency, 2),
      },
    },
    step4: {
      part1: {
        middleSection: {
          row1: money(F30(selectedPackage), currency),
          row2: money(F31(selectedPackage), currency),
          row3: money(F32(selectedPackage) * C75, currency),
        },
        rightSection: {
          totalrevenueGrowth: money(F32(selectedPackage), currency),
          marginGrowth: money(K32(selectedPackage), currency),
        }
      },
      part2: {
        leftSection: {
          row1: money(-D18, currency),
          row2: money(-D19, currency),
          totalPercentage: `${(-C20 * 100).toFixed(2)} %`,
          totalMoney: money(-D20, currency),
        },
        middleSection: {
          row1CostSaving: money(F33(selectedPackage), currency),
          row2CostSaving: money(F34(selectedPackage), currency),
          row1MarginSaved: money(K33(selectedPackage), currency),
          row2MarginSaved: money(K34(selectedPackage), currency),
          row3CostSaving: money(F33(selectedPackage) + F34(selectedPackage) + D75 + D75, currency),
          row3MarginSaved: money(K33(selectedPackage) + K34(selectedPackage) + D75 + D75, currency),
        },
        rightSection: {
          totalrevenueSaving: money(F35(selectedPackage), currency),
          marginSaving: money(K35(selectedPackage), currency),
        }
      }
    },
    step5: {
      addOnsPercentage: `${Math.round(C75 * 100)} %`,
      totalPercentage: `${Math.round((C12 + C13 + C18 + C19 + C27(selectedPackage) + C28(selectedPackage) + C75) * 100)} %`,
      impactConversion1: money(-D12, currency),
      impactConversion2: money(-D13, currency),
      impactAddOns: money(D75, currency),
      impactTotalProfit: money(D75 + D14 + D20, currency),
      upliftValues: {
        Silver: upliftValues('Silver'),
        Gold: upliftValues('Gold'),
        Platinum: upliftValues('Platinum'),
      }
    }
  };

  const setPackage = useCallback((newValues: TPackageUpliftValue) => {
    setStore({
      ...store,
      packages: {
        ...store.packages,
        [store.selectedPackage || 'Silver']: {
          ...packageData,
          ...newValues,
        }
      }
    })
  }, [store.selectedPackage, packageData]);

  return [store, setStore, { packageData: packageData(selectedPackage), setPackage }, displayValue, api];
};