import React, { useEffect, useState } from 'react';
import { ASSETS } from 'assets';
import { BgImageContainer, Text, ScaledScreenRatio } from 'components';
import cls from 'classnames';
import cc from '../@common-classes/Common.module.scss';
import { useStore } from 'store';

import CurrencyAndVerticalDropdown from './comps/CurrencyAndVerticalDropdown';

const Login = ({ onInputCode } : any) => {
  const [store, setStore, sheetData, displayValues, api] = useStore();
  const [selectedCurrency, setSelectedCurrency] = useState(store.currency ? {
    ...store.currency,
    label: store.currency.cc,
    value: store.currency.cc,
  } : undefined);
  const [selectedVertical, setSelectedVertical] = useState<undefined | { value: string, label: string }>(
    store.vertical ? { value: store.vertical, label: store.vertical } : undefined
  );

//   const onInputCode = async () => {
//     const code = window.prompt('Please enter the password');
//     if (!code) return alert('Wrong password');
    
//   }

  const MiddleContainer = ({ children }: any) => (
    <div className={cls(cc.middle, cc.flex1, cc.p20)}>
      <div className={cc.middle} style={{ width: '50%' }}>
        {children}
      </div>
    </div>
  );
  return (
    <BgImageContainer src={ASSETS.imgHomeBG} overlayColor={'rgba(19,39,68,0.35)'} middle>
      <ScaledScreenRatio width={1920} height={1080}>
        <MiddleContainer>

          <img src={ASSETS.imgACILogo} style={{ width: 278, height: 'autos', marginBottom: 30, }} />

          <Text h1 white center style={{ marginBottom: 30 }}>
            Payments Optimization Packages
          </Text>

          {/* <Text bold h4 white center style={{ marginBottom: 30 }}>
            Select Your Preferred Currency and Vertical
          </Text>

          <CurrencyAndVerticalDropdown
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            selectedVertical={selectedVertical}
            setSelectedVertical={setSelectedVertical}
            onNext={handleNextStep}
          /> */}

          <Text bold h4 white center style={{ marginBottom: 30 }}>
            Please enter Password to continue
          </Text>

          <button className={cc.whiteButton} style={{ width: 197 }} onClick={onInputCode}>
            Enter Password
          </button>

        </MiddleContainer>
      </ScaledScreenRatio>
    </BgImageContainer>
  );
};

export default Login;