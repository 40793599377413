import classes from './SVGUI.module.scss';

interface IChildProps {
  children: any;
  bottom?: string;
  left?: string;
  right?: string;
  top?: string;
  width: string;
  height: string;
}

export const Child = ({ children, bottom, left, right, top, width, height }: IChildProps) => {
  return (
    <div className={classes.svgChild} style={{ bottom, left, right, top, width, height }}>
      {children}
    </div>
  );
};
