import { VarHelper } from 'helpers';
import Request from './Request.utils';

class API {

  token = '';

  singIn = async ({ email, password } : any) => {
    const res = await Request.post('https://cms.codesigned.co.uk/api/v1/db/auth/user/signin', { email, password });
    if (res?.data.token) {
      Request.setToken(res.data.token);
      this.token = res.data.token;
    }
  }
  
  makeSureAuthen = async () => {
    if (!this.token) {
      await this.singIn({ email: 'anonymous@devserver.london', password: 'Anonymous@123' });
    }
  }

  async getDataFromCode(code : string) {
    await this.makeSureAuthen();
    const url = `https://cms.codesigned.co.uk/api/v1/db/data/noco/ACI Tiered Calculator/Sessions`;
    const res = await Request.get(url, {
      limit: 25,
      offset: 0,
      where: `(Code,eq,${code})`
    })
    if (!res) return undefined;
    if (res.data.list.length === 0) return undefined;
    const data = res.data.list[0].Data;
    return data;
  }
  
  async findUnsuedCode(numberOfTrials : number, alreadyTried: number) : Promise<string> {
    await this.makeSureAuthen();
    const code = VarHelper.generateCode();
    const data = await this.getDataFromCode(code);
    if (!data) return code;
    else {
      if (alreadyTried >= numberOfTrials) return code;
      return await this.findUnsuedCode(numberOfTrials, alreadyTried + 1);
    }
  }

  async saveDataAndGenerateCode(data : any) {
    await this.makeSureAuthen();
    const Code = await this.findUnsuedCode(5, 0);
    const url = `https://cms.codesigned.co.uk/api/v1/db/data/noco/ACI Tiered Calculator/Sessions`;
    const res = await Request.post(url, {
      Data: data,
      Code,
    });
    if (!res) return undefined;
    return {
      Code,
      Id: res.data.Id,
    };
  }

  async updateData(code: string, newData: any) {
    await this.makeSureAuthen();
    const url = `https://cms.codesigned.co.uk/api/v1/db/data/noco/ACI Tiered Calculator/Sessions/${newData.Id}`;
    await Request.patch(url, {
      Data: newData,
      Code: code,
    });
  }

}

export default new API();