import imgHomeBG from './img/home-bg.jpg';
import imgACILogo from './img/aci-logo.png';
import imgACILogoBlue from './img/aci-logo-blue.png';
import imgStep2BG from './img/step2-bg.jpg';
import imgStep4AcceptAlternativePayment from './img/step-4-accept-alternative-payment.jpg'
import imgStep4Mobile from './img/step-4-mobile.jpg'
import imgStep4CostOfDoingBusiness from './img/step-4-cost-of-doing-business.jpg'
import imgStep3Authen from './img/step-3-authen.jpg'

import SVGIconInfo from './svg/InfoIcon';
import SVGDropdownIcon from './svg/DropdownIcon';

export const ASSETS = {
  imgHomeBG,
  imgACILogo,
  imgACILogoBlue,
  imgStep2BG,
  imgStep4AcceptAlternativePayment,
  imgStep4Mobile,
  imgStep4CostOfDoingBusiness,
  imgStep3Authen,

  SVGIconInfo,
  SVGDropdownIcon,
};
