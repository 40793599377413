import React, { useState } from 'react';
import classes from './Parts.module.scss';
import cls from 'classnames';
import { Text, SliderRange } from 'components';
import cc from '../../@common-classes/Common.module.scss';
import { ASSETS } from 'assets';
import { useStore } from 'store';

const Row = ({ children, style }: any) => {
  const childrenComps = React.Children.toArray(children);
  return (
    <div className={cc.row} style={{ marginBottom: 20, ...style }}>
      <div className={cls(cc.flex, cc.flex1)}>
        {childrenComps[0]}
      </div>
      <div className={cls(cc.flex, cc.flex1, cc.middle)}>
        {childrenComps[1]}
      </div>
    </div>
  );
};

const Part1 = ({ onClickAcceptingAlternativePaymentInfo, onClickMobileInfo }: any) => {
  const [store, setStore, currentPackage, displayValues] = useStore();
  const { packageData, setPackage } = currentPackage;
  return (
    <div className={cls(cc.flex1, cc.rowStretch)}>
      {/* LEFT */}
      <div className={classes.outlineContainer} style={{ width: 642, marginRight: 40, padding: 15 }}>
        <Row>
          <Text blue bold style={{ fontSize: 16 }}>{store.selectedPackage} Modules-<br /> Core Platform & Benefits</Text>
          <Text blue bold style={{ fontSize: 16 }}>{store.selectedPackage} Revenue <br />Uplift Percentage</Text>
        </Row>
        <Row>
          <Text blue h4>New Revenue and Growth</Text>
        </Row>
        <Row>
          <Text style={{ width: 250, marginTop: 30, }}>Accepting Alternative Payments</Text>
          <div style={{ width: '100%', paddingTop: 20 }}>
            <SliderRange
              min={0}
              max={100}
              step={0.1}
              value={packageData.newRevenueAndGrowthAcceptingAlternativePayments || 0}
              onChange={(v: number) => {
                setPackage({
                  ...packageData,
                  newRevenueAndGrowthAcceptingAlternativePayments: v,
                })
              }}
              formatDisplay={(v: number) => `${v}%`}
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
              }}
              small
            />
          </div>
        </Row>
        <Row style={{ marginTop: 60 }}>
          <Text style={{ width: 250, marginTop: 30 }}>Mobile / Tokenization / One-click</Text>
          <div style={{ width: '100%', paddingTop: 0 }}>
            <SliderRange
              min={0}
              max={100}
              step={0.1}
              value={packageData.newRevenueAndGrowthMobileTokenizationOneclick || 0}
              onChange={(v: number) => {
                setPackage({
                  ...packageData,
                  newRevenueAndGrowthMobileTokenizationOneclick: v,
                })
              }}
              formatDisplay={(v: number) => `${v}%`}
              small
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
              }}
            />
          </div>
        </Row>
      </div>
      {/* MIDDLE */}
      <div className={classes.solidContainer} style={{ width: 318, marginRight: 40 }}>
        <Text white bold center style={{ fontSize: 18 }}>{store.selectedPackage} Revenue <br />Uplift Value</Text>
        <div className={cls(cc.row, cc.middle)} style={{ marginTop: 100 }}>
          <ASSETS.SVGIconInfo style={{ marginRight: 30, cursor: 'pointer' }} onClick={onClickAcceptingAlternativePaymentInfo} />
          <div className={classes.whiteBox}>
            <Text white bold money>{displayValues.step4.part1.middleSection.row1}</Text>
          </div>
        </div>
        <div className={cls(cc.row, cc.middle)} style={{ marginTop: 80 }}>
          <ASSETS.SVGIconInfo style={{ marginRight: 30, cursor: 'pointer' }} onClick={onClickMobileInfo} />
          <div className={classes.whiteBox}>
            <Text white bold money>{displayValues.step4.part1.middleSection.row2}</Text>
          </div>
        </div>
      </div>
      {/* RIGHT */}
      <div className={cls(cc.flex, classes.solidContainer)} style={{ width: 421, marginRight: 20 }}>

        {/* <Text white h3 center>Cost saved</Text> */}
        <div className={cls(cc.flex1, cc.middle)}>
          <div className={cls(cc.middle)}>
            <Text white h3 center>Total Revenue Growth</Text>

            <div className={classes.whiteBox} style={{ marginTop: 30 }}>
              <Text white bold money>{displayValues.step4.part1.rightSection.totalrevenueGrowth}</Text>
            </div>
          </div>
          <div className={cls(classes.solidContainer, cc.middle)} style={{ marginTop: 30 }}>
            <Text white h3 center>Margin Growth</Text>

            <div className={classes.whiteBox} style={{ marginTop: 30 }}>
              <Text white bold money>{displayValues.step4.part1.rightSection.marginGrowth}</Text>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Part1;