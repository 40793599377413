import React from 'react';
import { useRatioDimensions } from './useRatioDimensions';
import { RatioContainer } from './RatioContainer';

interface IProps {
  width: number,
  height: number,
  children?: any,
}

const ScaledScreenRatio = ({ width, height, children }: IProps) => {
  const scaledWidth = useRatioDimensions(width / height);
  const scale = scaledWidth / width;
  const translate = 0;

  return (
    <RatioContainer width={scaledWidth} ratio={width / height}>
      <div
        style={{
          position: 'absolute',
          width: width,
          height: height,
          border: 'none',
          transform: `scale(${scale}) translate(${translate}%, ${translate}%)`,
          transformOrigin: 'top left',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </RatioContainer>
  );
};

export default ScaledScreenRatio;