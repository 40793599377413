import { useState, useEffect } from 'react';
import list_currencies from './list_currencies.json';

export const useListCurrencies = () : Array<{ rate: number; cc: string; symbol: string; name: string; }> => {
  const [list, setList] = useState<Array<{ rate: number; cc: string; symbol: string; name: string; }>>([]);
  useEffect(() => {
    fetch('https://api.exchangerate.host/latest?base=USD')
    .then(res => res.json())
    .then(json => {
      const newList : Array<{ rate: number; cc: string; symbol: string; name: string; }> = [];
      const { rates } = json;

      ['AUD', 'CNY', 'EUR', 'GBP', 'JPY', 'USD'].forEach(key => {
        const findCurrency = list_currencies.find(val => val.cc === key);
        if (!!findCurrency) newList.push({ ...findCurrency, rate: rates[key] });
      })
      setList(newList);
    });
  }, []);

  return list;
}