import * as React from "react"

const SvgComponent = (props : any) => (
  <svg
    data-name="Group 18"
    xmlns="http://www.w3.org/2000/svg"
    width={133.532}
    height={127.968}
    {...props}
  >
    <path
      data-name="Path 28"
      d="M36.331 91.397a2.828 2.828 0 0 1-1.035-.2 47.29 47.29 0 1 1 64.858-43.9 47.559 47.559 0 0 1-20.681 39.169 2.781 2.781 0 1 1-3.149-4.584 41.755 41.755 0 1 0-65.193-34.589 41.538 41.538 0 0 0 26.23 38.741 2.782 2.782 0 0 1-1.03 5.363Z"
      fill="#2726dd"
    />
    <path
      data-name="Path 29"
      d="M55.638 66.766h-13.91a2.782 2.782 0 1 1 0-5.564h13.91a5.564 5.564 0 1 0 0-11.128h-5.564a11.128 11.128 0 1 1 0-22.255h13.91a2.782 2.782 0 1 1 0 5.564H50.075a5.564 5.564 0 0 0 0 11.128h5.564a11.128 11.128 0 1 1 0 22.255Z"
      fill="#26d7da"
    />
    <path
      data-name="Path 30"
      d="M52.856 33.382a2.783 2.783 0 0 1-2.782-2.781V19.473a2.782 2.782 0 1 1 5.564 0v11.128a2.783 2.783 0 0 1-2.782 2.781Z"
      fill="#26d7da"
    />
    <path
      data-name="Path 31"
      d="M52.856 77.893a2.783 2.783 0 0 1-2.782-2.782V63.984a2.782 2.782 0 1 1 5.564 0v11.127a2.783 2.783 0 0 1-2.782 2.782Z"
      fill="#26d7da"
    />
    <path
      data-name="Path 32"
      d="M2.782 127.968a2.779 2.779 0 0 1-1.5-5.124l61.2-38.947a2.778 2.778 0 0 1 3.461.378l20.286 20.286L128.77 62.02a2.782 2.782 0 0 1 3.934 3.934L88.2 110.464a2.784 2.784 0 0 1-3.934 0L63.584 89.783l-59.3 37.751a2.764 2.764 0 0 1-1.5.434Z"
      fill="#2726dd"
    />
    <path
      data-name="Path 33"
      d="M130.75 89.021a2.783 2.783 0 0 1-2.782-2.782V66.766h-19.473a2.782 2.782 0 1 1 0-5.564h22.255a2.783 2.783 0 0 1 2.782 2.782v22.255a2.783 2.783 0 0 1-2.782 2.782Z"
      fill="#26d7da"
    />
  </svg>
)

export default SvgComponent
