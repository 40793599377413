import * as React from "react"
import { SVGProps } from "react"
import { useStore } from 'store';

import styles from './ResultTableSVG.module.scss';

interface IProps extends SVGProps<SVGSVGElement> {
  isDownloadMode: boolean,
}
const SvgComponent = (props: IProps) => {
  const [store, setStore, currentPackage, displayValues] = useStore();
  const upliftValues = displayValues.step5.upliftValues;
  const SilverPackage = store.packages.Silver;
  const GoldPackage = store.packages.Gold;
  const PlatinumPackage = store.packages.Platinum;
  // @ts-ignore
  const className = !['USD', 'EUR', 'GBP', 'AUD'].includes(window.activeCurrency?.cc !== 'USD') ? styles.scaleDownAbit : '';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={1845} height={668} className={className} {...props}>
      <g data-name="Group 3188">
        <g
          data-name="Rectangle 4"
          fill="#2726dd"
          stroke="#2726dd"
          strokeWidth={3}
        >
          <rect width={1844} height={667} rx={28} stroke="none" />
          <rect x={1.5} y={1.5} width={1841} height={664} rx={26.5} fill="none" />
        </g>
        <path
          data-name="Rectangle 809"
          d="M1491 0h328a25 25 0 0 1 25 25v617a25 25 0 0 1-25 25h-328V0Z"
          fill="#26d7da"
        />
        <path
          data-name="Rectangle 808"
          fill="#ffcb00"
          opacity={0.996}
          d="M1190 0h301v667h-301z"
        />
        <path data-name="Rectangle 807" fill="#e4e4e4" d="M861 0h329v668H861z" />
        <text
          data-name="Conversion Authentication and Fraud Management Authorization / Bank Approvals Totals Growth Accepting Alternative Payments Mobile / Tokenization / One-click Totals Cost Chargebacks Cost of Doing Business Totals Add-ons Total Profit Impact"
          transform="translate(33 64)"
          fill="#fff"
          fontSize={26}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={0} y={32}>
            {"Conversion"}
          </tspan>
          <tspan
            fontSize={22}
            fontFamily="NeuePlakText-Regular, Neue Plak Text"
            fontWeight={400}
          >
            <tspan x={0} y={58}>
              {"Auth"}
            </tspan>
            <tspan x={0} y={92}>
              {"Fraud"}
            </tspan>
            <tspan x={0} y={126}>
              {"Bank Approvals"}
            </tspan>
          </tspan>
          <tspan fontSize={22}>
            <tspan x={0} y={158}>
              {"Totals"}
            </tspan>
          </tspan>
          <tspan x={0} y={200}>
            {"Growth"}
          </tspan>
          <tspan
            fontSize={22}
            fontFamily="NeuePlakText-Regular, Neue Plak Text"
            fontWeight={400}
          >
            <tspan x={0} y={242}>
              {"Accepting Alternative Payments"}
            </tspan>
            <tspan x={0} y={284}>
              {"Mobile / Tokenization / One-click"}
            </tspan>
          </tspan>
          <tspan fontSize={22}>
            <tspan x={0} y={326}>
              {"Totals"}
            </tspan>
          </tspan>
          <tspan x={0} y={368}>
            {"Cost"}
          </tspan>
          <tspan
            fontSize={22}
            fontFamily="NeuePlakText-Regular, Neue Plak Text"
            fontWeight={400}
          >
            <tspan x={0} y={410}>
              {"Chargebacks"}
            </tspan>
            <tspan x={0} y={452}>
              {"Cost of Doing Business"}
            </tspan>
          </tspan>
          <tspan fontSize={22}>
            <tspan x={0} y={494}>
              {"Totals"}
            </tspan>
            <tspan x={0} y={536}>
              {"Add-ons"}
            </tspan>
            <tspan x={0} y={578}>
              {"Total Profit Impact"}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="4% 5% 9%\xA0N/A N/A N/A4% 5% 9% N/A -18%"
          transform="translate(486 64)"
          fill="#fff"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
        >
          <tspan x={0} y={58}>
            {(store.revisedImpactAuthenManagementPercentage)?.toFixed(2) + "%"}
          </tspan>
          <tspan x={0} y={92}>
            {(store.revisedImpactFraudManagementPercentage)?.toFixed(2) + "%"}
          </tspan>
          <tspan x={0} y={124}>
            {(store.revisedImpactBankApprovalsPercentage)?.toFixed(2) + "%"}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {((store.revisedImpactAuthenManagementPercentage || 0) + (store.revisedImpactFraudManagementPercentage || 0) + (store.revisedImpactBankApprovalsPercentage || 0) ).toFixed(2) + "%"}
            </tspan>
          </tspan>
          <tspan x={0} y={195} />
          <tspan x={0} y={237}>
            {"N/A"}
          </tspan>
          <tspan x={0} y={279}>
            {"N/A"}
          </tspan>
          <tspan x={0} y={321}>
            {"N/A"}
          </tspan>
          <tspan x={0} y={405}>
            {(store.costChargeBacks)?.toFixed(2)}%
          </tspan>
          <tspan x={0} y={447}>
            {(store.costDoingBusiness)?.toFixed(2)}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={489}>
              {((store.costChargeBacks || 0) + (store.costDoingBusiness || 0)).toFixed(2)}%
            </tspan>
          </tspan>
          <tspan x={0} y={531}>
            {displayValues.step5.addOnsPercentage}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={573}>
              {/* {displayValues.step5.totalPercentage} */}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="\u20AC 19,220,000 \u20AC 21, 301,000 \u20AC 40,521,000N/A N/A N/A\u20AC 19,220,000 \u20AC 21, 301,000 \u20AC 40,521,000 N/A \u20AC81,000,000"
          transform="translate(658 64)"
          fill="#fff"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.impactColumn}
        >
          <tspan x={0} y={58} >
            {displayValues.step3.leftSection.impactRow1}
          </tspan>
          <tspan x={0} y={92} >
            {displayValues.step3.leftSection.impactRow2}
          </tspan>
          <tspan x={0} y={124}>
            {displayValues.step3.leftSection.impactRow3}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {displayValues.step3.leftSection.totalRevenueLostForStep7}
            </tspan>
          </tspan>
          <tspan x={0} y={237}>
            {"N/A"}
          </tspan>
          <tspan x={0} y={279}>
            {"N/A"}
          </tspan>
          <tspan x={0} y={321}>
            {"N/A"}
          </tspan>
          <tspan x={0} y={405}>
            {displayValues.step4.part2.leftSection.row1}
          </tspan>
          <tspan x={0} y={447}>
            {displayValues.step4.part2.leftSection.row2}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={489}>
              {displayValues.step4.part2.leftSection.totalMoney}
            </tspan>
          </tspan>
          <tspan x={0} y={531}>
            {displayValues.step5.impactAddOns}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={573}>
              {displayValues.step5.impactTotalProfit}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="0.2% 0.25% 0.45%\xA00.2% 0.25% 0.45%0.2% 0.25% 0.45% 0.5% 1.4%"
          transform="translate(880 64)"
          fill="#2726dd"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.silverColumn}
          style={{
            display: store.selectedPackage === 'Silver' || props.isDownloadMode ? undefined : 'none',
          }}
        >
          <tspan x={0} y={58}>
            {
              SilverPackage?.upliftAuthenManagementPercentage
            }%
          </tspan>
          <tspan x={0} y={92}>
            {
              SilverPackage.upliftFraudManagementPercentage
            }%
          </tspan>
          <tspan x={0} y={124}>
            {SilverPackage.upliftBankApprovalsPercentage}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {/* {Math.floor(
                +(SilverPackage.upliftFraudManagementPercentage || 0) +
                +(SilverPackage.upliftAuthenManagementPercentage || 0) +
                +(SilverPackage.upliftBankApprovalsPercentage || 0) 
              )}% */}
            </tspan>
          </tspan>
          <tspan x={0} y={195} />
          <tspan x={0} y={237}>
            {SilverPackage.newRevenueAndGrowthAcceptingAlternativePayments}%
          </tspan>
          <tspan x={0} y={279}>
            {SilverPackage.newRevenueAndGrowthMobileTokenizationOneclick}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={321}>
              {/* {(+(SilverPackage.newRevenueAndGrowthAcceptingAlternativePayments || 0) + +(SilverPackage.newRevenueAndGrowthMobileTokenizationOneclick || 0)).toFixed(2)}% */}
            </tspan>
          </tspan>
          <tspan x={0} y={405}>
            {SilverPackage.upliftCostChargeBacks}%
          </tspan>
          <tspan x={0} y={447}>
            {SilverPackage.upliftCostDoingBusiness}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={489}>
              {/* {Math.floor(+(SilverPackage.upliftCostChargeBacks || 0) + +(SilverPackage.upliftCostDoingBusiness || 0))}% */}
            </tspan>
            <tspan x={0} y={531}>
              {displayValues.step5.addOnsPercentage}
            </tspan>
            <tspan x={0} y={573}>
              {""}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="1.2% 0.6% 1.8%\xA01.2% 0.6% 1.8%1.2% 0.6% 1.8% 0.5% 5.9%"
          transform="translate(1205 64)"
          fill="#2726dd"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.goldColumn}
          style={{
            display: store.selectedPackage === 'Gold'  || props.isDownloadMode ? undefined : 'none',
          }}
        >
          <tspan x={0} y={58}>
            {Math.floor(
              +(GoldPackage.upliftAuthenManagementPercentage || 0)
            )}%
          </tspan>
          <tspan x={0} y={92}>
            {Math.floor(
              +(GoldPackage.upliftFraudManagementPercentage || 0)
            )}%
          </tspan>
          <tspan x={0} y={124}>
            {Math.floor(GoldPackage.upliftBankApprovalsPercentage || 0)}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {/* {Math.floor(
                +(GoldPackage.upliftAuthenManagementPercentage || 0) +
                +(GoldPackage.upliftFraudManagementPercentage || 0) + 
                +(GoldPackage.upliftBankApprovalsPercentage || 0)
              )}% */}
            </tspan>
          </tspan>
          <tspan x={0} y={195} />
          <tspan x={0} y={237}>
            {GoldPackage.newRevenueAndGrowthAcceptingAlternativePayments}%
          </tspan>
          <tspan x={0} y={279}>
            {GoldPackage.newRevenueAndGrowthMobileTokenizationOneclick}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={321}>
              {/* {Math.floor(+(GoldPackage.newRevenueAndGrowthAcceptingAlternativePayments || 0) + +(GoldPackage.newRevenueAndGrowthMobileTokenizationOneclick || 0))}% */}
            </tspan>
          </tspan>
          <tspan x={0} y={405}>
            {GoldPackage.upliftCostChargeBacks}%
          </tspan>
          <tspan x={0} y={447}>
            {GoldPackage.upliftCostDoingBusiness}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
            style={{
              display: store.selectedPackage === 'Gold'  || props.isDownloadMode ? undefined : 'none',
            }}
          >
            <tspan x={0} y={489}>
              {/* {Math.floor(+(GoldPackage.upliftCostChargeBacks || 0) + +(GoldPackage.upliftCostDoingBusiness || 0))}% */}
            </tspan>
            <tspan x={0} y={531}>
              {displayValues.step5.addOnsPercentage}
            </tspan>
            <tspan x={0} y={573}>
              {""}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="2.6% 0.75% 3.35%\xA02.6% 0.75% 3.35%2.6% 0.75% 3.35% 0.5% 7.55%"
          transform="translate(1510 64)"
          fill="#2726dd"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.platinumColumn}
          style={{
            display: store.selectedPackage === 'Platinum'  || props.isDownloadMode ? undefined : 'none',
          }}
        >
          <tspan x={0} y={58}>
            {Math.floor(
              +(PlatinumPackage.upliftAuthenManagementPercentage || 0)
            )}%
          </tspan>
          <tspan x={0} y={92}>
            {Math.floor(
              +(PlatinumPackage.upliftFraudManagementPercentage || 0)
            )}%
          </tspan>
          <tspan x={0} y={124}>
            {Math.floor(PlatinumPackage.upliftBankApprovalsPercentage || 0)}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {/* {Math.floor(
                +(PlatinumPackage.upliftAuthenManagementPercentage || 0) +
                +(PlatinumPackage.upliftFraudManagementPercentage || 0) +
                +(PlatinumPackage.upliftBankApprovalsPercentage || 0)
              )}% */}
            </tspan>
          </tspan>
          <tspan x={0} y={195} />
          <tspan x={0} y={237}>
            {PlatinumPackage.newRevenueAndGrowthAcceptingAlternativePayments}%
          </tspan>
          <tspan x={0} y={279}>
            {PlatinumPackage.newRevenueAndGrowthMobileTokenizationOneclick}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={321}>
              {/* {Math.floor(+(PlatinumPackage.newRevenueAndGrowthAcceptingAlternativePayments || 0) + +(PlatinumPackage.newRevenueAndGrowthMobileTokenizationOneclick || 0))}% */}
            </tspan>
          </tspan>
          <tspan x={0} y={405}>
            {PlatinumPackage.upliftCostChargeBacks}%
          </tspan>
          <tspan x={0} y={447}>
            {PlatinumPackage.upliftCostDoingBusiness}%
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={489}>
            {/* {Math.floor(+(PlatinumPackage.upliftCostChargeBacks || 0) + +(PlatinumPackage.upliftCostDoingBusiness || 0))}% */}
            </tspan>
            <tspan x={0} y={531}>
              {displayValues.step5.addOnsPercentage}
            </tspan>
            <tspan x={0} y={573}>
              {""}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="\u20AC983,000 \u20AC567,000 \u20AC1,450,000\u20AC983,000 \u20AC567,000 \u20AC1,450,000\u20AC983,000 \u20AC567,000 \u20AC1,450,000 \u20AC267,000 \u20AC3,167,000"
          transform="translate(1003 64)"
          fill="#2726dd"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.upliftColumn}
          style={{
            display: store.selectedPackage === 'Silver'  || props.isDownloadMode ? undefined : 'none',
          }}
        >
          <tspan x={0} y={58}>
            {upliftValues.Silver.authenManagement}
          </tspan>
          <tspan x={0} y={92}>
            {upliftValues.Silver.fraudManagement}
          </tspan>
          <tspan x={0} y={124}>
            {upliftValues.Silver.bankApproval}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {upliftValues.Silver.totalConversion}
            </tspan>
          </tspan>
          <tspan x={0} y={237}>
            {upliftValues.Silver.alternativePayments}
          </tspan>
          <tspan x={0} y={279}>
            {upliftValues.Silver.mobile}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={321}>
              {upliftValues.Silver.totalGrowth}
            </tspan>
          </tspan>
          <tspan x={0} y={405}>
            {upliftValues.Silver.chargebacks}
          </tspan>
          <tspan x={0} y={447}>
            {upliftValues.Silver.doingBusiness}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
            style={{
              display: store.selectedPackage === 'Silver'  || props.isDownloadMode ? undefined : 'none',
            }}
          >
            <tspan x={0} y={489}>
              {upliftValues.Silver.totalCost}
            </tspan>
            <tspan x={0} y={531}>
              {upliftValues.Silver.addOns}
            </tspan>
            <tspan x={0} y={573}>
              {upliftValues.Silver.totalProfitImpact}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="\u20AC1,800,000 \u20AC900,000 \u20AC4,500,000\u20AC1,800,000 \u20AC900,000 \u20AC4,500,000\u20AC1,800,000 \u20AC900,000 \u20AC4,500,000 \u20AC267,000 \u20AC13,800,000"
          transform="translate(1318 64)"
          fill="#2726dd"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.upliftColumn}
          style={{
            display: store.selectedPackage === 'Gold'  || props.isDownloadMode ? undefined : 'none',
          }}
        >
          <tspan x={0} y={58}>
            {upliftValues.Gold.authenManagement}
          </tspan>
          <tspan x={0} y={92}>
            {upliftValues.Gold.fraudManagement}
          </tspan>
          <tspan x={0} y={124}>
            {upliftValues.Gold.bankApproval}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {upliftValues.Gold.totalConversion}
            </tspan>
          </tspan>
          <tspan x={0} y={237}>
            {upliftValues.Gold.alternativePayments}
          </tspan>
          <tspan x={0} y={279}>
            {upliftValues.Gold.mobile}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={321}>
              {upliftValues.Gold.totalGrowth}
            </tspan>
          </tspan>
          <tspan x={0} y={405}>
            {upliftValues.Gold.chargebacks}
          </tspan>
          <tspan x={0} y={447}>
            {upliftValues.Gold.doingBusiness}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={489}>
              {upliftValues.Gold.totalCost}
            </tspan>
            <tspan x={0} y={531}>
              {upliftValues.Gold.addOns}
            </tspan>
            <tspan x={0} y={573}>
              {upliftValues.Gold.totalProfitImpact}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="\u20AC3,000,000 \u20AC1,000,000 \u20AC6,000,000\u20AC3,000,000 \u20AC1,000,000 \u20AC6,000,000\u20AC3,000,000 \u20AC1,000,000 \u20AC6,000,000 \u20AC267,000 \u20AC18,267,000"
          transform="translate(1637 64)"
          fill="#2726dd"
          fontSize={22}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          className={styles.upliftColumn}
          style={{
            display: store.selectedPackage === 'Platinum'  || props.isDownloadMode ? undefined : 'none',
          }}
        >
          <tspan x={0} y={58}>
            {upliftValues.Platinum.authenManagement}
          </tspan>
          <tspan x={0} y={92}>
            {upliftValues.Platinum.fraudManagement}
          </tspan>
          <tspan x={0} y={124}>
            {upliftValues.Platinum.bankApproval}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={153}>
              {upliftValues.Platinum.totalConversion}
            </tspan>
          </tspan>
          <tspan x={0} y={237}>
            {upliftValues.Platinum.alternativePayments}
          </tspan>
          <tspan x={0} y={279}>
            {upliftValues.Platinum.mobile}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={321}>
              {upliftValues.Platinum.totalGrowth}
            </tspan>
          </tspan>
          <tspan x={0} y={405}>
            {upliftValues.Platinum.chargebacks}
          </tspan>
          <tspan x={0} y={447}>
            {upliftValues.Platinum.doingBusiness}
          </tspan>
          <tspan
            fontFamily="NeuePlakText-SemiBold, Neue Plak Text"
            fontWeight={600}
          >
            <tspan x={0} y={489}>
              {upliftValues.Platinum.totalCost}
            </tspan>
            <tspan x={0} y={531}>
              {upliftValues.Platinum.addOns}
            </tspan>
            <tspan x={0} y={573}>
              {upliftValues.Platinum.totalProfitImpact}
            </tspan>
          </tspan>
        </text>
        <text
          data-name="Current Impact"
          transform="translate(549 42)"
          fill="#fff"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-63.396} y={0}>
            {"Current Impact"}
          </tspan>
        </text>
        <text
          data-name="Impact Value"
          transform="translate(712 42)"
          fill="#fff"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-54.477} y={0}>
            {"Impact Value"}
          </tspan>
        </text>
        <text
          transform="translate(904.034 42)"
          fill="#2726dd"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-23.823} y={0}>
            {"Silver"}
          </tspan>
        </text>
        <text
          transform="translate(1229 42)"
          fill="#2726dd"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-19.494} y={0}>
            {"Gold"}
          </tspan>
        </text>
        <text
          transform="translate(1547 42)"
          fill="#2726dd"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-36.909} y={0}>
            {"Platinum"}
          </tspan>
        </text>
        <text
          data-name="Total Margin Saved"
          transform="translate(1050.937 42)"
          fill="#2726dd"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-47.763} y={0}>
            {"Total Margin Saved"}
          </tspan>
        </text>
        <text
          data-name="Total Margin Saved"
          transform="translate(1370.5 42)"
          fill="#2726dd"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-55.763} y={0}>
            {"Total Margin Saved"}
          </tspan>
        </text>
        <text
          data-name="Total Margin Saved"
          transform="translate(1680.5 42)"
          fill="#2726dd"
          fontSize={18}
          fontFamily="NeuePlakText-Bold, Neue Plak Text"
          fontWeight={700}
        >
          <tspan x={-47.763} y={0}>
            {"Total Margin Saved"}
          </tspan>
        </text>
        <path
          data-name="Line 138"
          fill="none"
          stroke="#2726dd"
          d="M861.5 58.5H1845"
        />
        <path data-name="Line 130" fill="none" stroke="#fff" d="M465.712 1v667" />
        <path data-name="Line 131" fill="none" stroke="#fff" d="M638 0v667" />
        <path
          data-name="Line 133"
          fill="none"
          stroke="#2726dd"
          d="M982.937.5v667"
        />
        <path
          data-name="Line 134"
          fill="none"
          stroke="#2726dd"
          d="M1190.453.5v667"
        />
        <path
          data-name="Line 135"
          fill="none"
          stroke="#2726dd"
          d="M1301.234.5v667"
        />
        <path
          data-name="Line 136"
          fill="none"
          stroke="#2726dd"
          d="M1491.071.5v667"
        />
        <path
          data-name="Line 137"
          fill="none"
          stroke="#2726dd"
          d="M1612.179.5v667"
        />
        <path
          data-name="Line 125"
          fill="none"
          stroke="#fff"
          d="M.216 58.5H861.5"
        />
        <path
          data-name="Line 139"
          fill="none"
          stroke="#2726dd"
          d="M861.5 231.5H1845"
        />
        <path
          data-name="Line 140"
          fill="none"
          stroke="#2726dd"
          d="M861.5 400.5H1845"
        />
        <path
          data-name="Line 141"
          fill="none"
          stroke="#2726dd"
          d="M861.5 565.5H1845"
        />
        <path
          data-name="Line 142"
          fill="none"
          stroke="#2726dd"
          d="M861.5 610.5H1845"
        />
        <path
          data-name="Line 126"
          fill="none"
          stroke="#fff"
          d="M.216 231.5H861.5"
        />
        <path
          data-name="Line 127"
          fill="none"
          stroke="#fff"
          d="M.216 400.5H861.5"
        />
        <path
          data-name="Line 128"
          fill="none"
          stroke="#fff"
          d="M.216 565.5H861.5"
        />
        <path
          data-name="Line 129"
          fill="none"
          stroke="#fff"
          d="M.216 610.5H861.5"
        />
        <path
          data-name="Line 132"
          fill="none"
          stroke="#2726dd"
          d="M860.034.5v667"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
