import * as React from "react"
import { SVGProps } from "react"
import { ASSETS } from 'assets';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={1920}
    height={1080}
    {...props}
  >
    <defs>
      <clipPath id="d">
        <path
          data-name="Rectangle 820"
          fill="none"
          d="M0 0h270.033v270.033H0z"
        />
      </clipPath>
      <clipPath id="e">
        <path
          data-name="Rectangle 816"
          fill="none"
          stroke="#111"
          d="M0 0h113.329v50.299H0z"
        />
      </clipPath>
      <clipPath id="f">
        <path
          data-name="Rectangle 821"
          fill="none"
          stroke="#111"
          d="M0 0h56.264v75.238H0z"
        />
      </clipPath>
      <clipPath id="g">
        <path
          data-name="Rectangle 824"
          fill="none"
          stroke="#111"
          d="M0 0h87.243v63.465H0z"
        />
      </clipPath>
      <clipPath id="h">
        <path
          data-name="Rectangle 828"
          fill="none"
          stroke="#111"
          d="M0 0h71.753v71.755H0z"
        />
      </clipPath>
      <clipPath id="i">
        <path
          data-name="Rectangle 829"
          fill="none"
          stroke="#111"
          d="M0 0h84.661v84.661H0z"
        />
      </clipPath>
      <clipPath id="j">
        <path
          data-name="Rectangle 831"
          fill="none"
          stroke="#111"
          d="M0 0h70.966v78.478H0z"
        />
      </clipPath>
      <clipPath id="a">
        <path d="M0 0h1920v1080H0z" />
      </clipPath>
      <linearGradient
        id="c"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0a43ff" />
        <stop offset={1} stopColor="#0a43ff" />
      </linearGradient>
      <pattern
        id="b"
        width={1}
        height={1}
        viewBox="1417.067 50.983 740.95 848.596"
      >
        <image
          preserveAspectRatio="xMidYMid slice"
          width={2347.826}
          height={1080}
          xlinkHref={ASSETS.imgStep3Authen}
        />
      </pattern>
    </defs>
    
    <g data-name="Lightbox \u2013 7" clipPath="url(#a)">
      <path fill="#fff" d="M0 0h1920v1080H0z" />
      <path transform="translate(977)" fill="url(#b)" d="M0 0h943v1080H0z" />
      <path
        d="M0 0h942.924v1080H0Z"
        transform="translate(977)"
        fill="url(#c)"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <text
        data-name="Conversion - Authentication &amp; Fraud"
        transform="translate(84 210)"
        fill="#111"
        fontSize={40}
        fontFamily="Arial-BoldMT, Arial"
        fontWeight={700}
      >
        <tspan x={0} y={36}>
          {"Conversion - Authentication & Fraud"}
        </tspan>
      </text>
      <g data-name="Group 3205">
        <text
          data-name="30%"
          transform="translate(1358.517 642)"
          fill="#fff"
          fontSize={90}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
        >
          <tspan x={0} y={0}>
            {"30%"}
          </tspan>
        </text>
        <g data-name="Group 3204">
          <g
            data-name="Group 3203"
            clipPath="url(#d)"
            transform="translate(1313 475.983)"
          >
            <g data-name="Group 3202">
              <g data-name="Group 3201" clipPath="url(#d)">
                <g data-name="Group 3200" opacity={0.4}>
                  <g data-name="Group 3199">
                    <g data-name="Group 3198" clipPath="url(#d)">
                      <circle
                        data-name="Ellipse 9"
                        cx={129.41}
                        cy={129.41}
                        r={129.41}
                        transform="translate(5.607 5.606)"
                        fill="none"
                        stroke="#fff"
                        strokeMiterlimit={10}
                        strokeWidth={15}
                      />
                    </g>
                  </g>
                </g>
                <path
                  data-name="Path 585"
                  d="M135.016 5.606a129.513 129.513 0 0 1 123.7 167.546"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeMiterlimit={10}
                  strokeWidth={15}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <text
        data-name="Machine Learning Incremental LearningReduces false positives - highest degree of accuracy - evolves with new trends (IL)"
        transform="translate(84 457)"
        fill="#111"
        fontSize={22}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={27}>
          {"Machine Learning "}
        </tspan>
        <tspan x={0} y={51}>
          {"Incremental Learning"}
        </tspan>
        <tspan
          fontSize={16}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          fontWeight={400}
        >
          <tspan x={0} y={99}>
            {"Reduces false positives - highest "}
          </tspan>
          <tspan x={0} y={123}>
            {"degree of accuracy - evolves with "}
          </tspan>
          <tspan x={0} y={147}>
            {"new trends (IL)"}
          </tspan>
        </tspan>
      </text>
      <g data-name="Group 3189">
        <g
          data-name="Group 3188"
          clipPath="url(#e)"
          transform="translate(84 380)"
        >
          <path
            data-name="Path 60"
            d="M56.665 26.856 6.171 14.2 56.665 1.547 107.159 14.2Z"
            fill="none"
            stroke="#111"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
        </g>
      </g>
      <path
        data-name="Line 145"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M177.979 397.677v17"
      />
      <g data-name="Group 3191">
        <g
          data-name="Group 3190"
          clipPath="url(#e)"
          fill="none"
          stroke="#111"
          strokeMiterlimit={10}
          strokeWidth={3}
          transform="translate(84 380)"
        >
          <path
            data-name="Path 61"
            d="M86.362 26.7v16.84a86.5 86.5 0 0 1-59.394 0V26.7"
          />
          <path data-name="Line 146" d="M61.054 14.201h-8.778" />
        </g>
      </g>
      <text
        data-name="Global Consortium&amp; Profiling DataReduces exposure to industry trends - customize rules with risk of consumer (History)"
        transform="translate(368 457)"
        fill="#111"
        fontSize={22}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={27}>
          {"Global Consortium"}
        </tspan>
        <tspan x={0} y={51}>
          {"& Profiling Data"}
        </tspan>
        <tspan
          fontSize={16}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          fontWeight={400}
        >
          <tspan x={0} y={99}>
            {"Reduces exposure to industry "}
          </tspan>
          <tspan x={0} y={123}>
            {"trends - customize rules with risk of "}
          </tspan>
          <tspan x={0} y={147}>
            {"consumer (History)"}
          </tspan>
        </tspan>
      </text>
      <g data-name="Group 3207">
        <g
          data-name="Group 3206"
          clipPath="url(#f)"
          transform="translate(368 355.224)"
        >
          <path
            data-name="Path 586"
            d="M1.195 66.051V1.195h32.34l13.543 13.543v51.313Z"
            fill="none"
            stroke="#111"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
        </g>
      </g>
      <path
        data-name="Line 147"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M394.648 378.11h-17.619"
      />
      <path
        data-name="Line 148"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M407.243 384.836H377.03"
      />
      <path
        data-name="Line 149"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M401.534 391.561h-24.505"
      />
      <path
        data-name="Line 150"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M389.68 398.287h-12.651"
      />
      <g data-name="Group 3209">
        <g
          data-name="Group 3208"
          clipPath="url(#f)"
          fill="none"
          stroke="#111"
          strokeMiterlimit={10}
          strokeWidth={3}
          transform="translate(368 355.224)"
        >
          <path data-name="Path 587" d="M33.531 1.195v13.543h13.547" />
          <path data-name="Path 588" d="M55.07 22.729v51.314H9.187" />
        </g>
      </g>
      <text
        data-name="Business Intelligence DashboardsImmediate KPI management - Focus on False Positives and Efficiencies - Optimization"
        transform="translate(711 457)"
        fill="#111"
        fontSize={22}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={27}>
          {"Business Intelligence "}
        </tspan>
        <tspan x={0} y={51}>
          {"Dashboards"}
        </tspan>
        <tspan
          fontSize={16}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          fontWeight={400}
        >
          <tspan x={0} y={99}>
            {"Immediate KPI management - "}
          </tspan>
          <tspan x={0} y={123}>
            {"Focus on False Positives and "}
          </tspan>
          <tspan x={0} y={147}>
            {"Efficiencies - Optimization"}
          </tspan>
        </tspan>
      </text>
      <g data-name="Group 3212">
        <g
          data-name="Group 3211"
          clipPath="url(#g)"
          transform="translate(711 367.408)"
        >
          <path
            data-name="Rectangle 823"
            fill="none"
            stroke="#111"
            strokeMiterlimit={10}
            strokeWidth={3}
            d="M1.271 1.271h84.7v60.922h-84.7z"
          />
        </g>
      </g>
      <path
        data-name="Rectangle 825"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M758.86 388.665h29.636v24.675H758.86z"
      />
      <path
        data-name="Rectangle 826"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M720.748 395.744h29.636v24.675h-29.636z"
      />
      <g data-name="Group 3214">
        <g
          data-name="Group 3213"
          clipPath="url(#g)"
          transform="translate(711 367.408)"
        >
          <path
            data-name="Line 151"
            fill="none"
            stroke="#111"
            strokeMiterlimit={10}
            strokeWidth={3}
            d="M85.972 11.189H40.655"
          />
          <path
            data-name="Path 589"
            d="M13.919 11.189a2.543 2.543 0 1 1-2.543-2.543 2.543 2.543 0 0 1 2.543 2.543"
            fill="#111"
          />
          <path
            data-name="Path 590"
            d="M23.459 11.189a2.543 2.543 0 1 1-2.543-2.543 2.543 2.543 0 0 1 2.543 2.543"
            fill="#111"
          />
          <path
            data-name="Path 591"
            d="M32.731 11.189a2.543 2.543 0 1 1-2.545-2.543 2.543 2.543 0 0 1 2.545 2.543"
            fill="#111"
          />
        </g>
      </g>
      <text
        data-name="Dynamic 3D SecureAuthentication focus must be consumer profiling (ACI Merchant Fraud tools but in SCA/TRA"
        transform="translate(84 805)"
        fill="#111"
        fontSize={22}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={27}>
          {"Dynamic 3D Secure"}
        </tspan>
        <tspan
          fontSize={16}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          fontWeight={400}
        >
          <tspan x={0} y={75}>
            {"Authentication focus must be "}
          </tspan>
          <tspan x={0} y={99}>
            {"consumer profiling (ACI Merchant "}
          </tspan>
          <tspan x={0} y={123}>
            {"Fraud tools but in SCA/TRA"}
          </tspan>
        </tspan>
      </text>
      <g
        data-name="Group 3216"
        transform="translate(84 711.073)"
        clipPath="url(#h)"
        fill="none"
        stroke="#111"
        strokeMiterlimit={10}
        strokeWidth={3}
      >
        <circle
          data-name="Ellipse 10"
          cx={25.077}
          cy={25.077}
          r={25.077}
          transform="translate(1.383 1.383)"
        />
        <path
          data-name="Path 592"
          d="M10.995 26.459a15.465 15.465 0 0 1 15.464-15.464"
        />
        <path
          data-name="Path 593"
          d="m47.379 40.193 22.42 22.418-7.191 7.188L40.19 47.382"
        />
      </g>
      <text
        data-name="Multiple Decisioning Flows in end to end PaymentApply decision at richest point of payment - registration / Pre 3DS / Pre auth / Post auth / Post payment for POC"
        transform="translate(368 805)"
        fill="#111"
        fontSize={22}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={27}>
          {"Multiple Decisioning Flows in "}
        </tspan>
        <tspan x={0} y={51}>
          {"end to end Payment"}
        </tspan>
        <tspan
          fontSize={16}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          fontWeight={400}
        >
          <tspan x={0} y={99}>
            {"Apply decision at richest point of payment - "}
          </tspan>
          <tspan x={0} y={123}>
            {"registration / Pre 3DS / Pre auth / Post auth "}
          </tspan>
          <tspan x={0} y={147}>
            {"/ Post payment for POC"}
          </tspan>
        </tspan>
      </text>
      <g data-name="Group 3219">
        <g
          data-name="Group 3218"
          transform="translate(368 698.866)"
          clipPath="url(#i)"
          fill="none"
          stroke="#111"
          strokeMiterlimit={10}
          strokeWidth={3}
        >
          <path
            data-name="Path 594"
            d="M38.569 12.236v19.466a11.177 11.177 0 0 0-6.867 6.87H12.236v-6.887l-10.64 10.64 10.64 10.641"
          />
          <path
            data-name="Path 595"
            d="M72.425 38.569H52.959a11.177 11.177 0 0 0-6.867-6.867V12.236h6.883L42.336 1.596l-10.641 10.64"
          />
          <path
            data-name="Path 596"
            d="M46.093 72.426V52.96a11.179 11.179 0 0 0 6.866-6.867h19.47v6.883l10.64-10.64-10.64-10.64"
          />
          <path
            data-name="Path 597"
            d="M12.236 46.093h19.466a11.177 11.177 0 0 0 6.871 6.867v19.469h-6.888l10.64 10.64 10.641-10.64"
          />
        </g>
      </g>
      <text
        data-name="Risk Analytics FunctionCustomized analytics with ACI fraud experts dedicated to KPIs"
        transform="translate(711 805)"
        fill="#111"
        fontSize={22}
        fontFamily="NeuePlakText-Bold, Neue Plak Text"
        fontWeight={700}
      >
        <tspan x={0} y={27}>
          {"Risk Analytics"}
        </tspan>
        <tspan x={0} y={51}>
          {"Function"}
        </tspan>
        <tspan
          fontSize={16}
          fontFamily="NeuePlakText-Regular, Neue Plak Text"
          fontWeight={400}
        >
          <tspan x={0} y={99}>
            {"Customized analytics with ACI "}
          </tspan>
          <tspan x={0} y={123}>
            {"fraud experts dedicated to KPIs"}
          </tspan>
        </tspan>
      </text>
      <g data-name="Group 3221">
        <g
          data-name="Group 3220"
          transform="translate(711 705)"
          clipPath="url(#j)"
          fill="none"
          stroke="#111"
          strokeMiterlimit={10}
          strokeWidth={3}
        >
          <path
            data-name="Path 598"
            d="M53.337 11.082a29.483 29.483 0 0 1-21.492-9.3 29.485 29.485 0 0 1-21.492 9.3H1.22v18.749a42.53 42.53 0 0 0 14.03 31.568l16.595 14.982L48.44 61.399a42.53 42.53 0 0 0 14.03-31.568V11.082Z"
          />
          <path
            data-name="Path 599"
            d="M69.746 17.815v11.263a52.606 52.606 0 0 1-17.354 39.047l-10.464 9.447"
          />
          <path
            data-name="Path 600"
            d="M34.182 47.337h-4.673l-2.031-29.942h8.734Z"
          />
          <path data-name="Rectangle 830" d="M28.55 54.179h6.59v6.59h-6.59z" />
        </g>
      </g>
    </g>
    <text
      data-name="3DS exemptions on authentication \u2013 can improve decline rates by"
      transform="translate(1260 329)"
      fill="#fff"
      fontSize={25}
      fontFamily="NeuePlakText-Bold, Neue Plak Text"
      fontWeight={700}
    >
      <tspan x={82.775} y={31}>
        {"3DS exemptions on "}
      </tspan>
      <tspan x={27.337} y={63}>
        {"authentication \u2013 can improve "}
      </tspan>
      <tspan x={102.875} y={95}>
        {"decline rates by"}
      </tspan>
    </text>
  </svg>
)
export default SvgComponent
