
import { defaultAddOnsTable } from './defaultAddOnsTable';
export type TPackage = 'Silver' | 'Gold' | 'Platinum'
export type TPackageUpliftValue = {
  // step 3 - right section
  upliftAuthenManagementPercentage?: number, // C27_Authen
  upliftFraudManagementPercentage?: number, // C27_Fraud
  upliftBankApprovalsPercentage?: number, // C28
  // step 4 - part 2 - middle section
  upliftCostChargeBacks?: number, // C33
  upliftCostDoingBusiness?: number, // C34
  // step 4 - input and amend uplift amounts
  newRevenueAndGrowthAcceptingAlternativePayments?: number,  //D30
  newRevenueAndGrowthMobileTokenizationOneclick?: number, //D31
}

export type TStoreValue = {
  Id?: any,
  //session
  sessionCode?: string,
  // home
  currency: {
    cc: string,
    symbol: string,
    name: string,
    rate: number,
  },
  vertical?: string,
  // step 1
  annualTransactionValue?: number, // D3
  averageTransactionValue?: number, // D4
  salesMarginBeforeFixedCosts?: number, // percentage 0-100 // D5
  // step 2
  selectedPackage: TPackage,
  // step 3
  revisedImpactAuthenManagementPercentage?: number, // C12
  revisedImpactFraudManagementPercentage?: number, // C12
  revisedImpactBankApprovalsPercentage?: number, // C13
  // step 4 - part 2 - left section
  costChargeBacks?: number, // C18
  costDoingBusiness?: number, // C19
  
  addOnsTable: Array<{
    name: string,
    uplift: number,
  }>,
  packages: {
    Silver: TPackageUpliftValue,
    Gold: TPackageUpliftValue,
    Platinum: TPackageUpliftValue,
  },
};

export const defaultStoreValue : TStoreValue = {
  currency: {
    cc: "USD",
    symbol: "$",
    name: "United States dollar",
    rate: 1,
  },
  selectedPackage: 'Silver',
  addOnsTable: defaultAddOnsTable,
  annualTransactionValue: 7566000,
  averageTransactionValue: 65,
  salesMarginBeforeFixedCosts: 20,
  revisedImpactAuthenManagementPercentage: -2,
  revisedImpactFraudManagementPercentage: -2,
  revisedImpactBankApprovalsPercentage: -5,
  costChargeBacks: -0.75,
  costDoingBusiness: -1,
  packages: {
    Silver: {
      upliftAuthenManagementPercentage: 5,
      upliftFraudManagementPercentage: 5,
      upliftBankApprovalsPercentage: 5,
      newRevenueAndGrowthAcceptingAlternativePayments: 0.5,
      newRevenueAndGrowthMobileTokenizationOneclick: 0.75,
      upliftCostChargeBacks: 10,
      upliftCostDoingBusiness: 5,
    },
    Gold: {
      upliftAuthenManagementPercentage: 5,
      upliftFraudManagementPercentage: 30,
      upliftBankApprovalsPercentage: 12,
      newRevenueAndGrowthAcceptingAlternativePayments: 1.5,
      newRevenueAndGrowthMobileTokenizationOneclick: 1.2,
      upliftCostChargeBacks: 15,
      upliftCostDoingBusiness: 12,
    },
    Platinum: {
      upliftAuthenManagementPercentage: 5,
      upliftFraudManagementPercentage: 65,
      upliftBankApprovalsPercentage: 15,
      newRevenueAndGrowthAcceptingAlternativePayments: 2.5,
      newRevenueAndGrowthMobileTokenizationOneclick: 2,
      upliftCostChargeBacks: 35,
      upliftCostDoingBusiness: 25,
    }
  }
};