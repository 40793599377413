import React, { useState } from 'react';
import classes from './Parts.module.scss';
import cls from 'classnames';
import { Text, SliderRange } from 'components';
import cc from '../../@common-classes/Common.module.scss';
import { ASSETS } from 'assets';
import { useStore } from 'store';

const Row = ({ children, style }: any) => {
  const childrenComps = React.Children.toArray(children);
  return (
    <div className={cc.row} style={{ marginBottom: 20, ...style }}>
      <div className={cls(cc.flex, cc.flex2)}>
        {childrenComps[0]}
      </div>
      <div className={cls(cc.flex, cc.flex3, cc.middle)}>
        {childrenComps[1]}
      </div>
      {!!childrenComps[2] && (
        <div className={cls(cc.flex, cc.flex1, cc.middle)}>
          {childrenComps[2]}
        </div>
      )}
    </div>
  );
};

const Part2 = ({
  onClickCostOfDoingBusinessInfo,
  onClickChargebackInfo,
} : any) => {
  const [store, setStore, currentPackage, displayValues] = useStore();
  const { packageData, setPackage } = currentPackage;

  return (
    <div className={cls(cc.flex1, cc.rowStretch)}>
      {/* LEFT */}
      <div className={classes.outlineContainer} style={{ width: 642, marginRight: 40, padding: 20 }}>
        <Row>
          <Text blue bold style={{ fontSize: 16 }}>{store.selectedPackage} Modules-<br /> Core Platform & Benefits</Text>
          <Text blue bold center style={{ fontSize: 16 }}>Cost <br />(% Revenue)</Text>
        </Row>
        <Row>
          <Text blue bold style={{ fontSize: 16 }}>Cost Reduction</Text>
          <Text blue bold center style={{ fontSize: 16 }}>Impact <br />(% Revenue)</Text>
          <Text blue bold center style={{ fontSize: 16 }}>Impact <br />(Revenue)</Text>
        </Row>
        <Row style={{ marginTop: 30 }}>
          <Text style={{ marginTop: 30 }}>Chargebacks</Text>
          <div style={{ width: '100%' }}>
            <SliderRange
              min={0}
              max={100}
              step={0.1}
              value={-(store.costChargeBacks || 0)}
              onChange={(v: number) => {
                setStore({
                  ...store,
                  costChargeBacks: -v,
                })
              }}
              formatDisplay={(v: number) => `${v}%`}
              small
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
              }}
            />
          </div>
          <Text style={{ marginTop: 30, width: 150, fontSize: '1em', textAlign: 'right' }} money>{displayValues.step4.part2.leftSection.row1}</Text>
        </Row>
        <Row style={{ marginTop: 40 }}>
          <Text>Cost of Doing Business</Text>
          <div style={{ width: '100%' }}>
            <SliderRange
              min={0}
              max={100}
              step={0.1}
              value={-(store.costDoingBusiness || 0)}
              onChange={(v: number) => {
                setStore({
                  ...store,
                  costDoingBusiness: -v,
                })
              }}
              formatDisplay={(v: number) => `${v}%`}
              small
              style={{
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
              }}
            />
          </div>
          <Text style={{ marginTop: 30, width: 150, fontSize: '1em', textAlign: 'right' }} money>{displayValues.step4.part2.leftSection.row2}</Text>
        </Row>
        <div className={classes.blueLine} style={{ marginBottom: 20, marginTop: 20 }} />
        <Row>
          <div />
          <Text h1 blue>{displayValues.step4.part2.leftSection.totalPercentage}</Text>
          <Text h3 blue style={{ fontSize: '1.1em', textAlign: 'right' }} money>{displayValues.step4.part2.leftSection.totalMoney}</Text>
        </Row>
      </div>
      {/* MIDDLE */}
      <div className={classes.solidContainer} style={{ width: 566, marginRight: 40, padding: 30 }}>
        <Row style={{ marginTop: 70 }}>
          <Text white bold center style={{ fontSize: 16 }}>{store.selectedPackage} Cost <br />Saving Percentage</Text>
          <Text white bold center style={{ fontSize: 16 }}>{store.selectedPackage} Cost <br />Saving Value</Text>
          {/* <Text white bold center style={{ fontSize: 16 }}>Margin Saved</Text> */}
        </Row>
        <Row style={{ marginTop: 30 }}>
          <div className={cls(cc.row, cc.middle)}>
            <ASSETS.SVGIconInfo style={{ marginRight: 10, cursor: 'pointer' }} onClick={onClickChargebackInfo} />
            <input 
              className={classes.whiteBoxSmall}
              value={packageData.upliftCostChargeBacks}
              onChange={(e:any) => setPackage({
                ...packageData,
                upliftCostChargeBacks: e.target.value,
              })}
            />
            <div className={classes.percentageSymbol}>%</div>
          </div>
          <Text white bold money>{displayValues.step4.part2.middleSection.row1CostSaving}</Text>
          {/* <Text white bold money>{displayValues.step4.part2.middleSection.row1MarginSaved}</Text> */}
        </Row>
        <Row style={{ marginTop: 30 }}>
          <div className={cls(cc.row, cc.middle)}>
            <ASSETS.SVGIconInfo style={{ marginRight: 10, cursor: 'pointer' }} onClick={onClickCostOfDoingBusinessInfo} />
            <input 
              className={classes.whiteBoxSmall}
              value={packageData.upliftCostDoingBusiness}
              onChange={(e:any) => setPackage({
                ...packageData,
                upliftCostDoingBusiness: e.target.value,
              })}
            />
            <div className={classes.percentageSymbol}>%</div>
          </div>
          <Text white bold money>{displayValues.step4.part2.middleSection.row2CostSaving}</Text>
          {/* <Text white bold money>{displayValues.step4.part2.middleSection.row2MarginSaved}</Text> */}
        </Row>
      </div>
      {/* RIGHT */}
      <div className={cls(cc.flex, cc.flex1)}>
        <div className={cls(classes.solidContainer, cc.middle, cc.flex1)} style={{ padding: 30 }}>
          <Text white h3 center>Total Cost saving</Text>

          <div className={classes.whiteBox} style={{ marginTop: 30 }}>
            <Text white bold money>{displayValues.step4.part2.rightSection.totalrevenueSaving}</Text>
          </div>
        </div>
        {/* <div className={cls(classes.solidContainer, cc.middle, cc.flex1)} style={{ marginTop: 40, padding: 30 }}>
          <Text white h3 center>Total Margin Saving</Text>

          <div className={classes.whiteBox} style={{ marginTop: 30 }}>
            <Text white bold money>{displayValues.step4.part2.rightSection.marginSaving}</Text>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Part2;